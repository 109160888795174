import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useServices } from 'cng-web-lib'

import { PingSearchType } from '../PingSearch_Constants'
import PingSearchBase from './PingSearch_Base'
import VesselSearchLeftPanel from '../LeftPanel/VesselSearchLeftPanel'
import VesselMilestoneList from '../RightPanel/VesselMilestoneList'
import PingSearchApiUrls from 'src/apiUrls/ping/PingSearchApiUrls'

function PingSearch_VesselSearch(props) {

    const { vesselName } = useParams()
    const { securedSendRequest } = useServices()

    // On page load, if the page load with a vessel search, add the initial search into search history as well.
    function addVesselSearchHistory(searchValue) {
        console.debug("add history [" + searchValue + "]")

        let url = PingSearchApiUrls.ADD_SEARCH_HISTORY
        let data = { searchType: PingSearchType.VESSEL_NAME, searchValue: searchValue }

        securedSendRequest.execute('POST', url, data,
            (response) => {
                // success
                console.debug(response)
            },
            (error) => {
                // error
                console.error(error)
            },
            () => {
                // complete
            }
        )
    }

    useEffect(() => {
        if (vesselName) {
            addVesselSearchHistory(vesselName)
        }
    }, [vesselName]);

    // Temp state var so that even when the value is not changed, it will still trigger a refresh.
    const [searchTime, setSearchTime] = useState()

    const searchButtonClickHandler = ({ searchType, searchValue }) => {
        addVesselSearchHistory(searchValue)
        setSearchTime(new Date())
    }

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <PingSearchBase
            pageName='ping_vessel_search'
            onSearchButtonClick={searchButtonClickHandler}
            leftPanelComponent={<VesselSearchLeftPanel searchTime={searchTime} />}
            rightPanelComponent={<VesselMilestoneList searchTime={searchTime} />}
        />

        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default PingSearch_VesselSearch