import React from 'react'
import { Card, CardContent, Typography, Grid, InputAdornment } from '@material-ui/core'
import { components, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import PingKeys from 'src/constants/locale/key/Ping'
import makeValidationSchema from './PingMyMilestone_MakeValidationSchema'
import PingMyMilestoneUtils from './PingMyMilestoneUtils'
import PingTranslationText from '../../PingTranslationText';

// Moment library
import Moment from 'moment';
import 'moment-timezone';

const {
    form: { field: { CngTextField, CngSelectField } }
} = components

//* Field default values -------------------------------------------------------
const DEFAULT_INITIAL_VALUES = Object.freeze({
    eventCode: '',
    eventName: '',
    eventLocode: '',
    eventLocationName: '',
    eventCountryCode: '',
    eventTimezone: '',
    eventDatetime: '',
    eventTimestamp: '',
    referenceType: '',
    referenceValue: '',
    bookingCarrierRef: '',
    polCode: '',
    polCountryCode: '',
    polEtdTimestamp: '',
    polTimezone: '',
    podCode: '',
    podCountryCode: '',
    podEtaTimestamp: '',
    podTimezone: '',
    partyName: '',
    vesselName: '',
    voyageNum: '',
    beeId:'',
    temp:'',
    humidity:'',
    ambience:'',
    cutoffType: '',
    cutoffEventType: '',
    instructions: '',
    unit: '',
    maxTemp: '',
    minTemp: '',
    createdDate: '',
    createdByName: '',
    updatedDate: '',
    updatedByName: ''
})

//* ----------------------------------------------------------------------------
const FORMIK_PROPS = {
    initialValues: { ...DEFAULT_INITIAL_VALUES },
    makeValidationSchema: makeValidationSchema
}

//* Fields function ------------------------------------------------------------
function Fields({ disabled, showNotification, shouldHideMap, id, fetchData }) {

    // Translations ------------------------------------------------------------
    const { translate } = useTranslation(Namespace.PING)
    const PingMyMilestoneKeys = PingKeys.PING_MY_MILESTONES
    const PingFieldKeys = PingKeys.FIELDS
    const pingTranslatedTextsObject = PingTranslationText()

 

    const Labels = {

        // Card Titles
        eventDetails: translate(Namespace.PING, PingMyMilestoneKeys.EVENT_DETAILS),
        particulars: translate(Namespace.PING, PingMyMilestoneKeys.PARTICULARS),
        additionalDetails: translate(Namespace.PING, PingMyMilestoneKeys.ADDITIONAL_DETAILS),

        // Fields
        eventCode: translate(Namespace.PING, PingFieldKeys.EVENT_CODE),
        eventName: translate(Namespace.PING, PingFieldKeys.EVENT_NAME),
        eventLocode: translate(Namespace.PING, PingFieldKeys.EVENT_LOCODE),
        eventDatetime: translate(Namespace.PING, PingFieldKeys.EVENT_DATETIME),
        eventTimezone: translate(Namespace.PING, PingFieldKeys.EVENT_TIMEZONE),
        referenceType: translate(Namespace.PING, PingFieldKeys.REFERENCE_TYPE),
        referenceValue: translate(Namespace.PING, PingFieldKeys.REFERENCE_VALUE),
        pol: translate(Namespace.PING, PingFieldKeys.POL),
        pod: translate(Namespace.PING, PingFieldKeys.POD),
        partyName: translate(Namespace.PING, PingFieldKeys.PARTY_NAME),
        vesselName: translate(Namespace.PING, PingFieldKeys.VESSEL_NAME),
        voyageNum: translate(Namespace.PING, PingFieldKeys.VOYAGE_NUM),
        beeId: translate(Namespace.PING, PingFieldKeys.BEE_ID),
        temp: translate(Namespace.PING, PingFieldKeys.TEMP),
        humidity: translate(Namespace.PING, PingFieldKeys.HUMIDITY),
        ambience: translate(Namespace.PING, PingFieldKeys.AMBIENCE),
        freightBooking: translate(Namespace.PING, PingFieldKeys.FREIGHT_BOOKING),
        containerNumber: translate(Namespace.PING, PingFieldKeys.CONTAINER_NUMBER)
    }

    //* ------------------------------------------------------------------------
    function _countryFlagSrc(countryCode) {
        return `${process.env.PUBLIC_URL}/static/images/countries/${countryCode}.svg`
    }

    function _formatDate(timestamp, timezone, format) {
        let moment = Moment(timestamp)

        if (timezone) {
            moment.tz(timezone)
        }

        let str = moment.format(format)
        return (str)
    }

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <Grid container spacing={3}>

            {
                //* Event details ----------------------------------------------
            }
            <Grid item xs={12}><Card><CardContent>
                <h2 style={{ marginBottom: '1em' }}>{Labels.eventDetails}</h2>

                <Grid container>
                    <Grid item xs={false} xl={4}>{/* Empty space only on 1920 */}</Grid>

                    <Grid item xs={12} xl={8} container spacing={3}>
                        <Grid item xs={6}>
                            <CngTextField type='text'
                                name='eventCode'
                                label={Labels.eventCode}
                                disabled
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <CngTextField type='text'
                                name='eventName'
                                label={Labels.eventName}
                                disabled
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <CngTextField type='text'
                                name='eventLocationName'
                                label={Labels.eventLocode}
                                disabled

                                InputProps={{
                                    // Use startAdornment for the country flag.
                                    startAdornment: (
                                        <InputAdornment style={{ alignItems: 'baseline', marginTop: '0' }}>
                                            <img style={{
                                                width: '1em',
                                                height: '1em',
                                                marginRight: '0.5em'
                                            }}
                                                src={_countryFlagSrc(fetchData.eventCountryCode)} />
                                        </InputAdornment>
                                    ),

                                    // Use endAdornment for the eventLocode.
                                    endAdornment: (
                                        <InputAdornment>
                                            <Card variant='outlined'
                                                style={{
                                                    textAlign: 'center',
                                                    borderRadius: '1em',
                                                    width: '5em'
                                                }}
                                            >
                                                <Typography color='textSecondary' style={{ fontSize: '0.9em' }}>{fetchData.eventLocode}</Typography>
                                            </Card>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <CngTextField type='text'
                                name='eventDatetime'
                                label={Labels.eventDatetime}
                                disabled

                                // Format the timestamp via value
                                value={_formatDate(fetchData.eventTimestamp, fetchData.eventTimezone, 'DD MMM YYYY HH:mm')}

                                InputProps={{
                                    // Show the GMT offset via endAdornment
                                    endAdornment: (
                                        <InputAdornment>
                                            <Card variant='outlined'
                                                style={{
                                                    textAlign: 'center',
                                                    borderRadius: '1em',
                                                    paddingLeft: '0.5em',
                                                    paddingRight: '0.5em'
                                                }}
                                            >
                                                <Typography color='textSecondary'>
                                                    {_formatDate(fetchData.eventTimestamp, fetchData.eventTimezone, '[GMT ]Z')}
                                                </Typography>
                                            </Card>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>

                        <Grid item xs={6}>{/* Empty space */}</Grid>
                        <Grid item xs={6}>
                            <CngTextField type='text'
                                name='eventTimezone'
                                label={Labels.eventTimezone}
                                disabled
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent></Card></Grid>

            {
                //* Particulars ------------------------------------------------
            }
            <Grid item xs={12}><Card><CardContent>
                <h2 style={{ marginBottom: '1em' }}>{Labels.particulars}</h2>

                <Grid container>
                    <Grid item xs={false} xl={4}>{/* Empty space only on 1920 */}</Grid>

                    <Grid item xs={12} xl={8} container spacing={3}>
                        <Grid item xs={6}>
                            <CngSelectField
                                name='referenceType'
                                label={Labels.referenceType}
                                disabled

                                items={[
                                    { value: 'FREIGHT_BOOKING', text: (Labels.freightBooking) },
                                    { value: 'CONTAINER_NUM', text: (Labels.containerNumber) }
                                ]}
                            />
                        </Grid>

                        {
                            // Reference Value field depends on the reference type.
                            (fetchData.referenceType == 'FREIGHT_BOOKING') &&
                            <Grid item xs={6}>
                                <CngTextField type='text'
                                    name='bookingCarrierRef'
                                    label={Labels.referenceValue}
                                    disabled
                                />
                            </Grid>
                        }
                        {
                            (fetchData.referenceType == 'CONTAINER_NUM') &&
                            <Grid item xs={6}>
                                <CngTextField type='text'
                                    name='referenceValue'
                                    label={Labels.referenceValue}
                                    disabled
                                />
                            </Grid>
                        }

                        {
                            // Below fields will show only when reference type is FREIGHT_BOOKING.
                            (fetchData.referenceType == 'FREIGHT_BOOKING') &&
                            <React.Fragment>
                                <Grid item xs={6}>
                                    <CngTextField type='text'
                                        name='pol'
                                        label={Labels.pol}
                                        disabled

                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment style={{ alignItems: 'baseline', marginTop: '0' }}>
                                                    {
                                                        (fetchData.polCountryCode) ? // show image only when available
                                                            (
                                                                <img
                                                                    src={_countryFlagSrc(fetchData.polCountryCode)}
                                                                    style={{
                                                                        width: '1em', height: '1em',
                                                                        objectFit: 'contain',
                                                                        marginRight: '0.5em'
                                                                    }}
                                                                />
                                                            )
                                                            : '' // else show nothing
                                                    }
                                                </InputAdornment>
                                            )
                                        }}

                                        value={
                                            (fetchData.polCode) ? // show the value only when pol data is available
                                                (`${fetchData.polCode} - ETD: ${_formatDate(fetchData.polEtdTimestamp, fetchData.polTimezone, 'D MMM yyyy HH:mm')}`)
                                                : ('-') // else show a dash
                                        }
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <CngTextField type='text'
                                        name='pod'
                                        label={Labels.pod}
                                        disabled

                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment style={{ alignItems: 'baseline', marginTop: '0' }}>
                                                    {
                                                        (fetchData.podCountryCode) ? // show image only when available
                                                            (
                                                                <img
                                                                    src={_countryFlagSrc(fetchData.podCountryCode)}
                                                                    style={{
                                                                        width: '1em', height: '1em',
                                                                        objectFit: 'contain',
                                                                        marginRight: '0.5em'
                                                                    }}
                                                                />
                                                            )
                                                            : '' // else show nothing
                                                    }
                                                </InputAdornment>
                                            )
                                        }}

                                        value={
                                            (fetchData.podCode) ? // show the value only when booking data is valid.
                                                (`${fetchData.podCode} - ETA: ${_formatDate(fetchData.podEtaTimestamp, fetchData.podTimezone, 'D MMM yyyy HH:mm')}`)
                                                : ('-') // else show a dash
                                        }
                                    />
                                </Grid>
                            </React.Fragment>
                        }

                    </Grid>
                </Grid>
            </CardContent></Card></Grid>

            {
                //* Additional details -----------------------------------------
            }
            <Grid item xs={12}><Card><CardContent>
                <h2 style={{ marginBottom: '1em' }}>{Labels.additionalDetails}</h2>

                <Grid container>
                    <Grid item xs={false} xl={4}>{/* Empty space onlyl on 1920 */}</Grid>

                    <Grid item xs={12} xl={8} container spacing={3}>

                        {
                            // Below fields will only show when reference type is CONTAINER_NUM
                            (fetchData.referenceType == 'CONTAINER_NUM')  
                            && (fetchData.eventCode != 'PCIOT')  
                            && (fetchData.eventCode != 'PCTSP') 
                            && (fetchData.eventCode != 'PCCOF')   
                            &&
                            <React.Fragment>
                                <Grid item xs={6}>
                                    <CngTextField type='text'
                                        name='vesselName'
                                        label={Labels.vesselName}
                                        disabled
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <CngTextField type='text'
                                        name='voyageNum'
                                        label={Labels.voyageNum}
                                        disabled
                                    />
                                </Grid>
                            </React.Fragment>
                        }

                        {
                             (fetchData.eventCode == 'PCIOT') &&
                                <React.Fragment>
                                    <Grid item xs={6}>
                                        <CngTextField type='text'
                                            name='beeId'
                                            label={Labels.beeId}
                                            disabled
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <CngTextField type='text'
                                            name='temp'
                                            label={Labels.temp}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <CngTextField type='text'
                                            name='humidity'
                                            label={Labels.humidity}
                                            disabled
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <CngTextField type='text'
                                            name='ambience'
                                            label={Labels.ambience}
                                            disabled
                                        />
                                    </Grid>
                                </React.Fragment>

                        }
                        {
                             (fetchData.eventCode == 'PCCOF') &&  //Cut Off Time need cut off type/event type
                                <React.Fragment>
                                    <Grid item xs={6}>
                                        <CngTextField type='text'
                                            name='cutoffType'
                                            label={pingTranslatedTextsObject.cutoffType}
                                            disabled
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <CngTextField type='text'
                                            name='cutoffEventType'
                                            label={pingTranslatedTextsObject.cutoffEventType}
                                            disabled
                                        />
                                    </Grid>
                                </React.Fragment>

                        }

                        {
                             (fetchData.eventCode == 'PCTSP') &&  //min temp, max temp, unit, instruction
                                <React.Fragment>
                                    <Grid item xs={6}>
                                        <CngTextField type='text'
                                            name='minTemp'
                                            label={pingTranslatedTextsObject.minTemp}
                                            disabled
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <CngTextField type='text'
                                            name='maxTemp'
                                            label={pingTranslatedTextsObject.maxTemp}
                                            disabled
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                         <CngSelectField
                                            name='unit'
                                            items={PingMyMilestoneUtils.TempUomItems}
                                            label={pingTranslatedTextsObject.unit}
                                            disabled
                                />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <CngTextField type='text'
                                            name='instructions'
                                            label={pingTranslatedTextsObject.instructions}
                                            disabled
                                        />
                                    </Grid>
                                </React.Fragment>

                        }

                        <Grid item xs={6}>
                            <CngTextField type='text'
                                name='partyName'
                                label={Labels.partyName}
                                disabled
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent></Card></Grid>
        </Grid>
        //* End JSX ------------------------------------------------------------

    )
    //* End of function --------------------------------------------------------
}

function toClientDataFormat(serverData) {
    let localData = serverData;
    return localData;
}

function toServerDataFormat(localData) {
    return localData;
}

const FormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    Fields: Fields,
    toClientDataFormat: toClientDataFormat,
    toServerDataFormat: toServerDataFormat
})

export default FormProperties
