import React, { useState, useRef, useEffect } from 'react'
import { Grid, Typography, Select, MenuItem, CircularProgress } from '@material-ui/core'

import { useTranslation, useServices } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'

import PingKeys from 'src/constants/locale/key/Ping'
import CalistaUiComponentKeys from 'src/constants/locale/key/CalistaUiComponent'

import DownloadButtonSecondary from 'src/components/button/DownloadButtonSecondary'
import UploadButtonPrimary from 'src/components/button/UploadButtonPrimary'
import MyMilestoneUploadRow from './MyMilestoneUploadRow'
import LoadingSpinnerLabel from 'src/components/label/LoadingSpinnerLabel'
import ErrorRetrievingDataLabel from 'src/components/label/ErrorRetrievingDataLabel'
import PingMyMilestoneApiUrls from 'src/apiUrls/ping/PingMyMilestoneApiUrls'
import LoadMoreObserver from 'src/components/misc/LoadMoreObserver'
import PingMyMilestoneUploadDialog from '../dialog-page/PingMyMilestoneUploadDialog'

function MyMilestoneUploadListing({
    searchText, // The search value passed-in from the searchBox
    filterData, // The filter criteria passed-in from the filters.
    showNotification,
    ...props
}) {
    const { securedSendRequest } = useServices()

    // Translations ------------------------------------------------------------
    const { translate } = useTranslation([Namespace.CALISTA_UI_COMPONENT, Namespace.PING])

    const PingMyMilestoneKeys = PingKeys.PING_MY_MILESTONES

    const Labels = {
        DOWNLOAD_TEMPLATE: translate(Namespace.CALISTA_UI_COMPONENT, CalistaUiComponentKeys.DOWNLOAD_TEMPLATE),
        UPLOAD_FILE: translate(Namespace.CALISTA_UI_COMPONENT, CalistaUiComponentKeys.UPLOAD_FILE),
        MILESTONE_UPLOAD_LIST: translate(Namespace.PING, PingMyMilestoneKeys.MILESTONE_UPLOAD_LIST),
        SORT_BY: translate(Namespace.PING, PingMyMilestoneKeys.SORT_BY),
        UPLOAD_DATE_DESC: translate(Namespace.PING, PingMyMilestoneKeys.UPLOAD_DATE_DESC),
        UPLOAD_DATE_ASC: translate(Namespace.PING, PingMyMilestoneKeys.UPLOAD_DATE_ASC),
        NO_MORE_DATA: translate(Namespace.PING, PingMyMilestoneKeys.NO_MORE_DATA),
        ERROR_LOADING_MORE_DATA: translate(Namespace.PING, PingMyMilestoneKeys.ERROR_LOADING_MORE_DATA)
    }

    //* ------------------------------------------------------------------------
    //* Search related states and effects.
    //* ------------------------------------------------------------------------
    const [initialLoadingState, setInitialLoadingState] = useState({
        isInitialLoading: true,
        isInitialLoadingError: false
    })

    // useRef for above state. Somehow callback will always use a stale state
    const initialLoadingStateRef = useRef()
    initialLoadingStateRef.current = initialLoadingState

    const [loadingMoreState, setLoadingMoreState] = useState({
        isLoadingMore: false,
        isLoadingMoreError: false,
        isNoMoreData: false
    })

    const loadingMoreStateRef = useRef()
    loadingMoreStateRef.current = loadingMoreState

    const [sortType, setSortType] = useState('UPLOAD_DATE_DESC');
    const uploadListData = useRef([])

    const [uploadListResultCount, setUploadListResultCount] = useState(false)

    function processResponse(responseData) {
        let newUploadListData = [...uploadListData.current, ...responseData]
        uploadListData.current = newUploadListData
    }

    // useRef for searchText, sortType, and filterData.
    // because callback will always use a stale state similar to initialLoadingStateRef
    const searchTextRef = useRef()
    searchTextRef.current = searchText

    const sortTypeRef = useRef()
    sortTypeRef.current = sortType

    const filterDataRef = useRef()
    filterDataRef.current = filterData

    // Calls the MyMilestoneList ajax, which returns the filtered/sorted/paging result.
    function callMyMilestoneUploadListAjax({ onBeforeStart, onSuccess, onError, onComplete }) {

        if (onBeforeStart) { onBeforeStart() }

        let recordSize = uploadListData.current.length
        let pageSize = 10
        let currentPage = Math.ceil(recordSize / pageSize)

        let url = PingMyMilestoneApiUrls.UPLOAD_LIST_GET
        let data = {
            sortType: sortTypeRef.current,
            searchText: searchTextRef.current,
            dateFilter: filterDataRef.current.dateFilter,
            currentPage: currentPage,
            pageSize: pageSize
        }

        securedSendRequest.execute('POST', url, data,
            (response) => {
                // success
                console.debug(response)
                if (onSuccess) { onSuccess(response) }
            },
            (error) => {
                // error
                console.error(error)
                if (onError) { onError(error) }
            },
            () => {
                // complete
                if (onComplete) { onComplete() }
            }
        )
    }

    // Calls the MyMilestoneUploadListCount ajax, which returns the total result count for the filter/search result.
    function callMyMilestoneUploadListCountAjax({ onBeforeStart, onSuccess, onError, onComplete }) {

        if (onBeforeStart) { onBeforeStart() }

        let url = PingMyMilestoneApiUrls.UPLOAD_LIST_COUNT
        let data = {
            searchText: searchTextRef.current,
            dateFilter: filterDataRef.current.dateFilter
        }

        securedSendRequest.execute('POST', url, data,
            (response) => {
                // success
                console.debug(response)
                if (onSuccess) { onSuccess(response) }
            },
            (error) => {
                // error
                console.error(error)
                if (onError) { onError(error) }
            },
            () => {
                // complete
                if (onComplete) { onComplete() }
            }
        )
    }

    //* Private function to trigger all necesary ajax call to load the listing result.
    function loadListing() {
        searchTextRef.current = searchText
        sortTypeRef.current = sortType
        filterDataRef.current = filterData

        callMyMilestoneUploadListAjax({
            onBeforeStart: () => {
                // reset the ref data
                uploadListData.current = []

                // reset the load more data
                setLoadingMoreState({
                    isLoadingMore: false,
                    isLoadingMoreError: false,
                    isNoMoreData: false
                })

                setInitialLoadingState({ ...initialLoadingState, isInitialLoading: true })
            },
            onSuccess: (response) => {
                processResponse(response.data)
                setInitialLoadingState({ ...initialLoadingState, isInitialLoading: false })
            },
            onError: (error) => {
                setInitialLoadingState({
                    ...initialLoadingState,
                    isInitialLoading: false,
                    isInitialLoadingError: true
                })
            },
            onComplete: () => { /* Do nothing */ }
        })

        callMyMilestoneUploadListCountAjax({
            onBeforeStart: () => {
                setUploadListResultCount(false)
            },
            onSuccess: (response) => {
                setUploadListResultCount(String(response.data))
            },
            onError: () => { /* Do nothing */ },
            onComplete: () => { /* Do nothing */ }
        })
    }

    // Initial load effect.
    // Will also triggered by the sort option onChange.
    // Will also triggered by the search button click.
    useEffect(loadListing, [sortType, searchText, filterData])

    // the infinite scroll
    const handleInfiniteScroll = (entries, observer) => {

        if (initialLoadingStateRef.current.isInitialLoading) {
            // do nothing. it is still initial loading.
            return
        }

        if (loadingMoreStateRef.current.isLoadingMore) {
            // Is still loading more.
            return
        }

        if (loadingMoreStateRef.current.isNoMoreData) {
            // do nothing also. There is no more data to load.
            return
        }

        callMyMilestoneUploadListAjax({
            onBeforeStart: () => {
                setLoadingMoreState({ ...loadingMoreState, isLoadingMore: true })
            },
            onSuccess: (response) => {
                if ((response) && (response.data) && (response.data.length)) {
                    // has data.
                    processResponse(response.data)
                    setLoadingMoreState({ ...loadingMoreState, isLoadingMore: false })
                }
                else {
                    // no more data.
                    setLoadingMoreState({
                        ...loadingMoreState,
                        isLoadingMore: false,
                        isNoMoreData: true
                    })
                }

            },
            onError: (error) => {
                setLoadingMoreState({
                    ...loadingMoreState,
                    isLoadingMore: false,
                    isLoadingMoreError: true
                })
            },
            onComplete: () => { /* Do nothing */ }
        })
    }

    // Upload dialog box
    const uploadDialogRef = useRef()
    const uploadClickHandler = (event) => {
        uploadDialogRef.current.openDialog()
    }

    // Download template handler
    const downloadTemplateClickHandler = (event) => {

        // Trigger a download of a static file.
        // Simulate a click on a <a href>
        let fileName = 'My Milestones - Upload Template.xlsx'
        let filePath = `${process.env.PUBLIC_URL}/static/doc/ping/${fileName}`

        let a = document.createElement('a')
        a.href = filePath
        a.setAttribute('download', fileName)
        a.click()
    }

    //* return JSX -------------------------------------------------------------
    return (
        //* Start JSX ----------------------------------------------------------
        <>
            <Grid container spacing={3} alignItems='center'>

                { /* The header */}
                <Grid item xs={6}>
                    <h3 style={{ fontWeight: 'bold' }}>{Labels.MILESTONE_UPLOAD_LIST}</h3>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                    <DownloadButtonSecondary label={Labels.DOWNLOAD_TEMPLATE}
                        onClick={downloadTemplateClickHandler}
                    />
                    <UploadButtonPrimary label={Labels.UPLOAD_FILE}
                        style={{ marginLeft: '1em' }}
                        onClick={uploadClickHandler}
                    />
                </Grid>

                <Grid item xs={6}>
                    <Typography component='div' color='textSecondary'>
                        Total {uploadListResultCount || <CircularProgress size='1em' />} result(s)
                    </Typography>
                </Grid>

                <Grid item xs={6} style={{ textAlign: 'right' }}>
                    <Typography color='textSecondary' display='inline' style={{ marginRight: '1em', textTransform: 'uppercase' }}>
                        {Labels.SORT_BY}:
                    </Typography>
                    <Select disableUnderline={true}
                        value={sortType}
                        onChange={(event) => {
                            let value = event.target.value
                            setSortType(value)
                        }}
                    >
                        <MenuItem value='UPLOAD_DATE_DESC'>{Labels.UPLOAD_DATE_DESC}</MenuItem>
                        <MenuItem value='UPLOAD_DATE_ASC'>{Labels.UPLOAD_DATE_ASC}</MenuItem>
                    </Select>
                </Grid>

                { /* The listing */}
                <Grid item xs={12} container spacing={1}>
                    {
                        (initialLoadingState.isInitialLoading == true) &&
                        (
                            /* Show a loading spinner during initial loading. */
                            <Grid item xs={12}>
                                <LoadingSpinnerLabel />
                            </Grid>
                        )
                    }

                    {
                        (initialLoadingState.isInitialLoadingError == true) &&
                        (
                            /* Show error if failed to initial load. */
                            <Grid item xs={12}>
                                <ErrorRetrievingDataLabel />
                            </Grid>
                        )
                    }

                    {
                        (uploadListData.current.length > 0) &&
                        (
                            /* Loop and map the result */
                            uploadListData.current.map((uploadRowData, index) => {
                                let key = uploadRowData.uploadId;

                                return (
                                    <MyMilestoneUploadRow
                                        key={key}
                                        data={uploadRowData}
                                        showNotification={showNotification}
                                    />
                                )
                            })
                        )
                    }
                </Grid>

                { /* Load more data observer */}
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    {
                        (loadingMoreState.isNoMoreData == true) &&
                        (
                            <span>{Labels.NO_MORE_DATA}</span>
                        )
                    }

                    {
                        (loadingMoreState.isLoadingMoreError == true) &&
                        (
                            <span style={{ color: 'red' }}>{Labels.ERROR_LOADING_MORE_DATA}</span>
                        )
                    }

                    {
                        (loadingMoreState.isNoMoreData == false) &&
                        (loadingMoreState.isLoadingMoreError == false) &&
                        (
                            <LoadMoreObserver onInView={handleInfiniteScroll}>
                                {
                                    loadingMoreState.isLoadingMore && // show spinner only when isLoadingMore
                                    <CircularProgress />
                                }
                            </LoadMoreObserver>
                        )
                    }
                </Grid>
            </Grid>

            { /* The upload dialog box */}
            <PingMyMilestoneUploadDialog
                ref={uploadDialogRef}
                showNotification={showNotification}
                onClose={(event, hasFileUploaded) => {
                    if (hasFileUploaded) {
                        loadListing()
                    }
                }}
            />
        </>
        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default MyMilestoneUploadListing
