import React from 'react'
import { CircularProgress, Container } from '@material-ui/core'

function LeftPanelLoading(props) {

    //* return JSX -------------------------------------------------------------
    return (
        //* Start JSX ----------------------------------------------------------

        <Container className="loading">
            <CircularProgress />
            <label>Loading...</label>
        </Container>

        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default LeftPanelLoading