import React, { useState, useImperativeHandle, useRef } from 'react'
import { Typography, Button, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { components, useTranslation, useServices } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import PingKeys from 'src/constants/locale/key/Ping'
import CalistaUiComponentKeys from 'src/constants/locale/key/CalistaUiComponent'
import PingMyMilestoneApiUrls from 'src/apiUrls/ping/PingMyMilestoneApiUrls'
import ErrorDeletingDataLabel from 'src/components/label/ErrorDeletingDataLabel'

const { CngDialog } = components

//* Private Ajax Functions -----------------------------------------------------
function callMyMilestoneDeleteAjax(securedSendRequest,
    { myMilestoneId, onBeforeStart, onSuccess, onError, onComplete }) {

    if (onBeforeStart) { onBeforeStart() }

    let url = PingMyMilestoneApiUrls.DELETE
    let data = { id: myMilestoneId }

    securedSendRequest.execute('POST', url, data,
        (response) => {
            // success
            console.debug(response)
            if (onSuccess) { onSuccess(response) }
        },
        (error) => {
            // error
            console.error(error)
            if (onError) { onError(error) }
        },
        () => {
            // complete
            if (onComplete) { onComplete() }
        }
    )
}

//* Main Function --------------------------------------------------------------
function PingMyMilestoneDeleteDialog({
    onCancel = () => { /* Do nothing */ },
    onDeleteSuccess = (myMilestoneId) => { /* Do nothing */ }
}, ref) {

    const { securedSendRequest } = useServices()

    const [open, setOpen] = useState(false)
    const myMilestoneIdRef = useRef()

    const [hasDeleteError, setHasDeleteError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    // Open up so the parent can call openDialog(myMilestoneId)
    useImperativeHandle(ref, () => ({
        openDialog: (myMilestoneId) => {
            myMilestoneIdRef.current = myMilestoneId

            setHasDeleteError(false)
            setOpen(true)
        }
    }));

    const confirmClickHandler = (event) => {

        let myMilestoneId = myMilestoneIdRef.current

        callMyMilestoneDeleteAjax(securedSendRequest,
            {
                myMilestoneId: myMilestoneId,
                onBeforeStart: () => { setIsLoading(true) },
                onSuccess: (response) => {
                    // Triggers parent's deleteSuccess
                    if (onDeleteSuccess) { onDeleteSuccess(myMilestoneId) }

                    setOpen(false)
                },
                onError: (error) => { setHasDeleteError(true) },
                onComplete: () => { setIsLoading(false) }
            })
    }

    //* Styles -----------------------------------------------------------------
    const useStyles = makeStyles({
        dialogRoot: {
            '& .MuiDialogTitle-root': {
                borderBottom: '0.1em solid #eeeeee',
                padding: '0.8em',
                paddingTop: '1em',

                '& .MuiTypography-root': {
                    fontWeight: 'bold',
                    fontSize: '1.1em',
                    lineHeight: '1em'
                }
            }
        }
    });

    const classes = useStyles();

    // Translations ------------------------------------------------------------
    const { translate } = useTranslation([Namespace.CALISTA_UI_COMPONENT, Namespace.PING])

    const PingMyMilestoneKeys = PingKeys.PING_MY_MILESTONES

    const Labels = {
        DIALOG_TITLE: translate(Namespace.PING, PingMyMilestoneKeys.DELETE_MILESTONE),
        DIALOG_BODY: translate(Namespace.PING, PingMyMilestoneKeys.DELETE_MILESTONE_CONFIRM),
        NO_TAKE_ME_BACK: translate(Namespace.CALISTA_UI_COMPONENT, CalistaUiComponentKeys.NO_TAKE_ME_BACK),
        DELETING: translate(Namespace.PING, PingMyMilestoneKeys.DELETING),
        YES_DELETE: translate(Namespace.CALISTA_UI_COMPONENT, CalistaUiComponentKeys.YES_DELETE)
    }

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <CngDialog
            className={classes.dialogRoot}
            open={open}
            dialogTitle={Labels.DIALOG_TITLE}

            dialogContent={
                <>
                    {
                        (hasDeleteError) &&
                        <ErrorDeletingDataLabel style={{
                            padding: 0,
                            marginBottom: '1em'
                        }} />
                    }

                    <Typography color='textPrimary' style={{ whiteSpace: 'pre-line' }}>
                        {Labels.DIALOG_BODY}
                    </Typography>
                </>
            }

            dialogAction={
                <div>
                    <Button color='primary' style={{ marginRight: '1em' }}
                        onClick={(event) => {
                            setOpen(false)
                            if (onCancel) { onCancel() }
                        }}
                    >
                        {Labels.NO_TAKE_ME_BACK}
                    </Button>
                    <Button variant='contained' color='primary'
                        onClick={confirmClickHandler}
                        disabled={isLoading}
                    >
                        {
                            isLoading ? (
                                <>
                                    <CircularProgress color='inherit' size={10} />
                                    &nbsp; {Labels.DELETING}
                                </>
                            ) : (Labels.YES_DELETE)
                        }
                    </Button>
                </div>
            }
        />
        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default React.forwardRef(PingMyMilestoneDeleteDialog)
