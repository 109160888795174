import { BasePage as CngBasePage } from 'cng-web-lib'
import EditPage from './EditPage.js'
import React from 'react'
import TablePage from './TablePage.js'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_ManageReport'

function WrappedTablePage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      renderPage={(showSnackbar) => (
        <TablePage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedEditPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      renderPage={(showSnackbar) => (
        <EditPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

export {
  WrappedEditPage as EditPage,
  WrappedTablePage as TablePage
}
