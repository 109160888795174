import React, { useState, useImperativeHandle, useRef } from 'react'
import { DialogContent } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { components, useTranslation, useServices } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'

import PingKeys from 'src/constants/locale/key/Ping'
import PingMyMilestoneApiUrls from 'src/apiUrls/ping/PingMyMilestoneApiUrls'

import UploadFormProperties from './UploadDialog_UploadFormProperties'
import UploadResultProperties from './UploadDialog_UploadResultProperties'
import { UploadStatus } from '../components/common/PingMyMilestone_Constants'

const { CngDialog } = components

//* ----------------------------------------------------------------------------
//* Private Ajax Functions
//* ----------------------------------------------------------------------------
// Generic Ajax Calls.
function _callAjax(securedSendRequest, url, data, { onBeforeStart, onSuccess, onError, onComplete }) {

    if (onBeforeStart) { onBeforeStart() }

    securedSendRequest.execute('POST', url, data,
        (response) => {
            // success
            console.debug(response)
            if (onSuccess) { onSuccess(response) }
        },
        (error) => {
            // error
            console.error(error)
            if (onError) { onError(error) }
        },
        () => {
            // complete
            if (onComplete) { onComplete() }
        }
    )
}

//* ----------------------------------------------------------------------------
// Calls the MyMilestoneList ajax, which returns the filtered/sorted/paging result.
function callMyMilestoneUploadAjax(securedSendRequest, { fileData, onBeforeStart, onSuccess, onError, onComplete }) {

    let url = PingMyMilestoneApiUrls.UPLOAD_CREATE

    let data = new FormData();
    data.append('file', fileData);

    _callAjax(securedSendRequest, url, data, {
        onBeforeStart: onBeforeStart,
        onSuccess: onSuccess,
        onError: onError,
        onComplete: onComplete
    })
}

//* ----------------------------------------------------------------------------
// Calls the MyMilestoneList ajax, which returns the filtered/sorted/paging result.
function callMyMilestoneGetInfoAjax(securedSendRequest, { id, onBeforeStart, onSuccess, onError, onComplete }) {

    let url = PingMyMilestoneApiUrls.UPLOAD_INFO_GET
    let data = { 'id': id }

    _callAjax(securedSendRequest, url, data, {
        onBeforeStart: onBeforeStart,
        onSuccess: onSuccess,
        onError: onError,
        onComplete: onComplete
    })
}

//* ----------------------------------------------------------------------------
//* Main Function
//* ----------------------------------------------------------------------------
function PingMyMilestoneUploadDialog({
    showNotification,
    onClose = (event, hasFileUploaded) => { /* Do nothing */ },
    ...props }, ref) {

    const { securedSendRequest } = useServices()

    const [open, setOpen] = useState(false)

    // Use enum and make this dialogbox like a state-machine
    const DialogState = {
        UPLOAD_FORM: 'UPLOAD_FORM',
        UPLOAD_RESULT: 'UPLOAD_RESULT'
    }
    const [dialogState, setDialogState] = useState(DialogState.UPLOAD_FORM)

    const [selectedFiles, setSelectedFiles] = useState([])
    const [uploadedFiles, setUploadedFiles] = useState([])

    const _hasFileUploaded = useRef(false) // Used for triggering refresh for the listing.

    // Open up so the parent can call openDialog(myMilestoneId)
    useImperativeHandle(ref, () => ({
        openDialog: () => {
            _hasFileUploaded.current = false
            setDialogState(DialogState.UPLOAD_FORM)
            setOpen(true)
        }
    }));

    function _updateUploadStatus(index, uploadId, uploadStatus, statusMessage = false) {

        setUploadedFiles((currentState) => {
            let newState = [...currentState]
            newState[index].uploadId = uploadId
            newState[index].uploadStatus = uploadStatus
            newState[index].statusMessage = statusMessage
            return (newState)
        })
    }

    const uploadClickHandler = (event) => {

        let _uploadedFiles = []
        selectedFiles.forEach((selectedFile, index) => {
            _uploadedFiles[index] = {
                name: selectedFile.file.name,
                size: selectedFile.file.size,
                uploadStatus: UploadStatus.UPLOADING
            }
        })

        setUploadedFiles(_uploadedFiles)
        setDialogState(DialogState.UPLOAD_RESULT)
        _hasFileUploaded.current = true

        selectedFiles.forEach((selectedFile, index) => {

            let fileData = selectedFile.file

            callMyMilestoneUploadAjax(securedSendRequest, {
                fileData: fileData,
                onBeforeStart: () => { /* Do nothing */ },
                onSuccess: (response) => {
                    let uploadId = response.data

                    // set the status as processing
                    _updateUploadStatus(index, uploadId, UploadStatus.PROCESSING)

                    // trigger the status polling for this upload id.
                    _startUploadStatusPolling(index, uploadId)
                },
                onError: (error) => { _updateUploadStatus(index, null, UploadStatus.UPLOAD_FAILED) },
                onComplete: () => { /* Do nothing */ }
            })
        })
    }

    function _startUploadStatusPolling(index, uploadId) {
        const POLL_INTERVAL = 2000; // 2 seconds
        const POLL_TIMEOUT = 15; // 15 times

        // recursive call.
        _pollUploadStatus(index, uploadId, POLL_INTERVAL, POLL_TIMEOUT)
    }

    function _pollUploadStatus(index, uploadId, pollInterval, currentTimeout) {

        if (currentTimeout <= 0) {
            // Timed out...
            _updateUploadStatus(index, uploadId, UploadStatus.TIMEOUT)
        }
        else {

            setTimeout(() => {
                callMyMilestoneGetInfoAjax(securedSendRequest, {
                    id: uploadId,
                    onBeforeStart: () => { /* Do nothing */ },
                    onSuccess: (response) => {
                        let status = response.data.status
                        let statusMessage = response.data.statusMessage

                        if (status == UploadStatus.PROCESSING) {
                            // recursively call for next poll again.
                            _pollUploadStatus(index, uploadId, pollInterval, (currentTimeout - 1))
                        }
                        else {
                            _updateUploadStatus(index, uploadId, status, statusMessage)
                        }
                    },
                    onError: (error) => { _updateUploadStatus(index, uploadId, UploadStatus.ERROR) },
                    onComplete: () => { /* Do nothing */ }
                })
            }, pollInterval)
        }
    }

    const uploadMoreClickHandler = (event) => {
        setSelectedFiles([])
        setDialogState(DialogState.UPLOAD_FORM)
    }

    const dialogCloseHandler = (event) => {
        if (onClose) {
            onClose(event, _hasFileUploaded.current)
        }

        setOpen(false)
    }

    //* Styles -----------------------------------------------------------------
    const useStyles = makeStyles({
        dialogRoot: {
            // The dialog title
            '& .MuiDialogTitle-root': {
                borderBottom: '0.1em solid #eeeeee',
                padding: '0.8em',
                paddingTop: '1em',

                '& .MuiTypography-root': {
                    fontWeight: 'bold',
                    fontSize: '1.1em',
                    lineHeight: '1em'
                }
            },

            // the dialog content body
            '& .MuiDialogContent-root': {
                background: '#5E81F405'
            }
        }
    });

    const classes = useStyles();

    // Translations ------------------------------------------------------------
    const { translate } = useTranslation(Namespace.PING)

    const PingMyMilestoneKeys = PingKeys.PING_MY_MILESTONES

    const Labels = {
        DIALOG_TITLE: translate(Namespace.PING, PingMyMilestoneKeys.UPLOAD_MILESTONES)
    }

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <CngDialog
            className={classes.dialogRoot}
            fullWidth={true}
            maxWidth='md'
            scroll='paper'

            open={open}
            dialogTitle={Labels.DIALOG_TITLE}
            shouldShowCloseButton={true}
            onCloseButtonClick={dialogCloseHandler}

            customDialogContent={
                <DialogContent>
                    {
                        (dialogState == DialogState.UPLOAD_FORM) &&
                        <UploadFormProperties.DialogContent
                            onFileSelect={(_selectedFiles) => { setSelectedFiles(_selectedFiles) }}
                            showNotification={showNotification} />
                    }
                    {
                        (dialogState == DialogState.UPLOAD_RESULT) &&
                        <UploadResultProperties.DialogContent
                            uploadedFiles={uploadedFiles}
                            onUploadMoreButtonClick={uploadMoreClickHandler}
                            showNotification={showNotification}
                        />
                    }
                </DialogContent>
            }

            dialogAction={
                <>
                    {
                        (dialogState == DialogState.UPLOAD_FORM) &&
                        <UploadFormProperties.DialogAction
                            onCloseClick={dialogCloseHandler}
                            uploadDisabled={Boolean(selectedFiles && selectedFiles.length) == false}
                            onUploadClick={uploadClickHandler}
                        />
                    }
                    {
                        (dialogState == DialogState.UPLOAD_RESULT) &&
                        <UploadResultProperties.DialogAction
                            onCloseClick={dialogCloseHandler}
                        />
                    }
                </>
            }
        />
        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default React.forwardRef(PingMyMilestoneUploadDialog)
