import * as Yup from 'yup'

import { Box, Grid, Paper } from '@material-ui/core'
import React, { useState } from 'react'
import { components, constants } from 'cng-web-lib'

import RiceStockApiUrls from 'src/apiUrls/RiceStockApiUrls'
import UploadButton from 'src/views/ricestock/custombutton/UploadButton.js'

const {
  dropzone: { CngFileUpload, HelperTextArea },
  CngGridItem,
  form: {
    adapter: {
      useFormAdapter: { useField }
    },
    CngForm
  }
} = components
const { FormState, NotificationType } = constants

function StockDetailUploadView(props) {
  const [
    shouldShowPrimaryButtonCircularProgress,
    setShouldShowPrimaryButtonCircularProgress
  ] = useState(false)
  const { showNotification } = props
  const validationSchema = Yup.object({})

  return (
    <Paper>
      <CngForm
        formikProps={{
          initialValues: { files: [] },
          validationSchema: validationSchema
        }}
        renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
          <Fields
            labelMap={labelMap}
            shouldHideMap={shouldHideMap}
            shouldDisableMap={shouldDisableMap}
            showNotification={showNotification}
          />
        )}
        renderButtonSection={(shouldShowPrimaryButtonCircularProgress) => (
          <Box p={5}>
            <UploadButton
              shouldShowPrimaryButtonCircularProgress={
                shouldShowPrimaryButtonCircularProgress
              }
              setShouldShowPrimaryButtonCircularProgress={
                setShouldShowPrimaryButtonCircularProgress
              }
              showNotification={showNotification}
              url={RiceStockApiUrls.STOCKPILE_DATA_UPLOAD}
            />
          </Box>
        )}
        formState={FormState.COMPLETED}
        fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      />
    </Paper>
  )
}

function Fields({
  labelMap,
  shouldHideMap,
  shouldDisableMap,
  showNotification
}) {
  const moreActions = [
    {
      action: 'remove',
      name: 'Remove',
      icon: ['fal', 'trash']
    }
  ]
  const [uploadFile, , { setValue: setUploadFile }] = useField('files')
  return (
    <Grid container spacing={3}>
      <CngGridItem xs={12} sm={12}>
        <Box p={5}>
          <Box alignItems='center' pt={5}>
            <CngFileUpload
              accept={['.csv']}
              maxFiles={1}
              maxSize={5242880}
              onFileSelect={(files) => {
                setUploadFile(files)
              }}
              files={uploadFile.value}
              showFormFields={false}
              moreActions={moreActions}
              onDropRejected={(errorMessages) => {
                showNotification(NotificationType.ERROR, errorMessages[0])
              }}
              renderHelperText={() => {
                return (
                  <HelperTextArea
                    accept={['.csv']}
                    maxFiles={1}
                    maxSize={5242880}
                  />
                )
              }}
            />
          </Box>
        </Box>
      </CngGridItem>
    </Grid>
  )
}

export default StockDetailUploadView
