import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useServices } from 'cng-web-lib'

import { PingSearchType } from '../PingSearch_Constants'
import PingSearchBase from './PingSearch_Base'
import BLNumMilestoneList from '../RightPanel/BLNumMilestoneList'
import PingSearchApiUrls from 'src/apiUrls/ping/PingSearchApiUrls'
import BLNumSearchLeftPanel from "../LeftPanel/BLNumSearchLeftPanel";

function PingSearch_BLNumSearch(props) {

	const { blNum } = useParams()
	const { securedSendRequest } = useServices()

	// On page load, if the page load with a container search, add the initial search into search history as well.
	function addBLNumSearchHistory(searchValue) {
		console.debug("add history [" + searchValue + "]")

		let url = PingSearchApiUrls.ADD_SEARCH_HISTORY
		let data = { searchType: PingSearchType.BL_NUM, searchValue: searchValue }

		securedSendRequest.execute('POST', url, data,
			(response) => {
				// success
				console.debug(response)
			},
			(error) => {
				// error
				console.error(error)
			},
			() => {
				// complete
			}
		)
	}

	useEffect(() => {
		if (blNum) {
			addBLNumSearchHistory(blNum)
		}
	}, [blNum]);

	// Temp state var so that even when the value is not changed, it will still trigger a refresh.
	const [searchTime, setSearchTime] = useState()

	const searchButtonClickHandler = ({ searchType, searchValue }) => {
		addBLNumSearchHistory(searchValue)
		setSearchTime(new Date())
	}

	//* return JSX -------------------------------------------------------------
	return (

		//* Start JSX ----------------------------------------------------------
		<PingSearchBase
			pageName='ping_bl_search'
			onSearchButtonClick={searchButtonClickHandler}
			leftPanelComponent={<BLNumSearchLeftPanel searchTime={searchTime} />}
			rightPanelComponent={<BLNumMilestoneList searchTime={searchTime} />}
		/>

		//* End JSX ------------------------------------------------------------
	);

	//* End of function --------------------------------------------------------
}

export default PingSearch_BLNumSearch