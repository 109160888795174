import React from 'react'
import { InputLabel, MenuItem, FormControl, Select } from '@material-ui/core'
import { PingSearchTypeCode } from '../PingSearch_Constants'

function SearchTypeDropdown({ value, onChange }) {

    //* return JSX -------------------------------------------------------------
    return (
        //* Start JSX ----------------------------------------------------------

        <FormControl id="search_by" variant="filled">
            <InputLabel id="search-by">Search By</InputLabel>
            <Select labelId="search-by"
                disableUnderline={true}
                MenuProps={{
                    PaperProps: {
                        style: {
                            background: "#FAFBFC"
                        }
                    },
                    getContentAnchorEl: null,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    }
                }}
                renderValue={(_value) => {
                    return (<span>{_value}</span>)
                }}
                value={value}
                onChange={onChange}
            >
                <MenuItem className="search-by-item" value={PingSearchTypeCode.FREIGHT_BOOKING_REF}>
                    <span className="code">{PingSearchTypeCode.FREIGHT_BOOKING_REF}</span>
                    <span className="desc">Main Carrier Booking Reference Number</span>
                </MenuItem>
                <MenuItem className="search-by-item" value={PingSearchTypeCode.BL_NUM}>
                    <span className="code">{PingSearchTypeCode.BL_NUM}</span>
                    <span className="desc">Bill of Lading Number</span>
                </MenuItem>
                <MenuItem className="search-by-item" value={PingSearchTypeCode.CONTAINER_NUM}>
                    <span className="code">{PingSearchTypeCode.CONTAINER_NUM}</span>
                    <span className="desc">Container Number</span>
                </MenuItem>
                <MenuItem className="search-by-item" value={PingSearchTypeCode.VESSEL_NAME}>
                    <span className="code">{PingSearchTypeCode.VESSEL_NAME}</span>
                    <span className="desc">Vessel Name</span>
                </MenuItem>
            </Select>
        </FormControl>

        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default SearchTypeDropdown