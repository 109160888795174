import React, {useEffect, useState, useReducer} from 'react'
import FormProperties, {generateField} from "../downloadreport/FormProperties"
import ReportDownloadApiUrls from "../../../apiUrls/ReportDownloadApiUrls";
import { useServices } from 'cng-web-lib'

export default function DynamicFieldsHolder(props) {
    const {reportId} = props

    const {fetchRecords} = useServices();
    const [reportDynamicParameters, setReportDynamicParameters] = useState([])

    const dynamicFieldDataReducer = (state, action) => {
        switch (action.type) {
            case 'RESET_DATA':
                return {
                    ...state,
                    currentUser: []
                }
            case 'UPDATE_CURRENT_USER':
                return {
                    ...state,
                    currentUser: action.payload.currentUser
                }
        }
    };

    const[dynamicFieldData, dispatchDynamicFieldData] = useReducer(
        dynamicFieldDataReducer,
        { currentUser: [] }
    );


    const getCurrentUser = () => {
        fetchRecords.execute(
            ReportDownloadApiUrls.GET_CURRENT_USER,
            undefined,
            (data) => {
                const fetchedData = { currentUser: data }
                dispatchDynamicFieldData({
                    type: 'UPDATE_CURRENT_USER',
                    payload: fetchedData
                });
            },
            (error) => { console.log("Error in getting current user: " + error) }
        );
    }

    const getReportDynamicFields = () => {
        const onSuccess = (response) => {
            setReportDynamicParameters(response.content);
        }

        const onError = (error) => {
            console.log("Error:[" + JSON.stringify(error) + "]")
        }

        const onComplete = () => {
            console.log("Finish retrieving report parameters")
        }

        fetchRecords.execute(
            ReportDownloadApiUrls.GET_BY_ID,
            {customData: {id: reportId}},
            onSuccess,
            onError,
            onComplete
        );
    }


    useEffect(() => {
        getCurrentUser()
        if(reportId) {
            getReportDynamicFields()
        }
    }, []);

    const avoidFields = ["param_from_date", "param_to_date"]

    return <>
        {reportDynamicParameters?.map((data, index) => {
            if(avoidFields.includes(data.parameterName)) {
                // Values are taken from report scheduler main from "from_date" and "to_date". So no need to generate the field
                return <></>
            } else {
                return generateField(data, index, dynamicFieldData)
            }
        })}
    </>
}