import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useServices } from 'cng-web-lib'

import { PingSearchType } from '../PingSearch_Constants'
import BookingSearchLeftPanel from '../LeftPanel/BookingSearchLeftPanel'
import PingSearchBase from './PingSearch_Base'
import BookingMilestoneList from '../RightPanel/BookingMilestoneList'
import PingSearchApiUrls from 'src/apiUrls/ping/PingSearchApiUrls'

function PingSearch_BookingSearch(props) {

	const { bookingRef } = useParams()
	const { securedSendRequest } = useServices()

	// On page load, if the page load with a container search, add the initial search into search history as well.
	function addBookingSearchHistory(searchValue) {
		console.debug("add history [" + searchValue + "]")

		let url = PingSearchApiUrls.ADD_SEARCH_HISTORY
		let data = { searchType: PingSearchType.FREIGHT_BOOKING_REF, searchValue: searchValue }

		securedSendRequest.execute('POST', url, data,
			(response) => {
				// success
				console.debug(response)
			},
			(error) => {
				// error
				console.error(error)
			},
			() => {
				// complete
			}
		)
	}

	useEffect(() => {
		if (bookingRef) {
			addBookingSearchHistory(bookingRef)
		}
	}, [bookingRef]);

	// Temp state var so that even when the value is not changed, it will still trigger a refresh.
	const [searchTime, setSearchTime] = useState()

	const searchButtonClickHandler = ({ searchType, searchValue }) => {
		addBookingSearchHistory(searchValue)
		setSearchTime(new Date())
	}

	//* return JSX -------------------------------------------------------------
	return (

		//* Start JSX ----------------------------------------------------------
		<PingSearchBase
			pageName='ping_booking_search'
			onSearchButtonClick={searchButtonClickHandler}
			leftPanelComponent={<BookingSearchLeftPanel searchTime={searchTime} />}
			rightPanelComponent={<BookingMilestoneList searchTime={searchTime} />}
		/>

		//* End JSX ------------------------------------------------------------
	);

	//* End of function --------------------------------------------------------
}

export default PingSearch_BookingSearch