import { Container, Grid, Paper } from '@material-ui/core'

import ImporterFormProperties from './ImporterFormProperties'
import React from 'react'
import RiceStockApiUrls from 'src/apiUrls/RiceStockApiUrls'
import { components } from 'cng-web-lib'
import pathMap from 'src/paths/PathMap_RiceStock'

const {
  form: { CngAddForm }
} = components

function AddPage({ history, showNotification }) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <Container maxWidth='xl'>
            <CngAddForm
              fieldLevel=''
              history={history}
              showNotification={showNotification}
              bodySection={
                <ImporterFormProperties.Fields
                  disabled={false}
                  showNotification={showNotification}
                />
              }
              formikProps={ImporterFormProperties.formikProps}
              toClientDataFormat={ImporterFormProperties.toClientDataFormat}
              toServerDataFormat={ImporterFormProperties.toServerDataFormat}
              create={{
                url: RiceStockApiUrls.IMPORTER_POST,
                successMessage: 'Add importer succeeded.',
                errorMessage: 'Add importer failed.',
                successRedirect: pathMap.IMPORTER_LIST_VIEW
              }}
            />
          </Container>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default AddPage
