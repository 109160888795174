import React, { useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Grid, Typography, Select, MenuItem, CircularProgress } from '@material-ui/core'

import { components, useTranslation, useServices } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import PingKeys from 'src/constants/locale/key/Ping'

import UploadButtonSecondary from 'src/components/button/UploadButtonSecondary'
import AddButton from 'src/components/button/AddButton'
import MyMilestoneRow from './MyMilestoneRow'
import LoadingSpinnerLabel from 'src/components/label/LoadingSpinnerLabel'
import ErrorRetrievingDataLabel from 'src/components/label/ErrorRetrievingDataLabel'
import PingMyMilestoneApiUrls from 'src/apiUrls/ping/PingMyMilestoneApiUrls'
import LoadMoreObserver from 'src/components/misc/LoadMoreObserver'
import PingMyMilestoneDeleteDialog from '../dialog-page/PingMyMilestoneDeleteDialog'

import pathMap from 'src/paths/PathMap_Ping'

const { table: { useDefaultNotification } } = components

function MyMilestoneListing({
    searchText, // The search value passed-in from the searchBox
    filterData, // The filter criteria passed-in from the filters.
    showNotification,
    ...props
}) {
    const history = useHistory()
    const { securedSendRequest } = useServices()
    const notification = useDefaultNotification(showNotification)

    // Translations ------------------------------------------------------------
    const { translate } = useTranslation(Namespace.PING)

    const PingMyMilestoneKeys = PingKeys.PING_MY_MILESTONES

    const Labels = {
        MILESTONE_LIST: translate(Namespace.PING, PingMyMilestoneKeys.MILESTONE_LIST),
        UPLOAD_MILESTONE: translate(Namespace.PING, PingMyMilestoneKeys.UPLOAD_MILESTONE),
        CREATE_MILESTONE: translate(Namespace.PING, PingMyMilestoneKeys.CREATE_MILESTONE),
        MILESTONE_DELETED: translate(Namespace.PING, PingMyMilestoneKeys.MILESTONE_DELETED),
        SORT_BY: translate(Namespace.PING, PingMyMilestoneKeys.SORT_BY),
        EVENT_DATE_DESC: translate(Namespace.PING, PingMyMilestoneKeys.EVENT_DATE_DESC),
        EVENT_DATE_ASC: translate(Namespace.PING, PingMyMilestoneKeys.EVENT_DATE_ASC),
        EVENT_NAME_ASC: translate(Namespace.PING, PingMyMilestoneKeys.EVENT_NAME_ASC),
        EVENT_NAME_DESC: translate(Namespace.PING, PingMyMilestoneKeys.EVENT_NAME_DESC),
        NO_MORE_DATA: translate(Namespace.PING, PingMyMilestoneKeys.NO_MORE_DATA),
        ERROR_LOADING_MORE_DATA: translate(Namespace.PING, PingMyMilestoneKeys.ERROR_LOADING_MORE_DATA),
    }

    //* ------------------------------------------------------------------------
    //* Search related states and effects.
    //* ------------------------------------------------------------------------
    const [initialLoadingState, setInitialLoadingState] = useState({
        isInitialLoading: true,
        isInitialLoadingError: false
    })

    // useRef for above state. Somehow callback will always use a stale state
    const initialLoadingStateRef = useRef()
    initialLoadingStateRef.current = initialLoadingState

    const [loadingMoreState, setLoadingMoreState] = useState({
        isLoadingMore: false,
        isLoadingMoreError: false,
        isNoMoreData: false
    })

    const loadingMoreStateRef = useRef()
    loadingMoreStateRef.current = loadingMoreState

    const [sortType, setSortType] = useState('EVENT_DATE_DESC');
    const myMilestoneData = useRef([])

    const [myMilestoneResultCount, setMyMilestoneResultCount] = useState(false)

    function processResponse(responseData) {
        let newMyMilestoneData = [...myMilestoneData.current, ...responseData]
        myMilestoneData.current = newMyMilestoneData
    }

    // useRef for searchText, sortType and filterData.
    // because callback will always use a stale state similar to initialLoadingStateRef
    const searchTextRef = useRef()
    searchTextRef.current = searchText

    const sortTypeRef = useRef()
    sortTypeRef.current = sortType

    const filterDataRef = useRef()
    filterDataRef.current = filterData

    // Calls the MyMilestoneList ajax, which returns the filtered/sorted/paging result.
    function callMyMilestoneListAjax({ onBeforeStart, onSuccess, onError, onComplete }) {

        if (onBeforeStart) { onBeforeStart() }

        let recordSize = myMilestoneData.current.length
        let pageSize = 10
        let currentPage = Math.ceil(recordSize / pageSize)

        let url = PingMyMilestoneApiUrls.LIST_GET
        let data = {
            sortType: sortTypeRef.current,
            searchText: searchTextRef.current,
            dateFilter: filterDataRef.current.dateFilter,
            referenceTypeFilter: filterDataRef.current.referenceTypeFilter,
            eventCodeFilter: filterDataRef.current.eventCodeFilter,
            currentPage: currentPage,
            pageSize: pageSize
        }

        securedSendRequest.execute('POST', url, data,
            (response) => {
                // success
                console.debug(response)
                if (onSuccess) { onSuccess(response) }
            },
            (error) => {
                // error
                console.error(error)
                if (onError) { onError(error) }
            },
            () => {
                // complete
                if (onComplete) { onComplete() }
            }
        )
    }

    // Calls the MyMilestoneListCount ajax, which returns the total result count for the filter/search result.
    function callMyMilestoneListCountAjax({ onBeforeStart, onSuccess, onError, onComplete }) {

        if (onBeforeStart) { onBeforeStart() }

        let url = PingMyMilestoneApiUrls.LIST_COUNT
        let data = {
            searchText: searchTextRef.current,
            dateFilter: filterDataRef.current.dateFilter,
            referenceTypeFilter: filterDataRef.current.referenceTypeFilter,
            eventCodeFilter: filterDataRef.current.eventCodeFilter
        }

        securedSendRequest.execute('POST', url, data,
            (response) => {
                // success
                console.debug(response)
                if (onSuccess) { onSuccess(response) }
            },
            (error) => {
                // error
                console.error(error)
                if (onError) { onError(error) }
            },
            () => {
                // complete
                if (onComplete) { onComplete() }
            }
        )
    }

    // Initial load effect.
    // Will also triggered by the sort option onChange.
    // Will also triggered by the search button click.
    useEffect(() => {

        searchTextRef.current = searchText
        sortTypeRef.current = sortType
        filterDataRef.current = filterData

        callMyMilestoneListAjax({
            onBeforeStart: () => {
                // reset the ref data
                myMilestoneData.current = []

                // reset the load more data
                setLoadingMoreState({
                    isLoadingMore: false,
                    isLoadingMoreError: false,
                    isNoMoreData: false
                })

                setInitialLoadingState({ ...initialLoadingState, isInitialLoading: true })
            },
            onSuccess: (response) => {
                console.debug(response)

                processResponse(response.data)
                setInitialLoadingState({ ...initialLoadingState, isInitialLoading: false })
            },
            onError: (error) => {
                console.error(error)

                setInitialLoadingState({
                    ...initialLoadingState,
                    isInitialLoading: false,
                    isInitialLoadingError: true
                })
            },
            onComplete: () => { /* Do nothing */ }
        })

        callMyMilestoneListCountAjax({
            onBeforeStart: () => {
                setMyMilestoneResultCount(false)
            },
            onSuccess: (response) => {
                setMyMilestoneResultCount(String(response.data))
            },
            onError: () => { /* Do nothing */ },
            onComplete: () => { /* Do nothing */ }
        })

    }, [sortType, searchText, filterData])

    // the infinite scroll
    const handleInfiniteScroll = (entries, observer) => {

        if (initialLoadingStateRef.current.isInitialLoading) {
            // do nothing. it is still initial loading.
            return
        }

        if (loadingMoreStateRef.current.isLoadingMore) {
            // Is still loading more.
            return
        }

        if (loadingMoreStateRef.current.isNoMoreData) {
            // do nothing also. There is no more data to load.
            return
        }

        callMyMilestoneListAjax({
            onBeforeStart: () => {
                setLoadingMoreState({ ...loadingMoreState, isLoadingMore: true })
            },
            onSuccess: (response) => {
                console.debug(response)

                if ((response) && (response.data) && (response.data.length)) {
                    // has data.
                    processResponse(response.data)
                    setLoadingMoreState({ ...loadingMoreState, isLoadingMore: false })
                }
                else {
                    // no more data.
                    setLoadingMoreState({
                        ...loadingMoreState,
                        isLoadingMore: false,
                        isNoMoreData: true
                    })
                }

            },
            onError: (error) => {
                console.error(error)

                setLoadingMoreState({
                    ...loadingMoreState,
                    isLoadingMore: false,
                    isLoadingMoreError: true
                })
            },
            onComplete: () => { /* Do nothing */ }
        })
    }

    //* ------------------------------------------------------------------------
    //* Delete Dialog related stuffs
    //* ------------------------------------------------------------------------
    const deleteDialogRef = useRef()
    const [deletedMilestoneIds, setDeletedMilestoneIds] = useState([])

    const rowDeleteClickHandler = (myMilestoneId) => {
        deleteDialogRef.current.openDialog(myMilestoneId)
    }

    const deleteDialogSuccessHandler = (myMilestoneId) => {
        setDeletedMilestoneIds([myMilestoneId, ...deletedMilestoneIds])

        // deduct the current milestone result count.
        let newResultCount = Number(myMilestoneResultCount) - 1
        setMyMilestoneResultCount(String(newResultCount))

        notification.success(Labels.MILESTONE_DELETED)
    }

    //* return JSX -------------------------------------------------------------
    return (
        //* Start JSX ----------------------------------------------------------
        <>
            <Grid container spacing={3} alignItems='center'>

                { /* The header */}
                <Grid item xs={5}>
                    <h3 style={{ fontWeight: 'bold' }}>{Labels.MILESTONE_LIST}</h3>
                </Grid>
                <Grid item xs={7} style={{ textAlign: 'right' }}>
                    <UploadButtonSecondary label={Labels.UPLOAD_MILESTONE}
                        onClick={() => {
                            history.push(pathMap.PING_MY_MILESTONES_UPLOAD_LIST_VIEW)
                        }}
                    />
                    <AddButton label={Labels.CREATE_MILESTONE}
                        style={{ marginLeft: '1em' }}
                        onClick={() => {
                            history.push(pathMap.PING_MY_MILESTONES_ADD_VIEW)
                        }}
                    />
                </Grid>

                <Grid item xs={6}>
                    <Typography component='div' color='textSecondary'>
                        Total {myMilestoneResultCount || <CircularProgress size='1em' />} result(s)
                    </Typography>
                </Grid>

                <Grid item xs={6} style={{ textAlign: 'right' }}>
                    <Typography color='textSecondary' display='inline'
                        style={{ marginRight: '1em', textTransform: 'uppercase' }}
                    >
                        {Labels.SORT_BY}:
                    </Typography>
                    <Select disableUnderline={true}
                        value={sortType}
                        onChange={(event) => {
                            let value = event.target.value
                            setSortType(value)
                        }}
                    >
                        <MenuItem value='EVENT_DATE_DESC'>{Labels.EVENT_DATE_DESC}</MenuItem>
                        <MenuItem value='EVENT_DATE_ASC'>{Labels.EVENT_DATE_ASC}</MenuItem>
                        <MenuItem value='EVENT_NAME_ASC'>{Labels.EVENT_NAME_ASC}</MenuItem>
                        <MenuItem value='EVENT_NAME_DESC'>{Labels.EVENT_NAME_DESC}</MenuItem>
                    </Select>
                </Grid>

                { /* The listing */}
                <Grid item xs={12} container spacing={1}>

                    {
                        (initialLoadingState.isInitialLoading == true) &&
                        (
                            /* Show a loading spinner during initial loading. */
                            <Grid item xs={12}>
                                <LoadingSpinnerLabel />
                            </Grid>
                        )
                    }

                    {
                        (initialLoadingState.isInitialLoadingError == true) &&
                        (
                            /* Show error if failed to initial load. */
                            <Grid item xs={12}>
                                <ErrorRetrievingDataLabel />
                            </Grid>
                        )
                    }

                    {
                        (myMilestoneData.current.length > 0) &&
                        (
                            /* Loop and map the result */
                            myMilestoneData.current.map((myMilestone, index) => {
                                let key = myMilestone.myMilestoneId;
                                let isDeleted = deletedMilestoneIds.includes(myMilestone.myMilestoneId)

                                return (
                                    <MyMilestoneRow
                                        key={key}
                                        milestone={myMilestone}
                                        onDeleteClick={rowDeleteClickHandler}
                                        isDeleted={isDeleted}
                                    />
                                )
                            })
                        )
                    }
                </Grid>

                { /* Load more data observer */}
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    {
                        (loadingMoreState.isNoMoreData == true) &&
                        (
                            <span>{Labels.NO_MORE_DATA}</span>
                        )
                    }

                    {
                        (loadingMoreState.isLoadingMoreError == true) &&
                        (
                            <span style={{ color: 'red' }}>{Labels.ERROR_LOADING_MORE_DATA}</span>
                        )
                    }

                    {
                        (loadingMoreState.isNoMoreData == false) &&
                        (loadingMoreState.isLoadingMoreError == false) &&
                        (
                            <LoadMoreObserver onInView={handleInfiniteScroll}>
                                {
                                    loadingMoreState.isLoadingMore && // show spinner only when isLoadingMore
                                    <CircularProgress />
                                }
                            </LoadMoreObserver>
                        )
                    }
                </Grid>
            </Grid>

            { /* The Delete dialog box */}
            <PingMyMilestoneDeleteDialog ref={deleteDialogRef}
                onDeleteSuccess={deleteDialogSuccessHandler}
            />
        </>
        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default MyMilestoneListing
