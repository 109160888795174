import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Grid, CircularProgress, Paper, Button } from '@material-ui/core'

import PathMap from 'src/paths/PathMap_Ping'
import TopPanelLink from './TopPanelLink'
import SearchTypeDropdown from './SearchTypeDropdown'
import VesselNameAutoComplete from './VesselNameAutoComplete'
import { PingSearchTypeCode } from '../PingSearch_Constants'

// FontAwesome imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { far as FontAwesomeRegular } from '@fortawesome/pro-regular-svg-icons'
import { library as FontAwesomeLibrary } from '@fortawesome/fontawesome-svg-core'

FontAwesomeLibrary.add(FontAwesomeRegular)

function PingSearchTopPanel({ onSearchButtonClick }) {

    const { bookingRef, containerNum, blNum, vesselName } = useParams()
    const history = useHistory()

    const [searchType, setSearchType] = useState(PingSearchTypeCode.CONTAINER_NUM);
    const [searchValue, setSearchValue] = useState("");
    const [isSearching, setIsSearching] = useState(false)

    // Init the value based on the page param.
    useEffect(() => {
        if (bookingRef) {
            setSearchType(PingSearchTypeCode.FREIGHT_BOOKING_REF)
            setSearchValue(bookingRef)
        }

        if (blNum) {
            setSearchType(PingSearchTypeCode.BL_NUM)
            setSearchValue(blNum)
        }

        if (containerNum) {
            setSearchType(PingSearchTypeCode.CONTAINER_NUM)
            setSearchValue(containerNum)
        }

        if (vesselName) {
            setSearchType(PingSearchTypeCode.VESSEL_NAME)
            setSearchValue(vesselName)
        }

    }, [bookingRef, containerNum, blNum]);

    // onClick will trigger this handler
    function handleSearch() {

        if ((searchType.trim() == "") || (searchValue.trim() == "")) {
            // do nothing.
            return
        }

        // add a fake 2 second loading spinner to prevent spam.
        setIsSearching(true)
        setTimeout(() => { setIsSearching(false) }, 2000);

        switch (searchType) {
            case PingSearchTypeCode.FREIGHT_BOOKING_REF: {
                let paramValue = bookingRef
                let pushUrl = `${PathMap.PING_SEARCH}/booking-ref/` + searchValue

                _handleSearch(paramValue, pushUrl)
                break
            }
            case PingSearchTypeCode.BL_NUM: {
                let paramValue = blNum
                let pushUrl = `${PathMap.PING_SEARCH}/bl-num/` + searchValue

                _handleSearch(paramValue, pushUrl)
                break
            }
            case PingSearchTypeCode.CONTAINER_NUM: {
                let paramValue = containerNum
                let pushUrl = `${PathMap.PING_SEARCH}/container-num/` + searchValue

                _handleSearch(paramValue, pushUrl)
                break
            }
            case PingSearchTypeCode.VESSEL_NAME: {
                let paramValue = vesselName
                let pushUrl = `${PathMap.PING_SEARCH}/vessel-name/` + searchValue

                _handleSearch(paramValue, pushUrl)
                break
            }
        }
    }

    function _handleSearch(paramValue, pushUrl) {
        //* Private method just to satisfy SonarQube cognitive complexity.
        // called by the real handleSearch().

        // don't push into history when the search is the same.
        if ((paramValue) && (paramValue == searchValue)) {

            // trigger as a search button click instead.
            if (onSearchButtonClick) { onSearchButtonClick({ searchType, searchValue }) }
        }
        else {
            history.push(pushUrl)
        }
    }

    //* return JSX -------------------------------------------------------------
    return (
        //* Start JSX ----------------------------------------------------------

        <Paper>
            <Grid container spacing={1}>
                <Grid id="top_panel_links" item xs={12} md={4} container alignItems="center">
                    <TopPanelLink href={PathMap.PING_SEARCH_FAVOURITE_LIST} icon="star" text="Favourite" />
                    <TopPanelLink href={PathMap.PING_SEARCH_HISTORY_LIST} icon="history" text="History" />
                    <TopPanelLink href={PathMap.PING_SEARCH_BOOKING_LIST} icon="list-alt" text="Bookings" />
                </Grid>

                <Grid item xs={false} md={1}>
                    { /* the whitespace between the links and the form */}
                </Grid>

                <Grid id="search_form" item xs={12} md={7}>
                    <div id="search_input">
                        <SearchTypeDropdown
                            value={searchType}
                            onChange={(event) => { setSearchType(event.target.value) }}
                        />
                        <FontAwesomeIcon id="search_icon" icon={["far", "search"]} />

                        {
                            // Normal Ping Search, use freetext.
                            ([
                                PingSearchTypeCode.FREIGHT_BOOKING_REF,
                                PingSearchTypeCode.BL_NUM,
                                PingSearchTypeCode.CONTAINER_NUM
                            ].includes(searchType) == true) &&

                            <input id="search_value" type="text" name="search-value"
                                value={searchValue}
                                onKeyUp={e => { if (e.keyCode == 13) { handleSearch() } }}
                                onChange={(event) => setSearchValue(event.target.value)}
                            />
                        }
                        {
                            // Vessel Search, use special AutoComplete dropdown.
                            (searchType == PingSearchTypeCode.VESSEL_NAME) &&

                            <VesselNameAutoComplete
                                style={{ flexGrow: 1 }}
                                value={searchValue}
                                onKeyUp={e => { if (e.keyCode == 13) { handleSearch() } }}
                                onInputChange={(event, value) => setSearchValue(value)}
                            />
                        }
                    </div>

                    <Button variant="contained" color="primary" onClick={handleSearch} disabled={isSearching}>
                        Search
                        {isSearching && <CircularProgress size={24} />}
                    </Button>
                </Grid>
            </Grid>
        </Paper>

        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default PingSearchTopPanel