import React, { useState } from 'react'
import { Tooltip } from '@material-ui/core'

// Font Awesome imports.
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { far as FontAwesomeRegular } from '@fortawesome/pro-regular-svg-icons'
import { fal as FontAwesomeLight } from '@fortawesome/pro-light-svg-icons'
import { library as FontAwesomeLibrary } from '@fortawesome/fontawesome-svg-core'

FontAwesomeLibrary.add(FontAwesomeRegular)
FontAwesomeLibrary.add(FontAwesomeLight)

function CopyToClipboardIcon({ str }) {

    const [tooltipOpen, setTooltipOpen] = useState(false)

    const handleClick = (event) => {
        navigator.clipboard.writeText(str)
        setTooltipOpen(true)

        setTimeout(function () {
            setTooltipOpen(false)
        }, 1000);
    }

    return (
        //* Start JSX ----------------------------------------------------------

        <Tooltip title="Copied to clipboard" placement="right"
            disableFocusListener
            disableHoverListener
            disableTouchListener
            open={tooltipOpen}
        >
            <div className="copy_icon" onClick={handleClick}>
                <FontAwesomeIcon icon={["fal", "clone"]} />
            </div>
        </Tooltip>


        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default CopyToClipboardIcon