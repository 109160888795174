import React from 'react'
import { Container } from '@material-ui/core'

function LeftPanelError(props) {

    function PING_STATIC_IMAGE(filename) {
        const PING_STATIC_IMAGE_DIR = `${process.env.PUBLIC_URL}/static/images/ping/`
        return (PING_STATIC_IMAGE_DIR + filename)
    }

    //* return JSX -------------------------------------------------------------
    return (
        //* Start JSX ----------------------------------------------------------

        <Container className="error">
            <img src={PING_STATIC_IMAGE("left_panel_error.svg")} />
            <label>Error retrieving data</label>
            <span>Please try again or contact system administrator.</span>
        </Container>

        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default LeftPanelError