
import HtmlTooltip from 'src/views/freightbooking/components/HtmlTooltip'
import Utils from 'src/views/common/utils/Utils'
import React from 'react'


const TempUomItems = [
  { text: '°C', value: 'degreeCelsius' },
  { text: '°F', value: 'degreeFahreinheit' },
  { text: 'K', value: 'Kelvin' },

]

function getTempDesc(unitCode){
  if(unitCode==null) return null
  let tempDesc = ''
  TempUomItems
    .filter((uomItem) => uomItem.value === unitCode)
    .forEach((selected) => {
      tempDesc = selected.text
      return null
    })

  return tempDesc

}

function constructRowComponnet(value, maxLength){
       if(Utils.isEmptyString(value)) {return <span></span>}
       else if(value.length> maxLength){
       return (
        <HtmlTooltip title={value} placement='right'>
           <span>{Utils.trimString(value, maxLength) || ""}</span>
        </HtmlTooltip>
        )
       }else{
        return (
            <span>{value || ""}</span>
         )
       }
    }

const PingMyMilestoneUtils = Object.freeze({
  TempUomItems,
  getTempDesc,
  constructRowComponnet
})
export default PingMyMilestoneUtils
