import {makeStyles} from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles(
    (theme) => ({
        tooltip: {
            fontSize: 15,
            paddingTop: 5
        }
    })
);

export default function UserCountTooltip(props) {
    const classes = useStyles(props);

    return (
        <>
            {
                props.userProfileList.map( user => (
                    <div className={classes.tooltip}>{user.userLoginId}</div>
                ) )
            }
        </>
    )
}