import { Container, Grid, Paper } from '@material-ui/core'

import ImporterFormProperties from './ImporterFormProperties'
import React from 'react'
import RiceStockApiUrls from 'src/apiUrls/RiceStockApiUrls'
import { components } from 'cng-web-lib'
import { useParams } from 'react-router-dom'

const {
  form: { CngEditForm }
} = components

function EditPage({ showNotification }) {
  const { id } = useParams()

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <Container maxWidth='xl'>
            <CngEditForm
              fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
              showNotification={showNotification}
              bodySection={
                <ImporterFormProperties.Fields
                  disabled={false}
                  showNotification={showNotification}
                />
              }
              formikProps={ImporterFormProperties.formikProps}
              toClientDataFormat={ImporterFormProperties.toClientDataFormat}
              toServerDataFormat={ImporterFormProperties.toServerDataFormat}
              fetch={{
                url: RiceStockApiUrls.IMPORTER_GET
              }}
              update={{
                url: RiceStockApiUrls.IMPORTER_PUT,
                successMessage: 'Edit dashboard configuration succeeded.',
                errorMessage: 'Edit dashboard configuration failed.'
              }}
              id={id}
            />
          </Container>
        </Paper>
      </Grid>
    </Grid>
  )
}
export default EditPage
