import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Grid, Paper, Container, FormControlLabel, Switch } from '@material-ui/core'
import { useServices } from 'cng-web-lib'

import BookingMilestoneRow from './BookingMilestoneRow'
import RightPanelLoading from './RightPanelLoading'
import RightPanelError from './RightPanelError'
import PingSearchApiUrls from 'src/apiUrls/ping/PingSearchApiUrls'
import RightPanelNoData from './RightPanelNoData';

function BookingMilestoneList({ searchTime }) {

    const { bookingRef } = useParams()
    const { securedSendRequest } = useServices()

    //* ------------------------------------------------------------------------
    //* Search related states and effects.
    //* ------------------------------------------------------------------------
    const [responseState, setResponseState] = useState({
        isLoading: false,
        hasError: false,
        responseData: {}
    })

    //* On page load or search button click, triggers the search ajax.
    useEffect(() => {
        let url = PingSearchApiUrls.BOOKING_MILESTONE_SEARCH + '/' + bookingRef
        let data = {}

        setResponseState({ isLoading: true })

        securedSendRequest.execute("GET", url, data,
            (_response) => {
                // success
                console.debug(_response)

                setResponseState({
                    isLoading: false,
                    hasError: false,
                    responseData: _response.data
                })
            },
            (error) => {
                // error
                console.error(error)

                setResponseState({
                    isLoading: false,
                    hasError: true,
                    responseData: {}
                })
            },
            () => { /* Completed. Do nothing. */ }
        )

    }, [bookingRef, searchTime])

    //* ------------------------------------------------------------------------
    //* Collapse/Expand related states and effects
    //* ------------------------------------------------------------------------
    const [expandState, setExpandState] = useState({
        // The display state, for the Expand-All switch on UI.
        displayExpandAll: false,

        // When user toggles the Collapse-All/Expand-All switch, the target state will be set into here.
        // Because when user click on individual row to collapse/expand, the display state will be reset into Collapse-All.
        //
        // null = user click on individual rows.
        // true = user switch from Collapse-All into Expand-All
        // false = user switch from Expand-All into Collapse-All
        clickState: null
    })

    // event handler for the Collapse/Expand-All toggle switch
    const handleCollapseExpandAllToggle = (event) => {
        let switchValue = event.target.checked
        setExpandState({
            displayExpandAll: switchValue,
            clickState: switchValue
        })
    }

    // event handler to pass down into the milestone rows, called by the collapse/expand of individual rows.
    // true = expand. false = collapse.
    const handleCollapseExpandRow = (prevState, targetState) => {
        // for now, hardcode to always change to show as Collapse-All.
        setExpandState({
            displayExpandAll: false,
            clickState: null
        })
    }

    //* ------------------------------------------------------------------------
    //* Return JSX - Loading...
    //* ------------------------------------------------------------------------
    if (responseState.isLoading == true) {
        return (<RightPanelLoading />)
    }

    //* ------------------------------------------------------------------------
    //* Return JSX - Error...
    //* ------------------------------------------------------------------------
    if (responseState.hasError == true) {
        return (<RightPanelError />)
    }

    //* ------------------------------------------------------------------------
    //* Return JSX - No data...
    //* ------------------------------------------------------------------------
    let response = responseState.responseData

    if (!response.milestones || !response.milestones.length) {
        return (<RightPanelNoData title="Track &amp; Trace Details" />)
    }

    //* ------------------------------------------------------------------------
    //* Return JSX - Result
    //* ------------------------------------------------------------------------
    return (
        //* Start JSX ----------------------------------------------------------

        <Paper>
            <Grid id="right_panel_top" container alignItems="center">
                <Grid id="right_panel_title" item xs={6}>
                    <h3>Track &amp; Trace Details</h3>
                </Grid>
                <Grid id="collapse_expand_control" item xs={6} checked={expandState.displayExpandAll} >
                    <FormControlLabel
                        control={
                            <Switch
                                checked={expandState.displayExpandAll}
                                onChange={handleCollapseExpandAllToggle}
                                name="isExpanded"
                                color="primary"
                            />
                        }
                        label={
                            <div data-is-expanded={expandState.displayExpandAll}>
                                <span className="collapse">Collapse All</span>
                                <span>&nbsp; / &nbsp;</span>
                                <span className="expand">Expand All</span>
                            </div>
                        }
                    />
                </Grid>
            </Grid>
            <Container id="right_panel_result">
                {
                    response.milestones.map((milestone, index) => {
                        let key = "BookingMilestoneRow_" + index;

                        return (
                            <BookingMilestoneRow
                                key={key}
                                milestone={milestone}
                                collapseExpandAll={expandState.clickState}
                                onChange={handleCollapseExpandRow}
                            />
                        )
                    })
                }
            </Container>
        </Paper>

        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default BookingMilestoneList