import React, { useState, useEffect } from 'react'
import { Checkbox, CardContent, Grid, FormControlLabel } from '@material-ui/core'

import { useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import PingKeys from 'src/constants/locale/key/Ping'

function ReferenceTypeFilter({
    clearAllFilterClickedTime,
    onChange
}) {

    // Translations ------------------------------------------------------------
    const { translate } = useTranslation(Namespace.PING)
    const FilterKeys = PingKeys.PING_MY_MILESTONES.FILTERS;

    const Labels = {
        REFERENCE_TYPE: translate(Namespace.PING, PingKeys.FIELDS.REFERENCE_TYPE),
        ALL: translate(Namespace.PING, FilterKeys.ALL),
        FREIGHT_BOOKING: translate(Namespace.PING, FilterKeys.FREIGHT_BOOKING),
        CONTAINER: translate(Namespace.PING, FilterKeys.CONTAINER)
    }

    //* ------------------------------------------------------------------------
    const DEFAULT_CHECKED_VALUES = { ALL: true }
    const [checkedValues, setCheckedValues] = useState({ ...DEFAULT_CHECKED_VALUES })

    // The 'ALL' checkbox need special handling.
    const allCheckboxChangeHandler = (event) => {
        let currentChecked = checkedValues.ALL

        // Uncheck everything else, and change the current.
        let newCheckedValues = { ...DEFAULT_CHECKED_VALUES }
        newCheckedValues.ALL = !(currentChecked)
        setCheckedValues(newCheckedValues)
    }

    // The other 2 checkboxes
    const checkboxChangeHandler = (event) => {
        let checkboxValue = event.target.value

        let currentChecked = (checkedValues[checkboxValue] == true)

        let newCheckedValues = { ...checkedValues }
        newCheckedValues[checkboxValue] = !(currentChecked)
        setCheckedValues(newCheckedValues)
    }

    // Monitor the Clear All clicked time. When changed, reset the value.
    useEffect(() => {
        setCheckedValues({ ...DEFAULT_CHECKED_VALUES })
    }, [clearAllFilterClickedTime])

    useEffect(() => {

        // Trigger the onChange when checkedValues are changed.
        if (onChange) {
            let selectedValues = []

            for (let [key, value] of Object.entries(checkedValues)) {
                if (value == true) {
                    selectedValues.push(key)
                }
            }

            onChange(selectedValues)
        }
    }, [checkedValues])

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <Grid container>
            <Grid item xs={12}>
                <CardContent style={{
                    background: '#8996AF10',
                    padding: '1em',
                    marginTop: '1em',
                    textTransform: 'uppercase',
                    fontWeight: 'bold'
                }}>
                    {Labels.REFERENCE_TYPE}
                </CardContent>
            </Grid>
            <Grid item xs={12}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <FormControlLabel label={Labels.ALL}
                                control={
                                    <Checkbox
                                        checked={checkedValues.ALL}
                                        onChange={allCheckboxChangeHandler}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={12} xl={6}>
                            <FormControlLabel label={Labels.FREIGHT_BOOKING}
                                disabled={(checkedValues.ALL == true)}
                                control={
                                    <Checkbox value='FREIGHT_BOOKING'
                                        checked={(checkedValues.ALL) || (checkedValues.FREIGHT_BOOKING)}
                                        onChange={checkboxChangeHandler}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={12} xl={6}>
                            <FormControlLabel label={Labels.CONTAINER}
                                disabled={(checkedValues.ALL == true)}
                                control={
                                    <Checkbox value='CONTAINER'
                                        checked={(checkedValues.ALL) || (checkedValues.CONTAINER)}
                                        onChange={checkboxChangeHandler}
                                    />
                                } />
                        </Grid>
                    </Grid>
                </CardContent>
            </Grid>
        </Grid>
        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default ReferenceTypeFilter
