import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation
} from 'cng-web-lib'

import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import ReportManagementKeys from "../../../constants/locale/key/ReportManagementKeys";
import ReportManagementApiUrls from "../../../apiUrls/ReportManagementApiUrls";

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    useDefaultNotification,
    useFetchCustomLookup,
    useFetchCodeMaintenanceLookup
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  },
  filter: { EQUAL }
} = constants

function TablePage(props) {
  const {
    location: { pathname },
    showNotification
  } = props

  const notification = useDefaultNotification(showNotification)
  const fetchCustomLookup = useFetchCustomLookup()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.REPORT_MANAGEMENT
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {

    let tableTitle = translate(
        Namespace.REPORT_MANAGEMENT,
        ReportManagementKeys.TITLE
    )

    let reportName = translate(
      Namespace.REPORT_MANAGEMENT,
      ReportManagementKeys.REPORT_NAME
    )

    let description = translate(
      Namespace.REPORT_MANAGEMENT,
      ReportManagementKeys.DESCRIPTION
    )

    let sheetName = translate(
      Namespace.REPORT_MANAGEMENT,
      ReportManagementKeys.SHEET_NAME
    )

    let query = translate(
      Namespace.REPORT_MANAGEMENT,
      ReportManagementKeys.QUERY
    )

    let seqNo = translate(
      Namespace.REPORT_MANAGEMENT,
      ReportManagementKeys.SEQNO
    )

    let type = translate(
      Namespace.REPORT_MANAGEMENT,
      ReportManagementKeys.TYPE
    )

    let accessLevelMasterCode = translate(
      Namespace.REPORT_MANAGEMENT,
      ReportManagementKeys.ACCESS_LEVEL_MASTER_CODE
    )

    return {
      reportName,
      description,
      sheetName,
      query,
      seqNo,
      type,
      accessLevelMasterCode,
      tableTitle
    }
  }

  const columns = [
    {
      field: "reportName",
      title: translatedTextsObject.reportName,
      defaultSort: "asc",
    },
    {
      field: "description",
      title: translatedTextsObject.description,
    },
    {
      field: "sheetName",
      title: translatedTextsObject.sheetName,
    },
    {
      field: "query",
      title: translatedTextsObject.query,
      cellStyle: { maxWidth: '100px', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"},
    },
    {
      field: "type",
      title: translatedTextsObject.type,
      customLookup: () => {
        return fetchCodeMaintenanceLookup(
            constants.CodeMaintenanceType.CODE_MASTER,
            (error) => console.error(error),
            [{ field: 'codeType', operator: EQUAL, value: 'REPORT_CATEGORY' }]
        )
      }
    },
    {
      field: "accessLevelMasterCode",
      title: translatedTextsObject.accessLevelMasterCode,
      customLookup: () => {
        return fetchCustomLookup(
            `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/account-setup/access-level-master/hdr/get`,
            { },
            'content',
            'name',
            'code',
            (error) => console.error(error)
        )
      },
    }
  ]

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              addRoute={`${pathname}/add`}
              columns={columns}
              del={{ url: ReportManagementApiUrls.DELETE }}
              editRoute={`${pathname}/edit`}
              exportData={{ url: ReportManagementApiUrls.EXPORT }}
              fetch={{ url: ReportManagementApiUrls.GET }}
              idAccessor="id"
              notification={notification}
              viewRoute={`${pathname}/view`}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default TablePage
