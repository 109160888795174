import React from 'react'
import { useHistory } from 'react-router-dom'
import { Grid } from '@material-ui/core'

import PathMap from 'src/paths/PathMap_Ping'
import { PingSearchType, PingSearchTypeCode } from '../PingSearch_Constants'

// Moment library
import Moment from 'moment';

function HistoryRow({ historyRow }) {

    const history = useHistory()

    // format the search type
    let searchTypeStr = ""
    switch (historyRow.searchType) {
        case PingSearchType.FREIGHT_BOOKING_REF: {
            searchTypeStr = PingSearchTypeCode.FREIGHT_BOOKING_REF;
            break;
        }
        case PingSearchType.BL_NUM: {
            searchTypeStr = PingSearchTypeCode.BL_NUM;
            break;
        }
        case PingSearchType.CONTAINER_NUM: {
            searchTypeStr = PingSearchTypeCode.CONTAINER_NUM;
            break;
        }
        case PingSearchType.VESSEL_NAME: {
            searchTypeStr = PingSearchTypeCode.VESSEL_NAME;
            break;
        }
    }

    // format the search datetime
    let moment = Moment(historyRow.searchTimestamp)
    const searchDatetimeStr = moment.format('D MMM yyyy - HH:mm')

    const handleClick = () => {
        switch (historyRow.searchType) {
            case PingSearchType.FREIGHT_BOOKING_REF: {
                history.push(`${PathMap.PING_SEARCH}/booking-ref/` + historyRow.searchValue)
                break
            }
            case PingSearchType.BL_NUM: {
                history.push(`${PathMap.PING_SEARCH}/bl-num/` + historyRow.searchValue)
                break
            }
            case PingSearchType.CONTAINER_NUM: {
                history.push(`${PathMap.PING_SEARCH}/container-num/` + historyRow.searchValue)
                break
            }
            case PingSearchType.VESSEL_NAME: {
                history.push(`${PathMap.PING_SEARCH}/vessel-name/` + historyRow.searchValue)
                break
            }
        }
    }

    //* return JSX -------------------------------------------------------------
    return (
        //* Start JSX ----------------------------------------------------------

        <Grid className="search_list_row history_row" container onClick={handleClick}>
            <Grid className="search_type" item xs={2} container alignItems="center">
                <span>{searchTypeStr}</span>
            </Grid>
            <Grid item xs={5} container alignItems="center">
                <div className="field">
                    <label>Reference number</label>
                    <span>{historyRow.searchValue}</span>
                </div>
            </Grid>
            <Grid item xs={5} container alignItems="center">
                <div className="field">
                    <label>Searched date</label>
                    <span>{searchDatetimeStr}</span>
                </div>
            </Grid>
        </Grid>

        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default HistoryRow