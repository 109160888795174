

import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Paper from '@material-ui/core/Paper';
import Loading from 'src/views/vesselschedule/searchschedule/Loading'


const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

const nOOp = () => {
    console.log('')
}
const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange, onChangePage, ...rest }) => {
    console.log('>>>>>>>res', rest)
    const classes = useStyles1();
    const theme = useTheme();

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

const TabelComponent = ({ columns = [], dataTabel = [], rowsPerPage = 10, page = 0, totalCount = 0, loading = false, onPageChange = nOOp, onRowsPerPageChange = nOOp, }) => {
    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table" className="tabel-component">
                <Loading loading={loading} />
                <TableHead>
                    <TableRow >
                        {columns.map((elm, index) => {
                            return <TableCell key={`h_${index}`}> <div className="head-tabel"> <div>{elm?.title}</div>  {elm?.filter ?? null}</div></TableCell>
                        })}

                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataTabel.map((row, rowKey) => {
                        let confixFirst = rowKey == 0 ? { component: "th", scope: "row" } : {}
                        return <TableRow Rowkey={`row_${rowKey}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            {columns.map((item, key) => {
                                return <TableCell className={item?.className}{...confixFirst} align="left">{item?.render ? item?.render(row[item.field], row) : row[item.field]}</TableCell>
                            })}
                        </TableRow>
                    })}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            count={totalCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: { 'aria-label': 'rows per page' },
                                native: true,
                            }}
                            onPageChange={onPageChange}
                            onRowsPerPageChange={onRowsPerPageChange}
                            onChangeRowsPerPage={onRowsPerPageChange}
                            ActionsComponent={(rest) => <TablePaginationActions {...rest} onChangePage={onPageChange} />}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    )
}
export default TabelComponent