import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Grid, Tooltip } from '@material-ui/core'
import { useServices } from 'cng-web-lib'

import PathMap from 'src/paths/PathMap_Ping'
import PingSearchApiUrls from 'src/apiUrls/ping/PingSearchApiUrls'
import { PingSearchType, PingSearchTypeCode } from '../PingSearch_Constants'

// FontAwesome imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { far as FontAwesomeRegular } from '@fortawesome/pro-regular-svg-icons'
import { fas as FontAwesomeSolid } from '@fortawesome/pro-solid-svg-icons'
import { library as FontAwesomeLibrary } from '@fortawesome/fontawesome-svg-core'

// Moment library
import Moment from 'moment';

FontAwesomeLibrary.add(FontAwesomeRegular)
FontAwesomeLibrary.add(FontAwesomeSolid)

function FavouriteRow({ favouriteRow }) {

    const history = useHistory()
    const { securedSendRequest } = useServices()

    const [isDeleted, setIsDeleted] = useState(false)

    // format the search type
    let searchTypeStr = ""
    switch (favouriteRow.searchType) {
        case PingSearchType.FREIGHT_BOOKING_REF: {
            searchTypeStr = PingSearchTypeCode.FREIGHT_BOOKING_REF;
            break;
        }
        case PingSearchType.BL_NUM: {
            searchTypeStr = PingSearchTypeCode.BL_NUM;
            break;
        }
        case PingSearchType.CONTAINER_NUM: {
            searchTypeStr = PingSearchTypeCode.CONTAINER_NUM;
            break;
        }
        case PingSearchType.VESSEL_NAME: {
            searchTypeStr = PingSearchTypeCode.VESSEL_NAME;
            break;
        }
    }

    // format the favourite datetime
    let moment = Moment(favouriteRow.favouriteTimestamp)
    const favouriteDatetimeStr = moment.format('D MMM yyyy - HH:mm')

    const handleRowClick = (event) => {
        switch (favouriteRow.searchType) {
            case PingSearchType.FREIGHT_BOOKING_REF: {
                history.push(`${PathMap.PING_SEARCH}/booking-ref/` + favouriteRow.searchValue)
                break
            }
            case PingSearchType.BL_NUM: {
                history.push(`${PathMap.PING_SEARCH}/bl-num/` + favouriteRow.searchValue)
                break
            }
            case PingSearchType.CONTAINER_NUM: {
                history.push(`${PathMap.PING_SEARCH}/container-num/` + favouriteRow.searchValue)
                break
            }
            case PingSearchType.VESSEL_NAME: {
                history.push(`${PathMap.PING_SEARCH}/vessel-name/` + favouriteRow.searchValue)
                break
            }
        }
    }

    const handleStarClick = (event) => {
        event.stopPropagation()

        let searchType = favouriteRow.searchType
        let searchValue = favouriteRow.searchValue
        let url = PingSearchApiUrls.DELETE_SEARCH_FAVOURITE
        let data = { searchType: searchType, searchValue: searchValue }

        securedSendRequest.execute("POST", url, data,
            (response) => {
                // success
                console.log(response)
            },
            (error) => {
                console.error(error)
            },
            () => {
                // completed
            }
        )

        setIsDeleted(true)
    }

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <Grid className={"search_list_row favourite_row"} container
            onClick={handleRowClick} data-deleted={isDeleted}
        >
            <Grid className="search_type" item xs={2} container alignItems="center">
                <span>{searchTypeStr}</span>
            </Grid>
            <Grid item xs={5} container alignItems="center">
                <div className="field">
                    <label>Reference number</label>
                    <span>{favouriteRow.searchValue}</span>
                </div>
            </Grid>
            <Grid item xs={4} container alignItems="center">
                <div className="field">
                    <label>Bookmarked on</label>
                    <span>{favouriteDatetimeStr}</span>
                </div>
            </Grid>
            <Grid className="star" item xs={1} container alignItems="center" onClick={handleStarClick}>
                <Tooltip title="Unbookmark this search">
                    <div className="solid_star"><FontAwesomeIcon icon={["fas", "star"]} /></div>
                </Tooltip>
                <div className="outline_star"><FontAwesomeIcon icon={["far", "star"]} /></div>
            </Grid>
        </Grid>

        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default FavouriteRow