import React, { useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { Card, CardContent, Container, Grid, Divider, Typography, CircularProgress } from '@material-ui/core'

import { components, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import PingKeys from 'src/constants/locale/key/Ping'

import FormProperties from './PingMyMilestone_ViewFormProperties'
import PingMyMilestoneApiUrls from 'src/apiUrls/ping/PingMyMilestoneApiUrls'
import PingPathMap from 'src/paths/PathMap_Ping'
import BackButton from 'src/components/button/BackButton'
import EditButton2 from 'src/components/button/EditButton2'
import DeleteButton2 from 'src/components/button/DeleteButton2'
import PingMyMilestoneDeleteDialog from '../dialog-page/PingMyMilestoneDeleteDialog'

// Moment library
import Moment from 'moment';
import 'moment-timezone';

const {
    form: { CngViewForm },
    table: { useDefaultNotification }
} = components

function ViewPage({ history, showNotification }) {

    const { id } = useParams()
    const notification = useDefaultNotification(showNotification)

    // Translations ------------------------------------------------------------
    const { translate } = useTranslation(Namespace.PING)

    const PingMyMilestoneKeys = PingKeys.PING_MY_MILESTONES
    const Labels = {
        MILESTONE_DELETED: translate(Namespace.PING, PingMyMilestoneKeys.MILESTONE_DELETED),
        BACK_TO_MILESTONES: translate(Namespace.PING, PingMyMilestoneKeys.BACK_TO_MILESTONES)
    }

    //* ------------------------------------------------------------------------
    const [fetchData, setFetchData] = useState(false)

    // format the blue header text
    function _displayReferenceType(referenceType) {
        switch (referenceType) {
            case 'FREIGHT_BOOKING': return ('FREIGHT BOOKING')
            case 'CONTAINER_NUM': return ('CONTAINER')
        }
    }

    // format the created date
    const DATE_DISPLAY_FORMAT = 'dddd, D MMM YYYY [at] HH:mm [GMT] Z'
    function _formatDate(datetime) {

        let moment = Moment(datetime)
        let str = moment.format(DATE_DISPLAY_FORMAT)
        return (str)
    }

    //* ------------------------------------------------------------------------
    //* Delete Dialog related stuffs
    //* ------------------------------------------------------------------------
    const deleteDialogRef = useRef()

    const deleteClickHandler = (event) => {
        deleteDialogRef.current.openDialog(id)
    }

    const deleteDialogSuccessHandler = (myMilestoneId) => {
        notification.success(Labels.MILESTONE_DELETED)
        history.push(PingPathMap.PING_MY_MILESTONES_LIST_VIEW)
    }

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <>
            <Card>
                <CardContent style={{ padding: '0.2rem' }}>

                    { /* The blue bar header */}
                    <Card style={{ marginBottom: '1em', background: '#5E81F433' }}>
                        <CardContent style={{ padding: '1em' }}>
                            <Typography style={{ fontWeight: 'bold' }}>
                                {
                                    fetchData ?
                                        _displayReferenceType(fetchData.referenceType)
                                        : <CircularProgress size='1em' />
                                }
                            </Typography>
                        </CardContent>
                    </Card>

                    { /* The created date and name info */}
                    <Container style={{ marginTop: '2rem', marginBottom: '2rem' }}>
                        <Typography>
                            The following event was created on &nbsp;

                            <span style={{ fontWeight: 'bold' }}>
                                {
                                    fetchData ?
                                        _formatDate(fetchData.createdDate)
                                        : <CircularProgress size='1em' />
                                }
                            </span>

                            &nbsp; by &nbsp;

                            <span style={{ fontWeight: 'bold' }}>
                                {
                                    fetchData ? fetchData.createdByName
                                        : <CircularProgress size='1em' />
                                }
                            </span>
                        </Typography>
                    </Container>

                    { /* The form content */}
                    <Container>
                        <CngViewForm
                            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                            history={history}
                            showNotification={showNotification}
                            bodySection={
                                <FormProperties.Fields
                                    disabled={true}
                                    showNotification={showNotification}
                                    id={id}
                                    fetchData={fetchData}
                                />
                            }
                            formikProps={FormProperties.formikProps}
                            toClientDataFormat={FormProperties.toClientDataFormat}
                            toServerDataFormat={FormProperties.toServerDataFormat}
                            fetch={{
                                url: PingMyMilestoneApiUrls.DETAILS_GET,
                                id: id,
                                onPostSuccess: (response) => {
                                    setFetchData(response)
                                }
                            }}
                        />
                    </Container>

                    { /* The buttons */}
                    <Divider style={{ marginTop: '1em', marginBottom: '1em' }} />
                    <Container style={{ paddingBottom: '1rem' }}>
                        <Grid container>
                            <Grid item xs={6}>
                                <BackButton label={Labels.BACK_TO_MILESTONES}
                                    onClick={() => {
                                        history.push(PingPathMap.PING_MY_MILESTONES_LIST_VIEW)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} style={{ textAlign: 'right' }} >
                                <DeleteButton2 style={{ width: '10em', marginRight: '1em' }}
                                    onClick={deleteClickHandler}
                                />

                                <EditButton2 style={{ width: '10em' }}
                                    onClick={(event) => {
                                        let editUrl = `${PingPathMap.PING_MY_MILESTONES}/edit/${id}`
                                        history.push(editUrl)
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Container>
                </CardContent>
            </Card>

            { /* The Delete dialog box */}
            <PingMyMilestoneDeleteDialog ref={deleteDialogRef}
                onDeleteSuccess={deleteDialogSuccessHandler}
            />
        </>

        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default ViewPage