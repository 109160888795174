import React, { useState, useEffect } from 'react'
import { Grid, Container } from '@material-ui/core'

// FontAwesome imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { far as FontAwesomeRegular } from '@fortawesome/pro-regular-svg-icons'
import { fas as FontAwesomeSolid } from '@fortawesome/pro-solid-svg-icons'
import { library as FontAwesomeLibrary } from '@fortawesome/fontawesome-svg-core'

// Moment library
import Moment from 'moment';
import 'moment-timezone';
import PingTranslationText from 'src/views/ping/PingTranslationText'
import PingMyMilestoneUtils from 'src/views/ping/my-milestones/form-page/PingMyMilestoneUtils'

FontAwesomeLibrary.add(FontAwesomeRegular)
FontAwesomeLibrary.add(FontAwesomeSolid)

function ContainerMilestoneRow({ milestone, onChange, collapseExpandAll }) {

    const [isCollapsed, setCollapsed] = useState(true)
    const pingTranslatedTextsObject = PingTranslationText()

    // format the event date and time
    let moment = Moment(milestone.eventDate)

    if (milestone.eventTimezone) {
        moment.tz(milestone.eventTimezone)
    }

    const eventDateStr = moment.format('D MMM yyyy')
    const eventTimeStr = moment.format('HH:mm ([GMT] Z)')

    const handleExpandButtonClick = (event) => {
        let prevState = isCollapsed
        let targetState = !isCollapsed

        setCollapsed(targetState)

        // trigger the parent's onChange handler, if applicable.
        if (onChange) {
            onChange(prevState, targetState)
        }
    }

    // Listen on the Collapse/Expand-All, and overwrite the current row's collapse/expand state if needed.
    useEffect(() => {
        if (collapseExpandAll != null) {
            // true = expand. false = collapse.
            setCollapsed(!collapseExpandAll)
        }
    }, [collapseExpandAll])

    //* return JSX -------------------------------------------------------------
    let vesselName = (milestone.eventDetails).vesselName
    let voyageNum = (milestone.eventDetails).voyageNum
    let hasEventDetails = (Boolean(vesselName) || Boolean(voyageNum))

    let eventDetails = milestone.eventDetails // remove this... because PCIOT need it.

    let isPCIOT = (eventDetails && Object.entries(eventDetails).length > 0) && milestone.eventCode == 'PCIOT'
    let isPCTSP = (eventDetails && Object.entries(eventDetails).length > 0) && milestone.eventCode == 'PCTSP'
    let isPCCOF = (eventDetails && Object.entries(eventDetails).length > 0) && milestone.eventCode == 'PCCOF'

    return (
        //* Start JSX ----------------------------------------------------------

        <Grid className="milestone_row container_milestone_row" data-is_collapsed={isCollapsed} container>
            <Grid className="milestone_date" item xs={3} container>
                <label>
                    <span className="date">{eventDateStr}</span>
                    <span className="time">{eventTimeStr}</span>
                </label>
                <div className="line">
                    <div className="dot"></div>
                </div>
            </Grid>
            <Grid className="milestone_event" item xs={9} container>
                <Container>
                    <Grid className="milestone_event_header container_milestone_event_header" container alignItems="center">
                        <Grid className="event_locode_box" xs={11}>
                            <Grid className="event_name" item >
                                <span>{milestone.eventName}</span>
                            </Grid>

                            <Grid className="event_locode" item>
                                <div>
                                    <img className="flFag" src={`${process.env.PUBLIC_URL}/static/images/countries/${milestone.eventCountryCode}.svg`} />
                                    <span>{milestone.eventLocationName}</span>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid className="arrow" item xs={1}>
                            {
                                (hasEventDetails) && (!isPCIOT) && (!isPCTSP) && (!isPCCOF)  && // show only when has event details

                                <div onClick={handleExpandButtonClick}>
                                    <FontAwesomeIcon icon={["far", "chevron-down"]} />
                                </div>
                            }
                            {
                                (isPCIOT || isPCTSP || isPCCOF) &&

                                <div onClick={handleExpandButtonClick}>
                                    <FontAwesomeIcon icon={["far", "chevron-down"]} />
                                </div>
                            }
                        </Grid>
                    </Grid>
                    {
                        (hasEventDetails)  && (!isPCIOT) && (!isPCCOF) && // show only when has event details

                        <Grid className="milestone_event_detail_wrapper container_milestone_event_detail_wrapper">
                            <Grid className="milestone_event_detail_header container_milestone_event_detail_header" container alignItems="center">
                                <Grid item xs={6}><label>Vessel Name</label></Grid>
                                <Grid item xs={6}><label>Voyage Num</label></Grid>
                            </Grid>
                            <Grid className="milestone_event_detail_row container_milestone_event_detail_row" container>
                                <Grid container alignItems="center">
                                    <Grid item xs={6} container alignItems="center">
                                        <span>{vesselName || ""}</span>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <span>{voyageNum || ""}</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    }


                    {
                        (isPCIOT) && // show only when has event details

                        <Grid className="milestone_event_detail_wrapper container_milestone_event_detail_wrapper">
                            <Grid className="milestone_event_detail_header container_milestone_event_detail_header" container alignItems="center">
                                <Grid item xs={3}><label>Bee ID</label></Grid>
                                <Grid item xs={3}><label>Temp</label></Grid>
                                <Grid item xs={3}><label>Humidity</label></Grid>
                                 <Grid item xs={3}><label>Ambience</label></Grid>
                            </Grid>
                            <Grid className="milestone_event_detail_row container_milestone_event_detail_row" container>
                                <Grid container alignItems="center">
                                    <Grid item xs={3}>
                                        <span>{milestone.eventDetails.beeId || ""}</span>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <span>{milestone.eventDetails.temp || ""}</span>
                                    </Grid>
                                      <Grid item xs={3}>
                                        <span>{milestone.eventDetails.humidity || ""}</span>
                                    </Grid>
                                      <Grid item xs={3}>
                                        <span>{milestone.eventDetails.ambience || ""}</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    }

                    {
                        (isPCTSP) && // show only when has event details

                        <Grid className="milestone_event_detail_wrapper container_milestone_event_detail_wrapper">
                            <Grid className="milestone_event_detail_header container_milestone_event_detail_header" container alignItems="center">
                                <Grid item xs={3}><label>{pingTranslatedTextsObject.minTemp}</label></Grid>
                                <Grid item xs={3}><label>{pingTranslatedTextsObject.maxTemp}</label></Grid>
                                <Grid item xs={3}><label>{pingTranslatedTextsObject.unit}</label></Grid>
                                 <Grid item xs={3}><label>{pingTranslatedTextsObject.instructions}</label></Grid>
                            </Grid>
                            <Grid className="milestone_event_detail_row container_milestone_event_detail_row" container
                            >
                                <Grid container alignItems="center">
                                    <Grid item xs={3}>
                                        <span>{milestone.eventDetails.minTemp || ""}</span>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <span>{milestone.eventDetails.maxTemp || ""}</span>
                                    </Grid>
                                      <Grid item xs={3}>
                                        <span>{PingMyMilestoneUtils.getTempDesc(milestone.eventDetails.unit) || ""}</span>
                                    </Grid>
                                      <Grid item xs={3}>
                                        {PingMyMilestoneUtils.constructRowComponnet(milestone.eventDetails.instructions, 17)}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    }

                    {
                        (isPCCOF) && // show only when has event details

                        <Grid className="milestone_event_detail_wrapper container_milestone_event_detail_wrapper">
                            <Grid className="milestone_event_detail_header container_milestone_event_detail_header" container alignItems="center">
                                <Grid item xs={6}><label>{pingTranslatedTextsObject.cutoffType}</label></Grid>
                                <Grid item xs={6}><label>{pingTranslatedTextsObject.cutoffEventType}</label></Grid>
                                
                            </Grid>
                            <Grid className="milestone_event_detail_row container_milestone_event_detail_row" container>
                                <Grid container alignItems="center">
                                    <Grid item xs={6}>
                                        {/* <span>{milestone.eventDetails.cutoffType || ""}</span> */}
                                        {PingMyMilestoneUtils.constructRowComponnet(milestone.eventDetails.cutoffType, 37)}
                                    </Grid>
                                    <Grid item xs={6}>
                                        {PingMyMilestoneUtils.constructRowComponnet(milestone.eventDetails.cutoffEventType, 37)}
                                    </Grid>
                                     
                                </Grid>
                            </Grid>
                        </Grid>
                    }

                </Container>
            </Grid>
        </Grid>

        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default ContainerMilestoneRow
