import { components, constants, useServices } from 'cng-web-lib'

import { CircularProgress } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import React from 'react';

const {
  button: { CngPrimaryButton },
  form: {
    adapter: {
      useFormAdapter: { useFormikContext }
    }
  }
} = components
const { NotificationType } = constants

function UploadButton({
  shouldShowPrimaryButtonCircularProgress,
  setShouldShowPrimaryButtonCircularProgress,
  showNotification,
  url
}) {
  const {
    formState: { isSubmitting },
    handleSubmit
  } = useFormContext()
  const { createRecord } = useServices()
  const { setFieldValue } = useFormikContext()

  function onSubmit(data) {
    return new Promise((resolve) => {
      if (data && data.files.length > 0) {
        var formData = new FormData()
        formData.append('file', data.files[0].file)
        setShouldShowPrimaryButtonCircularProgress(true)
        createRecord.execute(
          url,
          formData,
          onSuccess,
          onError,
          () => onComplete(resolve)
        )
      } else {
        showNotification(NotificationType.ERROR, 'Please upload a file')
        resolve()
      }
    })
  }

  function onSuccess(response) {
    if (response.errorMessages != null) {
      showNotification(NotificationType.ERROR, response.errorMessages[0])
    } else {
      showNotification(NotificationType.SUCCESS, 'Uploaded successfully')
    }
  }

  function onError(error) {
    showNotification(NotificationType.ERROR, error.message)
  }

  function onComplete(resolve) {
    setFieldValue('files', [])
    resolve()
  }

  return (
    <CngPrimaryButton
      type='submit'
      disabled={isSubmitting}
      onClick={handleSubmit(onSubmit)}
      endIcon={
        shouldShowPrimaryButtonCircularProgress ? (
          <CircularProgress color='inherit' size={10} />
        ) : null
      }
    >
      Upload
    </CngPrimaryButton>
  )
}

export default UploadButton
