import React from 'react'
import { Grid, Container } from '@material-ui/core'

function LeftPanelPlaceholder(props) {

    function PING_STATIC_IMAGE(filename) {
        const PING_STATIC_IMAGE_DIR = `${process.env.PUBLIC_URL}/static/images/ping/`
        return (PING_STATIC_IMAGE_DIR + filename)
    }

    //* return JSX -------------------------------------------------------------
    return (
        //* Start JSX ----------------------------------------------------------

        <Container id="placeholder_left_panel">
            <Grid item xs={12}>
                <h2>Different ways to <br />track &amp; trace</h2>
                <span>Select from bookings list, history &amp; favourites</span>
            </Grid>
            <Grid item xs={12} id="placeholder_image">
                <img src={PING_STATIC_IMAGE("left_panel_placeholder.png")}
                    srcset={`
                        ${PING_STATIC_IMAGE("left_panel_placeholder.png")}, 
                        ${PING_STATIC_IMAGE("left_panel_placeholder@2x.png")} 2x
                        `}
                />
            </Grid>
        </Container>

        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default LeftPanelPlaceholder