import React, { useState, useEffect } from 'react'
import { Card, CardContent, Typography, Grid, InputAdornment } from '@material-ui/core'
import { useFormContext } from "react-hook-form";
import { components, useTranslation, useServices, constants } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import PingKeys from 'src/constants/locale/key/Ping'
import makeValidationSchema from './PingMyMilestone_MakeValidationSchema'
import MandatoryFieldsLabel from 'src/components/label/MandatoryFieldsLabel'
import PingMyMilestoneApiUrls from 'src/apiUrls/ping/PingMyMilestoneApiUrls'
import PingMyMilestoneUtils from './PingMyMilestoneUtils'

import BookingPortDisplayField from '../components/form-fields/PingMyMilestone_BookingPortDisplayField'

// Moment library
import Moment from 'moment';
import 'moment-timezone';
import PingTranslationText from '../../PingTranslationText';

const {
    form: { field: { CngTextField, CngSelectField, CngDateTimeField, CngLookupAutocompleteField, CngServerSideAutocompleteField } }
} = components

const {
    component: {
        textField: {
            customType: { PLAIN_NUMBER }
        }
    }
} = constants

//* Field default values -------------------------------------------------------
const DEFAULT_INITIAL_VALUES = Object.freeze({
    eventCode: '',
    eventLocode: '',
    eventDatetime: '',
    referenceType: '',
    referenceValue: '',

    // server data only, for use in edit form.
    eventTimestamp: '',
    eventTimezone: '',
    eventType: '',

    // Additional Details
    vesselName: '',
    voyageNum: '',

    beeId: '',
    temp: '',
    humidity: '',
    ambience: '',

    cutoffType: '',
    cutoffEventType: '',
    instructions: '',
    unit: '',
    maxTemp: '',
    minTemp: ''


})

//* ----------------------------------------------------------------------------
const FORMIK_PROPS = {
    initialValues: { ...DEFAULT_INITIAL_VALUES },
    makeValidationSchema: makeValidationSchema
}

//* ----------------------------------------------------------------------------
//* Private functions, to reduce the stupid "Cognitive Complexity" Sonar issues.
//* ----------------------------------------------------------------------------
const EVENT_DATETIME_FORMAT = 'DD MMM YYYY HH:mm'

function _formatDate(timestamp, timezone, format) {
    let moment = Moment(timestamp)

    if (timezone) {
        moment.tz(timezone)
    }

    let str = moment.format(format)
    return (str)
}

//* ----------------------------------------------------------------------------
// Get the available "Reference Type" options after selecting an event code.
function _getReferenceTypeOptions(DEFAULT_REFERENCE_TYPE_OPTIONS, eventType) {
    let newOptions = [...DEFAULT_REFERENCE_TYPE_OPTIONS]

    if (eventType == 'INFORMATION') {
        newOptions = newOptions.filter(option => option.value == 'FREIGHT_BOOKING')
    }
    else if (eventType == 'EQUIPMENT' || eventType == 'TRANSPORT') {
        newOptions = newOptions.filter(option => option.value == 'CONTAINER_NUM')
    }

    return (newOptions)
}

//* ----------------------------------------------------------------------------
// Workaround, Hardcoded flag images.
// CNG Ticket: #193946
// Reference: https://crimsonlogic.visualstudio.com/Gets-Next%20Generation%20Calista/_workitems/edit/193946/
function _countryFlagSrc(countryCode) {
    return `${process.env.PUBLIC_URL}/static/images/countries/${countryCode}.svg`
}

//* ----------------------------------------------------------------------------
//* Private Ajax Call Functions 
//* ----------------------------------------------------------------------------
// Call AJAX to get the party name.
function callGetPartyNameAjax(securedSendRequest, { onBeforeStart, onSuccess, onError, onComplete }) {

    let url = PingMyMilestoneApiUrls.LOGIN_USER_PARTY_NAME_GET
    let data = {}

    if (onBeforeStart) { onBeforeStart() }

    securedSendRequest.execute('POST', url, data,
        (response) => {
            // success
            console.debug(response)
            if (onSuccess) { onSuccess(response) }
        },
        (error) => {
            // error
            console.error(error)
            if (onError) { onError(error) }
        },
        () => {
            // complete
            if (onComplete) { onComplete() }
        }
    )
}

//* ----------------------------------------------------------------------------
//* Main Fields function 
//* ----------------------------------------------------------------------------
function Fields({ disabled, showNotification, shouldHideMap, id, fetchData }) {

    const { securedSendRequest } = useServices()
    const { getValues, setValue } = useFormContext();
    const pingTranslatedTextsObject = PingTranslationText()
    // Translations ------------------------------------------------------------
    const { translate } = useTranslation(Namespace.PING)

    const PingMyMilestoneKeys = PingKeys.PING_MY_MILESTONES
    const PingFieldKeys = PingKeys.FIELDS

    const Labels = {

        // Card Titles
        eventDetails: translate(Namespace.PING, PingMyMilestoneKeys.EVENT_DETAILS),
        particulars: translate(Namespace.PING, PingMyMilestoneKeys.PARTICULARS),
        additionalDetails: translate(Namespace.PING, PingMyMilestoneKeys.ADDITIONAL_DETAILS),

        // Fields
        eventCode: translate(Namespace.PING, PingFieldKeys.EVENT_CODE),
        eventLocode: translate(Namespace.PING, PingFieldKeys.EVENT_LOCODE),
        eventDatetime: translate(Namespace.PING, PingFieldKeys.EVENT_DATETIME),
        eventTimezone: translate(Namespace.PING, PingFieldKeys.EVENT_TIMEZONE),
        referenceType: translate(Namespace.PING, PingFieldKeys.REFERENCE_TYPE),
        referenceValue: translate(Namespace.PING, PingFieldKeys.REFERENCE_VALUE),
        pol: translate(Namespace.PING, PingFieldKeys.POL),
        pod: translate(Namespace.PING, PingFieldKeys.POD),
        partyName: translate(Namespace.PING, PingFieldKeys.PARTY_NAME),
        vesselName: translate(Namespace.PING, PingFieldKeys.VESSEL_NAME),
        voyageNum: translate(Namespace.PING, PingFieldKeys.VOYAGE_NUM),
        beeId: translate(Namespace.PING, PingFieldKeys.BEE_ID),
        temp: translate(Namespace.PING, PingFieldKeys.TEMP),
        humidity: translate(Namespace.PING, PingFieldKeys.HUMIDITY),
        ambience: translate(Namespace.PING, PingFieldKeys.AMBIENCE),
        freightBooking: translate(Namespace.PING, PingFieldKeys.FREIGHT_BOOKING),
        containerNumber: translate(Namespace.PING, PingFieldKeys.CONTAINER_NUMBER)
    }

    //* ------------------------------------------------------------------------
    const DEFAULT_REFERENCE_TYPE_OPTIONS = [
        { text: (Labels.freightBooking), value: 'FREIGHT_BOOKING' },
        { text: (Labels.containerNumber), value: 'CONTAINER_NUM' }
    ]
    const [referenceType, setReferenceType] = useState('')
    const [beeId, setBeeId] = useState("");
    const [temp, setTemp]   = useState("");
    const [humidity, setHumidity] = useState("");
    const [ambience, setAmbience] = useState("");

    const [eventCode, setEventCode] = useState('')

    const [referenceTypeOptions, setReferenceTypeOptions] = useState(DEFAULT_REFERENCE_TYPE_OPTIONS)

    const [eventDatetime, setEventDatetime] = useState('')
    const [eventTimezone, setEventTimezone] = useState('')
    const [timezoneOffset, setTimezoneOffset] = useState('')

    const [polData, setPolData] = useState(false)
    const [podData, setPodData] = useState(false)

    const [partyName, setPartyName] = useState('')

    function _refreshReferenceTypeOptions(eventType) {
        let newOptions = _getReferenceTypeOptions(DEFAULT_REFERENCE_TYPE_OPTIONS, eventType)
        setReferenceTypeOptions(newOptions)

        let currValue = getValues('referenceType')
        var filteredData = newOptions.filter(function (el) {
          return el.value == currValue
        });
        if (filteredData.length == 0) {
            setValue('referenceType', '')
        }
    }

    const eventCodeFieldChangeHandler = (optionValue, option) => {
        setEventCode(optionValue);
        let eventType = option ? option.data.eventType : ''
        _refreshReferenceTypeOptions(eventType)
    }

    const referenceTypeChangeHandler = (event) => {
        setReferenceType(event.target.value)

        // Clear the Reference Value.
        setValue('referenceValue', '')
    }

    const bookingReferenceValueChangeHandler = (value, event) => {

        // the value is the bookingId.
        // the event.data is the bookingDTO object.
        if (event && event.data) {
            let bookingData = event.data

            setPolData({
                countryCode: bookingData.polCountryCode,
                portCode: bookingData.polCode,
                etdTimestamp: bookingData.polEtdTimestamp,
                portTimezone: bookingData.polTimezone
            })

            setPodData({
                countryCode: bookingData.podCountryCode,
                portCode: bookingData.podCode,
                etaTimestamp: bookingData.podEtaTimestamp,
                portTimezone: bookingData.podTimezone
            })
        }
        else {
            setPolData(false)
            setPodData(false)
        }
    }

    useEffect(() => {

        // refresh the timezone offset
        if (eventTimezone && eventDatetime) {

            let momentData = Moment.tz(eventDatetime, EVENT_DATETIME_FORMAT, eventTimezone)
            let offsetValue = 'GMT ' + momentData.format('Z')

            setTimezoneOffset(offsetValue)
        }
    }, [eventTimezone, eventDatetime]);

    useEffect(() => {

        callGetPartyNameAjax(securedSendRequest, {
            onBeforeStart: () => { /* Do nothing */ },
            onSuccess: (response) => {
                setPartyName(response.data)
            },
            onError: (error) => { /* Do nothing */ },
            onComplete: () => { /* Do nothing */ }
        })
    }, [])

    // Only used by edit form.
    useEffect(() => {

        if (!fetchData) {

            // do nothing.
            return;
        }

        // set the eventDatetime and eventTimezone state,
        // to trigger to refresh the timezone offset.
        let fetchedEventDatetime = _formatDate(fetchData.eventTimestamp, fetchData.eventTimezone, EVENT_DATETIME_FORMAT)
        setEventDatetime(fetchedEventDatetime)
        setEventTimezone(fetchData.eventTimezone)

        // Refresh the reference type option and value
        _refreshReferenceTypeOptions(fetchData.eventType)
        setEventCode(fetchData.eventCode);
        setReferenceType(fetchData.referenceType)

        setBeeId(fetchData.beeId)
        setTemp(fetchData.temp)
        setHumidity(fetchData.humidity)
        setAmbience(fetchData.ambience)

        // Refresh the POL and POD state, if applicable
        setPolData({
            countryCode: fetchData.polCountryCode,
            portCode: fetchData.polCode,
            etdTimestamp: fetchData.polEtdTimestamp,
            portTimezone: fetchData.polTimezone
        })

        setPodData({
            countryCode: fetchData.podCountryCode,
            portCode: fetchData.podCode,
            etaTimestamp: fetchData.podEtaTimestamp,
            portTimezone: fetchData.podTimezone
        })

    }, [fetchData])

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <Grid container spacing={3}>

            {
                //* Event details ----------------------------------------------
            }
            <Grid item xs={12}><Card><CardContent>
                <h2>{Labels.eventDetails}</h2>
                <MandatoryFieldsLabel />

                <Grid container>
                    <Grid item xs={false} xl={4}>{/* Empty space only on 1920 */}</Grid>

                    <Grid item xs={12} xl={8} container spacing={3}>
                        <Grid item xs={12}>
                            <CngLookupAutocompleteField name='eventCode'
                                label={Labels.eventCode}
                                required
                                lookupProps={{
                                    url: PingMyMilestoneApiUrls.EVENT_CODE_OPTION_GET,
                                    label: (record) => {
                                        return `(${record.eventCode}) ${record.eventDescription}`
                                    },
                                    value: 'eventCode'
                                }}

                                renderOption={(option) => (
                                    <React.Fragment>
                                        <Card variant='outlined'
                                            style={{
                                                display: 'inline-block',
                                                width: '5em',
                                                textAlign: 'center',
                                                borderRadius: '1em',
                                                marginRight: '1em'
                                            }}
                                        >
                                            <Typography color='primary'>{option.data.eventCode}</Typography>
                                        </Card>
                                        {option.data.eventDescription}
                                    </React.Fragment>
                                )}

                                onChange={eventCodeFieldChangeHandler}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <CngServerSideAutocompleteField name='eventLocode'
                                label={Labels.eventLocode}
                                required

                                searchableFields={['searchText']}
                                minFilterChars={3}
                                debounceInterval={1000}
                                lookupPageableProps={{
                                    url: PingMyMilestoneApiUrls.EVENT_LOCODE_OPTION_GET,
                                    label: (record) => {
                                        return `(${record.locationCode}) ${record.locationName}`
                                    },
                                    value: 'locationCode',
                                    pageSize: 10
                                }}

                                getOptionSelected={(option, value) => {
                                    return (option.value == value.value)
                                }}

                                renderOption={(option) => (
                                    // Only render if option has data
                                    (option.data) &&

                                    <Grid container alignItems='center'
                                        style={{
                                            height: '24px', // this is framework hardcoded...
                                            fontSize: '0.8em' // this is to make the font size smaller, to fit in...
                                        }}
                                    >
                                        <Grid item xs={2} style={{ height: '100%' }}>
                                            <img
                                                style={{ height: '100%', objectFit: 'contain' }}
                                                src={_countryFlagSrc(option.data.countryCode)}
                                            />
                                        </Grid>
                                        <Grid item xs={6} style={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap'
                                        }}>
                                            {option.data.locationName}
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Card variant='outlined'
                                                style={{
                                                    width: '5rem',
                                                    textAlign: 'center',
                                                    borderRadius: '1em'
                                                }}
                                            >
                                                <Typography color='textPrimary'>{option.data.locationCode}</Typography>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                )}

                                onChange={(optionValue, option) => {
                                    let timezoneData = option ? option.data.locationTimezone : ''
                                    setEventTimezone(timezoneData)
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CngDateTimeField
                                name='eventDatetime'
                                label={Labels.eventDatetime}
                                required
                                format={EVENT_DATETIME_FORMAT}
                                InputProps={{
                                    // because endAdornment is overwritten by the icon
                                    // workaround to use startAdornment and style it to in front of the icon.
                                    startAdornment: (
                                        <InputAdornment style={{
                                            position: 'absolute',
                                            right: '3.5em'
                                        }}>
                                            <Typography color='textSecondary'>
                                                ({timezoneOffset || 'GMT'})
                                            </Typography>
                                        </InputAdornment>
                                    )
                                }}

                                additionalOnChange={(momentObj, strValue) => {
                                    setEventDatetime(strValue)
                                }}
                            />
                        </Grid>

                        <Grid item xs={6}>{/* Empty space */}</Grid>
                        <Grid item xs={6}>
                            <CngTextField type='text'
                                name='eventTimezone'
                                label={Labels.eventTimezone}
                                disabled
                                value={eventTimezone}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent></Card></Grid>

            {
                //* Particulars ------------------------------------------------
            }
            <Grid item xs={12}><Card><CardContent>
                <h2>{Labels.particulars}</h2>
                <MandatoryFieldsLabel />

                <Grid container>
                    <Grid item xs={false} xl={4}>{/* Empty space only on 1920 */}</Grid>

                    <Grid item xs={12} xl={8} container spacing={3}>
                        <Grid item xs={6}>
                            <CngSelectField
                                name='referenceType'
                                label={Labels.referenceType}
                                required

                                items={referenceTypeOptions}
                                onChange={referenceTypeChangeHandler}
                            />
                        </Grid>

                        {
                            // Below fields will show only when reference type is FREIGHT_BOOKING.
                            (referenceType == 'FREIGHT_BOOKING') &&
                            <React.Fragment>
                                <Grid item xs={6}>
                                    <CngServerSideAutocompleteField name='referenceValue'
                                        label={Labels.referenceValue}
                                        required

                                        searchableFields={['searchText']}
                                        minFilterChars={3}
                                        debounceInterval={1000}
                                        lookupPageableProps={{
                                            url: PingMyMilestoneApiUrls.FREIGHT_BOOKING_OPTION_GET,
                                            label: 'carrierRef',
                                            value: 'bookingId',
                                            pageSize: 10
                                        }}

                                        renderOption={(option) => (
                                            <Grid container alignItems='center'
                                                style={{
                                                    height: '24px', // this is framework hardcoded...
                                                    fontSize: '0.8em' // this is to make the font size smaller, to fit in...
                                                }}
                                            >
                                                <Grid item xs={3}>
                                                    <Card variant='outlined'
                                                        style={{
                                                            textAlign: 'center',
                                                            borderRadius: '1em'
                                                        }}
                                                    >
                                                        <Typography color='textPrimary' style={{ fontSize: '0.9em' }}>{option.data.polCode} - {option.data.podCode}</Typography>
                                                    </Card>
                                                </Grid>
                                                <Grid item xs={5} style={{
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    paddingLeft: '0.5em',
                                                    paddingRight: '0.5em'
                                                }}>
                                                    <Typography color='textPrimary'>
                                                        {option.data.carrierRef}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4} style={{
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap'
                                                }}>
                                                    <Typography color='textSecondary' style={{ fontSize: '0.9em' }}>
                                                        {_formatDate(option.data.polEtdTimestamp, option.data.polTimezone, 'D MMM yyyy')}
                                                        &nbsp;-&nbsp;
                                                        {_formatDate(option.data.podEtaTimestamp, option.data.podTimezone, 'D MMM yyyy')}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        )}

                                        onChange={bookingReferenceValueChangeHandler}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <BookingPortDisplayField
                                        name='pol'
                                        label={Labels.pol}
                                        polData={polData}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <BookingPortDisplayField
                                        name='pod'
                                        label={Labels.pod}
                                        podData={podData}
                                    />
                                </Grid>
                            </React.Fragment>
                        }

                        {
                            // Below fields will only show when reference type is CONTAINER_NUM
                            (referenceType == 'CONTAINER_NUM') &&
                            <Grid item xs={6}>
                                <CngTextField type='text'
                                    name='referenceValue'
                                    label={Labels.referenceValue}
                                    required
                                />
                            </Grid>
                        }

                    </Grid>
                </Grid>
            </CardContent></Card></Grid>

            {
                //* Additional details -----------------------------------------
            }
            <Grid item xs={12}><Card><CardContent>
                <h2>{Labels.additionalDetails}</h2>
                <MandatoryFieldsLabel />

                <Grid container>
                    <Grid item xs={false} xl={4}>{/* Empty space onlyl on 1920 */}</Grid>

                    <Grid item xs={12} xl={8} container spacing={3}>
                        {
                            // Below fields will only show when reference type is CONTAINER_NUM
                            (referenceType == 'CONTAINER_NUM') 
                            && (eventCode != 'PCIOT') 
                            &&  (eventCode != 'PCTSP') 
                            &&  (eventCode != 'PCCOF') 
                            &&
                            <React.Fragment>
                                <Grid item xs={6}>
                                    <CngTextField type='text'
                                        name='vesselName'
                                        label={Labels.vesselName}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <CngTextField type='text'
                                        name='voyageNum'
                                        label={Labels.voyageNum}
                                    />
                                </Grid>
                            </React.Fragment>
                        }

                        {


                             (eventCode == 'PCIOT') &&
                                <React.Fragment>
                                <Grid item xs={6}>
                                    <CngTextField type='text'
                                            name='beeId'
                                            label={Labels.beeId}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <CngTextField type='text'
                                            name='temp'
                                            label={Labels.temp}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <CngTextField type='text'
                                            name='humidity'
                                            label={Labels.humidity}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <CngTextField type='text'
                                            name='ambience'
                                            label={Labels.ambience}
                                        />
                                    </Grid>
                                </React.Fragment>

                        }

                        {
                        (eventCode == 'PCCOF') &&  //Cut Off Time need cut off type/event type
                        <React.Fragment>
                            <Grid item xs={6}>
                                <CngTextField type='text'
                                    name='cutoffType'
                                    label={pingTranslatedTextsObject.cutoffType}
                                    inputProps={{ maxLength: 256 }}
                                    isRequired
                                    required
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <CngTextField type='text'
                                    name='cutoffEventType'
                                    label={pingTranslatedTextsObject.cutoffEventType}
                                    inputProps={{ maxLength: 256 }}
                                    isRequired
                                    required
                                />
                            </Grid>
                            
                        </React.Fragment>

                        }

{
                        (eventCode == 'PCTSP') &&  //Cut Off Time need cut off type/event type
                        <React.Fragment>
                            <Grid item xs={6}>
                                <CngTextField 
                                    type='number'
                                    name='minTemp'
                                    label={pingTranslatedTextsObject.minTemp}
                                    customType={PLAIN_NUMBER}
                                    inputProps={{ maxLength: 5 }}
                                    isRequired //for the red *
                                    required //for the form validation
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <CngTextField 
                                    type='number'
                                    name='maxTemp'
                                    inputProps={{ maxLength: 5 }}
                                    label={pingTranslatedTextsObject.maxTemp}
                                    customType={PLAIN_NUMBER}
                                    isRequired //for the red *
                                    required //for the form validation
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <CngSelectField
                                    name='unit'
                                    defaultValue="CEL"
                                    items={PingMyMilestoneUtils.TempUomItems}
                                    label={pingTranslatedTextsObject.unit}
                                    required
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <CngTextField 
                                    type='text'
                                    name='instructions'
                                    label={pingTranslatedTextsObject.instructions}
                                    inputProps={{ maxLength: 256 }}
                                />
                            </Grid>
                            
                        </React.Fragment>

                        }

                        <Grid item xs={6}>
                            <CngTextField type='text'
                                name='partyName'
                                label={Labels.partyName}
                                disabled
                                value={partyName}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent></Card></Grid>
        </Grid>
        //* End JSX ------------------------------------------------------------

    )
    //* End of function --------------------------------------------------------
}

function toClientDataFormat(serverData) {
    let localData = { ...serverData }

    // eventDatetime
    localData.eventDatetime = _formatDate(serverData.eventTimestamp, serverData.eventTimezone, EVENT_DATETIME_FORMAT)

    return localData;
}

function toServerDataFormat(localData) {
    return localData;
}

const FormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    Fields: Fields,
    toClientDataFormat: toClientDataFormat,
    toServerDataFormat: toServerDataFormat
})

export default FormProperties
