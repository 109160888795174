import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
    constants,
} from 'cng-web-lib'

const {
    locale: {
        key: {
            CommonValidationMessageKeys,
        },
    },
} = constants

function paramValidationSchema(translate) {

    let requiredMessage = translate(
        Namespace.COMMON_VALIDATION_MSG,
        CommonValidationMessageKeys.REQUIRED
    )

    return Yup.object({
        parameterName: Yup.string().required(requiredMessage),
        parameterDisplayNameEn: Yup.string().required(requiredMessage),
        fieldType: Yup.string().required(requiredMessage),
        regexValidation: Yup.string().required(requiredMessage)
    })
}

export default paramValidationSchema
