import React from 'react'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useFormContext } from 'react-hook-form'


function checkValueOption(listValue = [], listOption = []) {
    for (let index = 0; index < listValue.length; index++) {
        if (!listOption?.find((item) => item.value === listValue[index]))
            return false
    }
    return true
}
const SelectComponent = ({ options = [], label, name, value, disabled }) => {
    const { setValue, getValues } = useFormContext()
    const [selectValue, setSelectValue] = React.useState([])
    React.useEffect(() => {
        setValue(name, value ?? [])
        setSelectValue(Array.isArray(value) ? value : [])
    }, [value])
    React.useEffect(() => {
        if (options != '' && getValues(name)) {
            if (!checkValueOption(getValues(name), options)) {
                setValue(name, [])
                setSelectValue([])
            }
        }
    }, [options, getValues(name)])

    const onChangeSelect = (ev) => {
        setValue(name, ev.target.value)
        setSelectValue(ev.target.value)
    }

    return <FormControl fullWidth className='cng-select' >
        <InputLabel id="demo-controlled-open-select-label"
            style={{ marginLeft: '14px', fontSize: '1rem', color: 'rgba(0, 0, 0, 0.64)' }}
        >
            {label}
        </InputLabel>
        <Select
            variant='filled'
            className="ng-form-control"
            multiple
            onChange={onChangeSelect}
            labelId="demo-controlled-open-select-label"
            id="demo-controlled-open-select"
            value={selectValue}
            disabled={disabled}
        >
            {options != '' ? options.map((item, key) => {
                return <MenuItem key={item?.value} value={item?.value}>
                    {item?.text}
                </MenuItem>
            }) : <CircularProgress />}
        </Select>
    </FormControl>
}
export default SelectComponent;