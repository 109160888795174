import ReportSchedulerApiUrls from 'src/apiUrls/ReportSchedulerApiUrls'
import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import pathMap from 'src/paths/PathMap_Report'
import { components,useServices, DateTimeFormatter, Yup } from 'cng-web-lib'
import { useParams } from 'react-router-dom'


const {
  form: { CngEditForm }
} = components

function EditPage({ showNotification }) {
  const { id } = useParams()
  const { fetchRecords } = useServices();
  const [fetchData, setFetchData] = useState(false)
  const [editFormKey] = useState(false)

  const [formInitialValues] = useState({
    id: 0,
    title: "",
    partyId: "",
    reportId: 0,
    scheduleName: "",
    frequency: "",
    scheduledStartDate: "",
    scheduledEndDate: "",
    dynamicParameterList: [],
    userPartyReportList: [],
    reportStartDate: "",
    reportEndDate: "",
    scheduledStartTime: "",
    timezone: "",
    blankReportGenerate: false
  });

  const [validations] = useState(
      {
        reportId: Yup.string().required("Required"),
        partyId: Yup.string().required("Required"),
        scheduleName: Yup.string().required("Required"),
        frequency: Yup.string().required("Required"),
        scheduledStartDate: Yup.date().required("Required"),
        scheduledEndDate: Yup.string().nullable(),
        scheduledStartTime: Yup.string().required("Required"),
        timezone: Yup.string().required("Required"),
        blankReportGenerate: Yup.boolean().required("Required")
      }
  );

  function makeValidationSchemaUpdated() {
    return Yup.object( validations );
  }

  useEffect(() => {

    const onSuccess = (response) => {
      console.log("Success retrieving report parameters", response)
      setFetchData(response)
    }

    const onError = (error) => {
      console.log("Error:[" + JSON.stringify(error) + "]")
    }

    const onComplete = () => {
      console.log("Finish retrieving report parameters")
    }

    fetchRecords.execute(
      ReportSchedulerApiUrls.GET_BY_ID,
      { customData: { id: id } },
      onSuccess,
      onError,
      onComplete
    );
  }, [])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngEditForm
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          showNotification={showNotification}
          key={editFormKey}
          bodySection={
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
              fetchData={fetchData}
            />
          }
          formikProps={{
            validationSchema: makeValidationSchemaUpdated,
            initialValues: formInitialValues
          }}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          fetch={{
            url: ReportSchedulerApiUrls.GET
          }}
          update={{
            url: ReportSchedulerApiUrls.PUT,
            successRedirect: pathMap.REPORT_SCHEDULER_LIST_VIEW
          }}
          id={id}
        />
      </Grid>
    </Grid>
  )
}


export default EditPage;