import React, { useState, useEffect, useRef } from 'react'
import { Grid, Paper, CircularProgress } from '@material-ui/core'
import { useServices } from 'cng-web-lib'

import HistoryRow from './HistoryRow'
import PingSearchApiUrls from 'src/apiUrls/ping/PingSearchApiUrls'
import RightPanelLoading from './RightPanelLoading'
import LoadMoreObserver from 'src/components/misc/LoadMoreObserver'
import RightPanelError from './RightPanelError'

function HistoryList({ pageLoadTimestamp, ...props }) {

    const { securedSendRequest } = useServices()

    //* ------------------------------------------------------------------------
    //* Search related states and effects.
    //* ------------------------------------------------------------------------
    const [initialLoadingState, setInitialLoadingState] = useState({
        isInitialLoading: true,
        isInitialLoadingError: false
    })

    const [loadingMoreState, setLoadingMoreState] = useState({
        isLoadingMore: false,
        isLoadingMoreError: false,
        isNoMoreData: false
    })

    const searchTimestamp = useRef(Date.now())
    const historyData = useRef([])

    function processResponse(response) {

        let newHistoryData = [...historyData.current, ...response]
        historyData.current = newHistoryData

        // loop and update the maxTimestamp
        let nextSearchMaxTimestamp = searchTimestamp.current
        newHistoryData.forEach((item, index) => {
            nextSearchMaxTimestamp = Math.min(item.searchTimestamp)
        })

        searchTimestamp.current = nextSearchMaxTimestamp
    }

    function callHistoryAjax({ onBeforeStart, onSuccess, onError, onComplete }) {
        let url = PingSearchApiUrls.GET_SEARCH_HISTORY
        let data = { maxTimestamp: searchTimestamp.current }

        if (onBeforeStart) { onBeforeStart() }

        securedSendRequest.execute("POST", url, data,
            (response) => {
                console.debug(response)
                if (onSuccess) { onSuccess(response) }
            },
            (error) => {
                console.error(error)
                if (onError) { onError(error) }
            },
            () => { if (onComplete) { onComplete() } }
        )
    }

    // Initial load effect.
    useEffect(() => {
        callHistoryAjax({
            onBeforeStart: () => {
                setInitialLoadingState({ ...initialLoadingState, isInitialLoading: true })
            },
            onSuccess: (response) => {
                console.debug(response)

                processResponse(response.data)
                setInitialLoadingState({ ...initialLoadingState, isInitialLoading: false })
            },
            onError: (error) => {
                console.error(error)

                setInitialLoadingState({
                    ...initialLoadingState,
                    isInitiaLoading: false,
                    isInitialLoadingError: true
                })
            },
            onComplete: () => { /* Do nothing */ }
        })
    }, [pageLoadTimestamp])

    // the infinite scroll
    const handleInfiniteScroll = (entries, observer) => {

        if (initialLoadingState.isInitialLoading) {
            // do nothing. it is still initial loading.
            return;
        }

        if (loadingMoreState.isLoadingMore) {
            // Is still loading more.
            return;
        }

        if (loadingMoreState.isNoMoreData) {
            // do nothing also. There is no more data to load.
            return;
        }

        callHistoryAjax({
            onBeforeStart: () => {
                setLoadingMoreState({ ...loadingMoreState, isLoadingMore: true })
            },
            onSuccess: (response) => {
                console.debug(response)

                if ((response) && (response.data) && (response.data.length)) {
                    // has data.
                    processResponse(response.data)
                    setLoadingMoreState({ ...loadingMoreState, isLoadingMore: false })
                }
                else {
                    // no more data.
                    setLoadingMoreState({
                        ...loadingMoreState,
                        isLoadingMore: false,
                        isNoMoreData: true
                    })
                }
            },
            onError: (error) => {
                console.error(error)

                setLoadingMoreState({
                    ...loadingMoreState,
                    isLoadingMore: false,
                    isLoadingMoreError: true
                })
            },
            onComplete: () => {/* Do nothing */ }
        })
    }

    //* ------------------------------------------------------------------------
    //* Return JSX - Loading...
    //* ------------------------------------------------------------------------
    if (initialLoadingState.isInitialLoading == true) {
        return (<RightPanelLoading />)
    }

    //* ------------------------------------------------------------------------
    //* Return JSX - Error...
    //* ------------------------------------------------------------------------
    if (initialLoadingState.isInitialLoadingError == true) {
        return (<RightPanelError />)
    }

    //* ------------------------------------------------------------------------
    //* Return JSX - Result
    //* ------------------------------------------------------------------------
    return (
        //* Start JSX ----------------------------------------------------------

        <Paper>
            <Grid id="right_panel_top" container>
                <Grid id="right_panel_title" item xs={12}>
                    <h3>History</h3>
                </Grid>
                <Grid id="result_count" item xs={6}>
                    <span>Your last {historyData.current.length} searches.</span>
                </Grid>
            </Grid>
            <Grid id="right_panel_result" container direction="column">
                {
                    historyData.current.map((historyRow, index) => {
                        let key = "HistoryRow_" + index;

                        return (
                            <HistoryRow
                                key={key}
                                historyRow={historyRow}
                            />
                        )
                    })
                }
                <Grid className="load_more" item xs={12}>
                    {(
                        () => { // use annon function to return if-else condition.
                            if (loadingMoreState.isNoMoreData) {
                                return (<span>No more data to load.</span>)
                            }
                            else if (loadingMoreState.isLoadingMoreError) {
                                return (<span>Error loading more data.</span>)
                            }
                            else {
                                return (
                                    <LoadMoreObserver onInView={handleInfiniteScroll}>
                                        {
                                            loadingMoreState.isLoadingMore && // show spinner only when isLoadingMore
                                            <CircularProgress />
                                        }
                                    </LoadMoreObserver>
                                )
                            }
                        }
                    )()}
                </Grid>
            </Grid>
        </Paper>

        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default HistoryList