import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Tooltip, Typography } from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import PingKeys from 'src/constants/locale/key/Ping'

import { UploadStatus } from './PingMyMilestone_Constants'

//* Styles ---------------------------------------------------------------------
const useStyles = makeStyles({
    tooltip: {
        fontSize: '0.9em'
    }
});

//* ----------------------------------------------------------------------------
function UploadStatusLabel({
    uploadStatus,
    statusMessage = false
}) {
    const classes = useStyles();

    // Translations ------------------------------------------------------------
    const { translate } = useTranslation(Namespace.PING)
    const UploadStatusKeys = PingKeys.PING_MY_MILESTONES.UPLOAD_STATUS;

    const UploadStatusLabels = {
        UPLOADING: translate(Namespace.PING, UploadStatusKeys.UPLOADING),
        UPLOAD_FAILED: translate(Namespace.PING, UploadStatusKeys.UPLOAD_FAILED),
        PROCESSING: translate(Namespace.PING, UploadStatusKeys.PROCESSING),
        SUCCESS: translate(Namespace.PING, UploadStatusKeys.SUCCESS),
        FILE_ERROR: translate(Namespace.PING, UploadStatusKeys.FILE_ERROR),
        TIMEOUT: translate(Namespace.PING, UploadStatusKeys.TIMEOUT)
    }

    //* ------------------------------------------------------------------------
    // Depending on the status, return different JSX elements.
    let isLoading = false
    let uploadStatusText = uploadStatus
    let typoProps = {}

    switch (uploadStatus) {
        case UploadStatus.UPLOADING: {
            isLoading = true
            uploadStatusText = UploadStatusLabels.UPLOADING
            break
        }
        case UploadStatus.UPLOAD_FAILED: {
            uploadStatusText = UploadStatusLabels.UPLOAD_FAILED
            typoProps = { color: 'error' }
            break
        }
        case UploadStatus.PROCESSING: {
            isLoading = true
            uploadStatusText = UploadStatusLabels.PROCESSING
            break
        }
        case UploadStatus.SUCCESS: {
            uploadStatusText = UploadStatusLabels.SUCCESS
            typoProps = { style: { color: '#7CE7AC' } }
            break
        }
        case UploadStatus.ERROR: {
            uploadStatusText = UploadStatusLabels.FILE_ERROR
            typoProps = { color: 'error' }
            break
        }
        case UploadStatus.TIMEOUT: {
            uploadStatusText = UploadStatusLabels.TIMEOUT
            break
        }
    }

    return (
        //* Start JSX ----------------------------------------------------------
        <>
            <Typography
                style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}
                {...typoProps}
            >
                {
                    (isLoading == true) &&
                    <CircularProgress size='1em' style={{ marginRight: '0.5em' }} />
                }

                {uploadStatusText}

                {
                    (statusMessage) &&
                    <Tooltip title={statusMessage} classes={{ tooltip: classes.tooltip }}>
                        <InfoOutlinedIcon style={{
                            color: '#8996AF',
                            fontSize: '1.2em',
                            marginLeft: '0.5em'
                        }} />
                    </Tooltip>
                }
            </Typography>
        </>
        //* End JSX ------------------------------------------------------------
    )
}

export default UploadStatusLabel
