import React, {useEffect, useState} from 'react'
import {components, useServices} from 'cng-web-lib'
import ReportDownloadApiUrls from "../../../apiUrls/ReportDownloadApiUrls";

const {
    card: {
        CngSimpleCardHeader,
    },
    form: {
        adapter: {
            useFormAdapter:{ useField, useFormikContext }
        },
        field: {
            CngTextField,
            CngSelectField,
            CngDateField,
            CngDateTimeField,
            CngCodeMasterAutocompleteField
        },
    }
} = components

export default function CurrentUserPartyName(props) {

    const {fetchRecords} = useServices();
    const [currentParty, setCurrentParty] = useState({partyId:'', partyName:''});

    useEffect( () => {

        const onSuccess = (data) => {
            setCurrentParty({partyId: data[0].value, partyName: data[0].text})
        }

        const onError = (error) => {
            console.log("Error:[" + JSON.stringify(error) + "]")
        }

        const onComplete = () => {
            console.log("Finish retrieving report parameters")
        }

        fetchRecords.execute(
            ReportDownloadApiUrls.GET_CURRENT_USER_PARTY,
            {},
            onSuccess,
            onError,
            onComplete
        );

    },[]);

    return (
        <CngSelectField
            name={props.response.parameterName}
            label={props.response.parameterDisplayNameEn}
            disabled={false}
            items={
                [
                    { text: currentParty.partyName, value: currentParty.partyName }
                ]
            }
        />
    )

}