import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation
} from 'cng-web-lib'

import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import ReportDownloadKeys from "../../../constants/locale/key/ReportManagementKeys";
import ReportDownloadApiUrls from "../../../apiUrls/ReportDownloadApiUrls";
import ReportManagementApiUrls from "../../../apiUrls/ReportManagementApiUrls";

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    useDefaultNotification,
    useFetchCustomLookup,
    useFetchCodeMaintenanceLookup
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  }
} = constants

function TablePage(props) {
  const {
    location: { pathname },
    showNotification
  } = props

  const notification = useDefaultNotification(showNotification)
  const fetchCustomLookup = useFetchCustomLookup()
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.REPORT_MANAGEMENT
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {

    let reportId = translate(
      Namespace.REPORT_MANAGEMENT,
      ReportDownloadKeys.REPORT_ID
    )

    let tableTitle = translate(
      Namespace.REPORT_MANAGEMENT,
      ReportDownloadKeys.ReportParameterList.TITLE
    )

    let parameterName = translate(
      Namespace.REPORT_MANAGEMENT,
      ReportDownloadKeys.ReportParameterList.PARAMETER_NAME
    )

    let parameterDisplayName = translate(
      Namespace.REPORT_MANAGEMENT,
      ReportDownloadKeys.ReportParameterList.PARAMETER_DISPLAY_NAME
    )

    let parameterValue = translate(
      Namespace.REPORT_MANAGEMENT,
      ReportDownloadKeys.ReportParameterList.PARAMETER_VALUE
    )

    return {
      parameterName,
      parameterDisplayName,
      parameterValue,
      tableTitle,
      reportId
    }
  }

  const columns = [
    {
      field: "id",
      title: translatedTextsObject.reportId,
      customLookup: () => {
        return fetchCustomLookup(
          `${ReportManagementApiUrls.GET_BY_USER_ID}`,
          {},
          'content',
          'reportName',
          'id',
          (error) => console.error(error)
        )
      }
    }
  ]

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              addRoute={`${pathname}/add`}
              columns={columns}
              del={{ url: "" }}
              editRoute={`${pathname}/edit`}
              exportData={{ url: ReportDownloadApiUrls.EXPORT }}
              fetch={{ url: ReportManagementApiUrls.GET_BY_USER_ID }}
              idAccessor="id"
              notification={notification}
              tableConfigurationCode='REPORT_DOWNLOAD_TABLE_CONFIG'
              viewRoute={`${pathname}/view`}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default TablePage
