import React from 'react'
import { Card, CardContent, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import fileDownload from 'js-file-download'

import { components, useServices, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'

import KebabMenuButton from 'src/components/button/KebabMenuButton'
import FileIcon from '../components/common/FileIcon'
import UploadStatusLabel from '../components/common/UploadStatusLabel'

import PingMyMilestoneApiUrls from 'src/apiUrls/ping/PingMyMilestoneApiUrls'
import CalistaUiComponentKeys from 'src/constants/locale/key/CalistaUiComponent'

// FontAwesome imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { far as FontAwesomeRegular } from '@fortawesome/pro-regular-svg-icons'
import { library as FontAwesomeLibrary } from '@fortawesome/fontawesome-svg-core'

// Moment library
import Moment from 'moment';
import 'moment-timezone';

FontAwesomeLibrary.add(FontAwesomeRegular)

const { table: { useDefaultNotification } } = components

//* Styles ---------------------------------------------------------------------
const useStyles = makeStyles({
    // for the hover animations
    root_card: {
        borderRadius: "1em",
        transition: "background-color 0.5s",
        "&:hover": { backgroundColor: "#F9FAFF" }
    }
});

//* ----------------------------------------------------------------------------
function MyMilestoneUploadRow({ data, showNotification }) {

    const classes = useStyles()
    const { securedSendRequest } = useServices()
    const notification = useDefaultNotification(showNotification)

    //* Translations -----------------------------------------------------------
    const { translate } = useTranslation([Namespace.CALISTA_UI_COMPONENT, Namespace.PING])

    const Labels = {
        FAILED_TO_DOWNLOAD_FILE: translate(Namespace.CALISTA_UI_COMPONENT, CalistaUiComponentKeys.FAILED_TO_DOWNLOAD_FILE),
    }

    //* ------------------------------------------------------------------------
    function bytesToSize(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    }

    // format the event date and time
    let moment = Moment(data.uploadDate)
    const updateDateStr = moment.format('DD MMM yyyy - HH:mm')

    // Download function
    function _downloadFile(filename, uploadId) {
        let url = PingMyMilestoneApiUrls.UPLOAD_DOWNLOAD_DATA_GET
        let postData = { id: uploadId }
        let config = { responseType: "blob" }

        securedSendRequest.execute("POST", url, postData,
            (response) => {
                // Success
                console.debug(response)
                fileDownload(response.data, filename)
            },
            (error) => {
                console.error(error)
                notification.error(Labels.FAILED_TO_DOWNLOAD_FILE)
            },
            () => { /* Complete. Do nothing. */ },
            config
        )
    }

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <Grid item xs={12} className={classes.root}>
            <Card variant="outlined" className={classes.root_card}>
                <CardContent>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={1}><FileIcon /></Grid>

                        <Grid item xs={5} container>
                            <Grid item xs={12} style={{ paddingRight: "2em" }}>
                                <Typography color="textPrimary" style={{
                                    fontWeight: "bold",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                }}>
                                    {data.fileName}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography color="textSecondary">
                                    {bytesToSize(data.fileSize)}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid item xs={2}>
                            <UploadStatusLabel
                                uploadStatus={data.uploadStatus}
                                statusMessage={data.statusMessage}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <Typography color="textSecondary">
                                <FontAwesomeIcon icon={["fal", "arrow-square-up"]} style={{ marginRight: "0.5em" }} />
                                {updateDateStr}
                            </Typography>
                        </Grid>

                        <Grid item xs={1} container spacing={1} alignItems='center' justify='flex-end'>
                            <KebabMenuButton
                                hasDownload={true}
                                onDownloadClick={(event) => { _downloadFile(data.fileName, data.uploadId) }}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default MyMilestoneUploadRow
