import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

// FontAwesome imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { far as FontAwesomeRegular } from '@fortawesome/pro-regular-svg-icons'
import { library as FontAwesomeLibrary } from '@fortawesome/fontawesome-svg-core'

FontAwesomeLibrary.add(FontAwesomeRegular)

//* Styles ---------------------------------------------------------------------
const useStyles = makeStyles({
    root: {
        fontSize: '1.5em',
        width: '1.75em', height: '1.75em',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '100%',
        color: '#8996AFCC',
        background: '#8996AF10'
    }
});

//* ----------------------------------------------------------------------------
function FileIcon({ iconProps, ...props }) {
    const classes = useStyles();

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <div className={classes.root} {...props}>
            <FontAwesomeIcon icon={['fal', 'file-excel']} {...iconProps} />
        </div>
        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default FileIcon
