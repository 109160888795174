import { components, useTranslation } from 'cng-web-lib'

import Grid from '@material-ui/core/Grid'
import Namespace from 'src/constants/locale/Namespace.js'
import React from 'react'
import RiceStockKeys from 'src/constants/locale/key/RiceStock'
import makeValidationSchema from './ImporterMakeValidationSchema.js'

const {
  form: {
    field: { CngTextField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  criteriaQty: '',
  importQty: '',
  importerId: '',
  miq: '',
  name: '',
  packingPlantAvail: '',
  psq: '',
  riceType: '',
  type: ''
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.RICE_STOCK)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let criteriaQty = translate(
      Namespace.RICE_STOCK,
      RiceStockKeys.IMPORTER.CRITERIA_QTY
    )
    let importQty = translate(
      Namespace.RICE_STOCK,
      RiceStockKeys.IMPORTER.IMPORT_QTY
    )
    let importerId = translate(
      Namespace.RICE_STOCK,
      RiceStockKeys.IMPORTER.IMPORTER_ID
    )
    let miq = translate(Namespace.RICE_STOCK, RiceStockKeys.IMPORTER.MIQ)
    let name = translate(Namespace.RICE_STOCK, RiceStockKeys.IMPORTER.NAME)
    let packingPlantAvail = translate(
      Namespace.RICE_STOCK,
      RiceStockKeys.IMPORTER.PACKING_PLANT_AVAIL
    )
    let psq = translate(Namespace.RICE_STOCK, RiceStockKeys.IMPORTER.PSQ)
    let riceType = translate(
      Namespace.RICE_STOCK,
      RiceStockKeys.IMPORTER.RICE_TYPE
    )
    let type = translate(Namespace.RICE_STOCK, RiceStockKeys.IMPORTER.TYPE)

    return {
      criteriaQty,
      importQty,
      importerId,
      miq,
      name,
      packingPlantAvail,
      psq,
      riceType,
      type
    }
  }

  return (
    <Grid container spacing={3}>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.importerId}>
        <CngTextField
          name='importerId'
          label={translatedTextsObject.importerId + ' *'}
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.criteriaQty}>
        <CngTextField
          name='criteriaQty'
          label={translatedTextsObject.criteriaQty + ' *'}
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.importQty}>
        <CngTextField
          name='importQty'
          label={translatedTextsObject.importQty + ' *'}
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.miq}>
        <CngTextField
          name='miq'
          label={translatedTextsObject.miq + ' *'}
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.name}>
        <CngTextField
          name='name'
          label={translatedTextsObject.name + ' *'}
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.packingPlantAvail}>
        <CngTextField
          name='packingPlantAvail'
          label={translatedTextsObject.packingPlantAvail + ' *'}
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.psq}>
        <CngTextField
          name='psq'
          label={translatedTextsObject.psq + ' *'}
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.riceType}>
        <CngTextField
          name='riceType'
          label={translatedTextsObject.riceType}
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.type}>
        <CngTextField
          name='type'
          label={translatedTextsObject.type + ' *'}
          disabled={disabled}
        />
      </CngGridItem>
    </Grid>
  )
}

function toClientDataFormat(serverData) {
  let localData = serverData
  return localData
}

function toServerDataFormat(localData) {
  return localData
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
