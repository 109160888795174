import ReportSchedulerApiUrls from 'src/apiUrls/ReportSchedulerApiUrls'
import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React, {useState} from 'react'
import { components,useServices, DateTimeFormatter, Yup } from 'cng-web-lib'
import pathMap from 'src/paths/PathMap_Report'


/**
 * Add Page
 */
const {
  form: { CngAddForm, CngPrimaryButton }
} = components

function ReportSchedulerAdd({ history, showNotification }) {

    const [formInitialValues] = useState({
        id: 0,
        title: "",
        partyId: 0,
        reportId: 0,
        scheduleName: "",
        frequency: "",
        scheduledStartDate: "",
        scheduledEndDate: "",
        dynamicParameterList: [],
        userPartyReportList: [],
        reportStartDate: "",
        reportEndDate: "",
        scheduledStartTime: "23",
        timezone: "8",
        blankReportGenerate: false
    });

    const [validations] = useState(
        {
            reportId: Yup.string().required("Required"),
            partyId: Yup.string().required("Party id required"),
            scheduleName: Yup.string().required("Required"),
            frequency: Yup.string().required("Required"),
            scheduledStartDate: Yup.date().required("Required"),
            scheduledEndDate: Yup.date().nullable(),
            scheduledStartTime: Yup.string().required("Required"),
            timezone: Yup.string().required("Required"),
            blankReportGenerate: Yup.boolean().required("Required")
        }
    );

    function makeValidationSchemaUpdated() {
        return Yup.object(validations);
    }


  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngAddForm
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          history={history}
          showNotification={showNotification}
          bodySection={
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
            />
          }
                    formikProps={{
                        validationSchema: makeValidationSchemaUpdated,
                        initialValues: formInitialValues
                    }}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          create={{
            url: ReportSchedulerApiUrls.POST_CUSTOMER,
            successRedirect: pathMap.REPORT_SCHEDULER_CUSTOMER_LIST_VIEW
          }}
        />
      </Grid>
    </Grid>
  )
}


export default ReportSchedulerAdd;