import React from 'react'
import { InputAdornment } from '@material-ui/core'
import { components } from 'cng-web-lib'

// Moment library
import Moment from 'moment';
import 'moment-timezone';

const {
    form: { field: { CngTextField } }
} = components

//* ----------------------------------------------------------------------------
//* Private functions, to reduce the stupid "Cognitive Complexity" Sonar issues.
//* ----------------------------------------------------------------------------
const PORT_DATETIME_FORMAT = 'D MMM yyyy HH:mm'

function _formatDate(timestamp, timezone) {
    let moment = Moment(timestamp)

    if (timezone) {
        moment.tz(timezone)
    }

    let str = moment.format(PORT_DATETIME_FORMAT)
    return (str)
}

//* ----------------------------------------------------------------------------
// Workaround, Hardcoded flag images.
// CNG Ticket: #193946
// Reference: https://crimsonlogic.visualstudio.com/Gets-Next%20Generation%20Calista/_workitems/edit/193946/
function _countryFlagSrc(countryCode) {
    return `${process.env.PUBLIC_URL}/static/images/countries/${countryCode}.svg`
}

//* ----------------------------------------------------------------------------
//* Main Fields function 
//* ----------------------------------------------------------------------------
function PingMyMilestone_BookingPortDisplayField({
    name,
    label,

    // One of these 2 must have data.
    polData = false,
    podData = false
}) {

    let countryCode = '';
    let valueStr = '-';

    if (polData) {
        countryCode = polData.countryCode
        valueStr = `${polData.portCode} - ETD: ${_formatDate(polData.EtdTimestamp, polData.portTimezone)}`
    } else if (podData) {

        console.log({
            "podData": podData
        })
        countryCode = podData.countryCode
        valueStr = `${podData.portCode} - ETA: ${_formatDate(podData.etaTimestamp, podData.portTimezone)}`
    }

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <CngTextField type='text'
            name={name}
            label={label}
            disabled

            InputProps={{
                startAdornment: (
                    <InputAdornment style={{ alignItems: 'baseline', marginTop: '0' }}>
                        {
                            (countryCode) ? // show image only when has country data
                                (
                                    <img
                                        src={_countryFlagSrc(countryCode)}
                                        style={{
                                            width: '1em', height: '1em',
                                            objectFit: 'contain',
                                            marginRight: '0.5em'
                                        }}
                                    />
                                )
                                : '' // else show nothing
                        }
                    </InputAdornment>
                )
            }}

            value={valueStr}
        />
        //* End JSX ------------------------------------------------------------

    )
    //* End of function --------------------------------------------------------
}

export default PingMyMilestone_BookingPortDisplayField