import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useServices } from 'cng-web-lib'

import { PingSearchType } from '../PingSearch_Constants'
import PingSearchBase from './PingSearch_Base'
import ContainerSearchLeftPanel from '../LeftPanel/ContainerSearchLeftPanel'
import ContainerMilestoneList from '../RightPanel/ContainerMilestoneList'
import PingSearchApiUrls from 'src/apiUrls/ping/PingSearchApiUrls'

function PingSearch_ContainerSearch(props) {

	const { containerNum } = useParams()
	const { securedSendRequest } = useServices()

	// On page load, if the page load with a container search, add the initial search into search history as well.
	function addContainerSearchHistory(searchValue) {
		console.debug("add history [" + searchValue + "]")

		let url = PingSearchApiUrls.ADD_SEARCH_HISTORY
		let data = { searchType: PingSearchType.CONTAINER_NUM, searchValue: searchValue }

		securedSendRequest.execute('POST', url, data,
			(response) => {
				// success
				console.debug(response)
			},
			(error) => {
				// error
				console.error(error)
			},
			() => {
				// complete
			}
		)
	}

	useEffect(() => {
		if (containerNum) {
			addContainerSearchHistory(containerNum)
		}
	}, [containerNum]);

	// Temp state var so that even when the value is not changed, it will still trigger a refresh.
	const [searchTime, setSearchTime] = useState()

	const searchButtonClickHandler = ({ searchType, searchValue }) => {
		addContainerSearchHistory(searchValue)
		setSearchTime(new Date())
	}

	//* return JSX -------------------------------------------------------------
	return (

		//* Start JSX ----------------------------------------------------------
		<PingSearchBase
			pageName='ping_container_search'
			onSearchButtonClick={searchButtonClickHandler}
			leftPanelComponent={<ContainerSearchLeftPanel searchTime={searchTime} />}
			rightPanelComponent={<ContainerMilestoneList searchTime={searchTime} />}
		/>

		//* End JSX ------------------------------------------------------------
	);

	//* End of function --------------------------------------------------------
}

export default PingSearch_ContainerSearch