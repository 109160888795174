import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )

  return Yup.object({
    reportName: Yup.string().required(requiredMessage),
    description: Yup.string().required(requiredMessage),
    sheetName: Yup.string().required(requiredMessage),
    query: Yup.string().required(requiredMessage),
    seqNo: Yup.number(),
    type: Yup.string(),
    accessLevelMasterCode: Yup.string().required(requiredMessage),
    dateRangeMaxLimit: Yup.number().min(0).required(requiredMessage).typeError(requiredMessage),
    resultMaxLimit: Yup.number().min(0).required(requiredMessage).typeError(requiredMessage),
    fileDownloadOption: Yup.string().required(requiredMessage),
  })
}

export default makeValidationSchema
