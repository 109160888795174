import React, { useState, useEffect } from 'react'
import { Grid, Container } from '@material-ui/core'

// FontAwesome imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { far as FontAwesomeRegular } from '@fortawesome/pro-regular-svg-icons'
import { fas as FontAwesomeSolid } from '@fortawesome/pro-solid-svg-icons'
import { library as FontAwesomeLibrary } from '@fortawesome/fontawesome-svg-core'

// Moment library
import Moment from 'moment';
import 'moment-timezone';

FontAwesomeLibrary.add(FontAwesomeRegular)
FontAwesomeLibrary.add(FontAwesomeSolid)

function VesselMilestoneRow({ milestone, onChange, collapseExpandAll }) {

    const [isCollapsed, setCollapsed] = useState(true)

    // format the event date and time
    let moment = Moment(milestone.eventDate)

    if (milestone.eventTimezone) {
        moment.tz(milestone.eventTimezone)
    }

    const eventDateStr = moment.format('D MMM yyyy')
    const eventTimeStr = moment.format('HH:mm ([GMT] Z)')

    const handleExpandButtonClick = (event) => {
        let prevState = isCollapsed
        let targetState = !isCollapsed

        setCollapsed(targetState)

        // trigger the parent's onChange handler, if applicable.
        if (onChange) {
            onChange(prevState, targetState)
        }
    }

    // Listen on the Collapse/Expand-All, and overwrite the current row's collapse/expand state if needed.
    useEffect(() => {
        if (collapseExpandAll != null) {
            // true = expand. false = collapse.
            setCollapsed(!collapseExpandAll)
        }
    }, [collapseExpandAll])

    //* return JSX -------------------------------------------------------------
    let eventDetails = milestone.eventDetails
    let hasEventDetails = (eventDetails && Object.entries(eventDetails).length > 0)

    return (
        //* Start JSX ----------------------------------------------------------

        <Grid className="milestone_row container_milestone_row" data-is_collapsed={isCollapsed} container>
            <Grid className="milestone_date" item xs={3} container>
                <label>
                    <span className="date">{eventDateStr}</span>
                    <span className="time">{eventTimeStr}</span>
                </label>
                <div className="line">
                    <div className="dot"></div>
                </div>
            </Grid>
            <Grid className="milestone_event" item xs={9} container>
                <Container>
                    <Grid className="milestone_event_header container_milestone_event_header" container alignItems="center">
                        <Grid className="event_locode_box" xs={11}>
                            <Grid className="event_name" item >
                                <span>{milestone.eventName}</span>
                            </Grid>

                            <Grid className="event_locode" item>
                                <div>
                                    <img className="flFag" src={`${process.env.PUBLIC_URL}/static/images/countries/${milestone.eventCountryCode}.svg`} />
                                    <span>{milestone.eventLocationName}</span>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid className="arrow" item xs={1}>
                            {
                                (hasEventDetails) && // show only when has event details

                                <div onClick={handleExpandButtonClick}>
                                    <FontAwesomeIcon icon={["far", "chevron-down"]} />
                                </div>
                            }
                        </Grid>
                    </Grid>
                    {
                        (hasEventDetails) && // show only when has event details

                        <Grid className="milestone_event_detail_wrapper container_milestone_event_detail_wrapper">
                            <Grid className="milestone_event_detail_header container_milestone_event_detail_header" container alignItems="center">
                                <Grid item xs={6}><label>Voyage Num</label></Grid>
                            </Grid>
                            <Grid className="milestone_event_detail_row container_milestone_event_detail_row" container>
                                <Grid container alignItems="center">
                                    <Grid item xs={6}>
                                        <span>{milestone.eventDetails.voyageNum || ""}</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    }

                </Container>
            </Grid>
        </Grid>

        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default VesselMilestoneRow
