import React from 'react'
import { useHistory } from 'react-router-dom'
import { Grid } from '@material-ui/core'

import PathMap from 'src/paths/PathMap_Ping'

// FontAwesome imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { far as FontAwesomeRegular } from '@fortawesome/pro-regular-svg-icons'
import { library as FontAwesomeLibrary } from '@fortawesome/fontawesome-svg-core'

// Moment library
import Moment from 'moment';

FontAwesomeLibrary.add(FontAwesomeRegular)

function BookingRow({ booking }) {
    const history = useHistory()

    // format the country flag data
    function formatSvgData(dataStr) {
        let formatted = 'data:image/svg+xml;utf8,' + dataStr
        return (formatted)
    }

    // format the favourite datetime
    function formatDateTimeStr(timestamp) {
        if (timestamp) {
            let moment = Moment(timestamp) // default browser timezone
            let datetimestr = moment.format('D MMM yyyy - HH:mm')

            return (datetimestr)
        }

        return ('-')
    }

    const handleRowClick = (event) => {
        history.push(`${PathMap.PING_SEARCH}/booking-ref/` + booking.freightBookingId)
    }

    //* return JSX -------------------------------------------------------------
    return (
        //* Start JSX ----------------------------------------------------------

        <Grid className="search_list_row booking_row" container
            onClick={handleRowClick}
        >
            <Grid item xs={4} container>
                <Grid className="search_type" item xs={3} container alignItems="center">
                    <span>BKRF</span>
                </Grid>
                <Grid item xs={9}>
                    <div className="field carrier_ref">
                        <label>Reference number</label>
                        <span>{booking.carrierRef || '-'}</span>
                    </div>
                    <div className="field">
                        <label>Freight booking id</label>
                        <span>{booking.freightBookingId}</span>
                    </div>
                </Grid>
            </Grid>
            <Grid className="pol" item xs={3}>
                <div className="field">
                    <label>POL</label>
                    <span className="country">
                        <img className="flag" src={`${process.env.PUBLIC_URL}/static/images/countries/${booking.polCountryCode}.svg`} />
                        <span className="portname">{booking.polName}</span>
                        <span className="portcode">{booking.polCode}</span>
                    </span>
                </div>
                <div className="field">
                    <label>&nbsp;</label>
                    <span>
                        <span className="on">on &nbsp;</span>
                        <span>{formatDateTimeStr(booking.polEtdTimestamp)}</span>
                    </span>
                </div>
            </Grid>
            <Grid className="pod" item xs={3}>
                <FontAwesomeIcon className="arrow" icon={["far", "arrow-right"]} />
                <div className="field">
                    <label>POD</label>
                    <span className="country">
                        <img className="flag" src={`${process.env.PUBLIC_URL}/static/images/countries/${booking.podCountryCode}.svg`} />
                        <span className="portname">{booking.podName}</span>
                        <span className="portcode">{booking.podCode}</span>
                    </span>
                </div>
                <div className="field">
                    <label>&nbsp;</label>
                    <span>
                        <span className="on">on &nbsp;</span>
                        <span>{formatDateTimeStr(booking.podEtaTimestamp)}</span>
                    </span>
                </div>
            </Grid>
            <Grid className="created_date" item xs={2}>
                <div className="field">
                    <label>Created on</label>
                    <span>{formatDateTimeStr(booking.createdDate)}</span>
                </div>
            </Grid>
        </Grid>

        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default BookingRow