import React, {useEffect, useState} from 'react'
import Namespace from 'src/constants/locale/Namespace'
import ReportManagementKeys from "src/constants/locale/key/ReportManagementKeys";
import {Card, CardContent, Grid, InputAdornment, Tooltip} from '@material-ui/core'
import {
    components,
    DataFlattener,
    useServices,
    constants,
    useTranslation
} from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import ReportParameterFormProperties from "./ReportParameterFormProperties";
import CodeMaintainanceApiUrls from "../../../apiUrls/CodeMaintainanceApiUrls";
import {InfoOutlined} from "@material-ui/icons";

const {
    card: {
        CngSimpleCardHeader,
    },
    form: {
        adapter: {
            useFormAdapter:{ useField, useFormikContext }
        },
        field: {
            CngTextField,
            CngSelectField
        },
    },
    table: {
        CngLocalModeDialogFormTable,
        useFetchCodeMaintenanceLookup
    },
    CngGridItem,
} = components

const {
    CodeMaintenanceType,
    EQUAL
} = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
    id: 0,
    reportName: "",
    description: "",
    sheetName: "",
    query: "",
    selectCriteria: "",
    seqNo: 0,
    type: "",
    accessLevelMasterCode: "",
    dateRangeMaxLimit: "",
    resultMaxLimit: "",
    fileDownloadOption: "",
    reportParameterList: []
})

const FORMIK_PROPS = {
    initialValues: {...DEFAULT_INITIAL_VALUES},
    makeValidationSchema: makeValidationSchema
}

function Fields({disabled, showNotification, shouldHideMap}) {

    const {translate} = useTranslation(Namespace.REPORT_MANAGEMENT);
    const translatedTextsObject = makeTranslatedTextsObject();
    const [reportId] = useField('id');
    const { fetchRecords } = useServices();
    const [reportTypeOptions, setReportTypeOptions] = useState([]);
    const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup();

    useEffect(()=> {

        const onSuccess = (response) => {
            let options = response.content.map((record) => ({
                text:record['descriptionEn'],
                value: record["code"]
            }));
            setReportTypeOptions(options);
        }

        const onError = (error) => { console.log("Error:[" + JSON.stringify(error) + "]") }

        const onComplete = () => {console.log("On Complete")}

        fetchRecords.execute(
            CodeMaintainanceApiUrls.MAINTAINANCE_CODE,
            {
                customData: { codeMType: CodeMaintenanceType.CODE_MASTER },
                filters: [
                    {
                        field: 'codeType',
                        operator: 'EQUAL',
                        value: 'REPORT_CATEGORY'
                    }
                ]
            },
            onSuccess,
            onError,
            onComplete
        )

    }, []);

    function makeTranslatedTextsObject() {

        let id = translate(
            Namespace.REPORT_MANAGEMENT,
            ReportManagementKeys.REPORT_ID
        )
        let title = translate(
            Namespace.REPORT_MANAGEMENT,
            ReportManagementKeys.TITLE
        )
        let reportParamTitle = translate(
            Namespace.REPORT_MANAGEMENT,
            ReportManagementKeys.ReportParameterList.TITLE
        )
        let reportName = translate(
            Namespace.REPORT_MANAGEMENT,
            ReportManagementKeys.REPORT_NAME
        )
        let description = translate(
            Namespace.REPORT_MANAGEMENT,
            ReportManagementKeys.DESCRIPTION
        )
        let sheetName = translate(
            Namespace.REPORT_MANAGEMENT,
            ReportManagementKeys.SHEET_NAME
        )
        let query = translate(
            Namespace.REPORT_MANAGEMENT,
            ReportManagementKeys.QUERY
        )
        let selectCriteria = translate(
            Namespace.REPORT_MANAGEMENT,
            ReportManagementKeys.SELECT_CRITERIA
        )
        let seqNo = translate(
            Namespace.REPORT_MANAGEMENT,
            ReportManagementKeys.SEQNO
        )
        let type = translate(
            Namespace.REPORT_MANAGEMENT,
            ReportManagementKeys.TYPE
        )
        let accessLevelMasterCode = translate(
            Namespace.REPORT_MANAGEMENT,
            ReportManagementKeys.ACCESS_LEVEL_MASTER_CODE
        )
        let paramName = translate(
            Namespace.REPORT_MANAGEMENT,
            ReportManagementKeys.ReportParameterList.PARAMETER_NAME
        )
        let paramDisplayName = translate(
            Namespace.REPORT_MANAGEMENT,
            ReportManagementKeys.ReportParameterList.PARAMETER_DISPLAY_NAME_EN
        )
        let paramValue = translate(
            Namespace.REPORT_MANAGEMENT,
            ReportManagementKeys.ReportParameterList.PARAMETER_VALUE
        )
        let fieldType = translate(
            Namespace.REPORT_MANAGEMENT,
            ReportManagementKeys.ReportParameterList.FIELD_TYPE
        )

        let dateRangeMaxLimit = translate(
            Namespace.REPORT_MANAGEMENT,
            ReportManagementKeys.DATE_RANGE_MAX_LIMIT
        )

        let resultMaxLimit = translate(
            Namespace.REPORT_MANAGEMENT,
            ReportManagementKeys.RESULT_MAX_LIMIT
        )

        let fileDownloadOption = translate(
            Namespace.REPORT_MANAGEMENT,
            ReportManagementKeys.FILE_DOWNLOAD_OPTION
        )

        return {
            id,
            reportName,
            description,
            sheetName,
            query,
            selectCriteria,
            seqNo,
            type,
            accessLevelMasterCode,
            reportParamTitle,
            paramName,
            paramDisplayName,
            fieldType,
            paramValue,
            title,
            dateRangeMaxLimit,
            resultMaxLimit,
            fileDownloadOption
        }
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Card>
                    <CngSimpleCardHeader title={translatedTextsObject.title}/>
                    <CardContent>
                        <Grid container spacing={3}>

                            <CngGridItem xs={12} sm={6} shouldHide={true}>
                                <CngTextField
                                    name="id"
                                    label={translatedTextsObject.id}
                                    disabled={disabled}
                                />
                            </CngGridItem>

                            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.reportName}>
                                <CngTextField
                                    name="reportName"
                                    label={translatedTextsObject.reportName + " *"}
                                    disabled={disabled}
                                />
                            </CngGridItem>

                            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.description}>
                                <CngTextField
                                    name="description"
                                    label={translatedTextsObject.description}
                                    disabled={disabled}
                                />
                            </CngGridItem>

                            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.sheetName}>
                                <CngTextField
                                    name="sheetName"
                                    label={translatedTextsObject.sheetName}
                                    disabled={disabled}
                                />
                            </CngGridItem>

                            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.query}>
                                <CngTextField
                                    name="query"
                                    label={translatedTextsObject.query}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip arrow title={<h6>The query must be a valid SQL in the format 'FROM [table owner].[table name]'. if you have 'where' clause or 'group by', you can append them also</h6>}>
                                                    <InfoOutlined fontSize={"small"} color={"secondary"}/>
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }}
                                    disabled={disabled}
                                />
                            </CngGridItem>

                            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.selectCriteria}>
                                <CngTextField
                                    name="selectCriteria"
                                    label={translatedTextsObject.selectCriteria}
                                    disabled={disabled}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip arrow title={<h6>If left empty, will 'select *'. Else comma separated fields accepted</h6>}>
                                                    <InfoOutlined fontSize={"small"} color={"secondary"}/>
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </CngGridItem>

                            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.type}>
                                <CngSelectField
                                    name="type"
                                    label={translatedTextsObject.type}
                                    disabled={disabled}
                                    items={reportTypeOptions}
                                />
                            </CngGridItem>

                            <CngGridItem xs={12} sm={6} shouldHide={false}>
                                <CngSelectField
                                    name="accessLevelMasterCode"
                                    label={translatedTextsObject.accessLevelMasterCode}
                                    disabled={false}
                                    fetch={
                                        {
                                            url: `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/account-setup/access-level-master/hdr/get`,
                                            textAccessor: "name",
                                            valueAccessor: "code"
                                        }
                                    }
                                />
                            </CngGridItem>

                            <CngGridItem xs={12} sm={6} shouldHide={false}>
                                <CngTextField
                                    name="dateRangeMaxLimit"
                                    label={translatedTextsObject.dateRangeMaxLimit}
                                    disabled={disabled}
                                    type="number"
                                    inputProps={{ min: 0 }}
                                />
                            </CngGridItem>

                            <CngGridItem xs={12} sm={6} shouldHide={false}>
                                <CngTextField
                                    name="resultMaxLimit"
                                    label={translatedTextsObject.resultMaxLimit}
                                    disabled={disabled}
                                    type="number"
                                    inputProps={{ min: 0 }}
                                />
                            </CngGridItem>

                            <CngGridItem xs={12} sm={6} shouldHide={false}>
                                <CngSelectField
                                    name="fileDownloadOption"
                                    label={translatedTextsObject.fileDownloadOption}
                                    items={[
                                        { text: 'S3 Link', value: 'S3' },
                                        { text: 'Attachment', value: 'ATTACHMENT' }
                                      ]}
                                />
                            </CngGridItem>

                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <CngSimpleCardHeader title={translatedTextsObject.reportParamTitle} />
                    <CardContent>
                        <CngLocalModeDialogFormTable
                            fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
                            disabled={disabled}
                            showNotification={showNotification}
                            propertyName="reportParameterList"
                            tableColumns={[
                                {
                                    title: translatedTextsObject.paramName,
                                    field: "parameterName"
                                },
                                {
                                    title: translatedTextsObject.paramDisplayName,
                                    field: "parameterDisplayNameEn"
                                },
                                {
                                    title: translatedTextsObject.fieldType,
                                    field: "fieldType",
                                    customLookup: () => {
                                        return fetchCodeMaintenanceLookup(
                                            constants.CodeMaintenanceType.CODE_MASTER,
                                            (error) => console.error(error),
                                            [{ field: 'codeType', operator: EQUAL, value: 'DYNAMIC_FIELD_TYPE' }]
                                        )
                                    }
                                },
                            ]}
                            formProperties={ {...ReportParameterFormProperties, reportId: reportId} }
                            initialData={DEFAULT_INITIAL_VALUES.reportParameterList}
                            isViewOnly={disabled}
                            // idGenerated
                        />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

function toClientDataFormat(serverData) {
    return DataFlattener.parse(serverData);
}

function toServerDataFormat(localData) {
    return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    Fields: Fields,
    toClientDataFormat: toClientDataFormat,
    toServerDataFormat: toServerDataFormat
})

export default FormProperties
