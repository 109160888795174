import React, { useState } from 'react'
import { Card, CardContent, Grid } from '@material-ui/core'

import { useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import PingKeys from 'src/constants/locale/key/Ping'

import MyMilestoneUploadListing from './MyMilestoneUploadListing'
import SearchPanel from '../components/filter-panel/SearchPanel'
import FilterPanel from '../components/filter-panel/FilterPanel'
import DateFilter from '../components/filter-panel/DateFilter'

function UploadListPage({ showNotification, ...props }) {

    // Translations ------------------------------------------------------------
    const { translate } = useTranslation(Namespace.PING)

    const PingMyMilestoneKeys = PingKeys.PING_MY_MILESTONES

    const Labels = {
        UPLOADED_DATE: translate(Namespace.PING, PingKeys.FIELDS.UPLOADED_DATE),
        UPLOAD_LIST_SEARCH_PLACEHOLDER: translate(Namespace.PING, PingMyMilestoneKeys.UPLOAD_LIST_SEARCH_PLACEHOLDER)
    }

    //* ------------------------------------------------------------------------
    const [searchText, setSearchText] = useState('')

    const [clearAllFilterClickedTime, setClearAllFilterClickedTime] = useState(new Date())
    const [dateFilter, setDateFilter] = useState('ALL')

    const [filterData, setFilterData] = useState({
        dateFilter: 'ALL'
    })

    const applyFilterClickHandler = (event) => {
        let newFilterData = {
            dateFilter: dateFilter
        }

        setFilterData(newFilterData)
    }

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <Grid container spacing={3}>
            <Grid item xs={9}>
                <Card>
                    <CardContent>
                        <MyMilestoneUploadListing
                            searchText={searchText}
                            filterData={filterData}
                            showNotification={showNotification}
                        />
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={3} container spacing={3} alignContent='flex-start'>
                <Grid item xs={12}>
                    <SearchPanel
                        placeholderText={Labels.UPLOAD_LIST_SEARCH_PLACEHOLDER}
                        onSearch={(searchValue) => {
                            setSearchText(searchValue)
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <FilterPanel
                        onClearAllClicked={(event) => {
                            setClearAllFilterClickedTime(new Date())
                        }}
                        onApplyClicked={applyFilterClickHandler}
                    >
                        <DateFilter
                            title={Labels.UPLOADED_DATE}
                            clearAllFilterClickedTime={clearAllFilterClickedTime}
                            onChange={(value) => {
                                setDateFilter(value)
                            }}
                        />
                    </FilterPanel>
                </Grid>
            </Grid>
        </Grid>
        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default UploadListPage
