import { Card, CardContent, Grid } from '@material-ui/core'
import { components, constants, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import React from 'react'
import RiceStockApiUrls from 'src/apiUrls/RiceStockApiUrls'
import RiceStockKeys from 'src/constants/locale/key/RiceStock'

const {
  locale: {
    key: { UiComponentKeys }
  }
} = constants

const {
  card: { CngSimpleCardHeader },
  table: { CngCrudTable, useDefaultNotification }
} = components

function TablePage(props) {
  const {
    location: { pathname },
    showNotification
  } = props

  const notification = useDefaultNotification(showNotification)
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.RICE_STOCK
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let importerTitle = translate(
      Namespace.RICE_STOCK,
      RiceStockKeys.IMPORTER.TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: importerTitle
      }
    )
    let criteriaQty = translate(
      Namespace.RICE_STOCK,
      RiceStockKeys.IMPORTER.CRITERIA_QTY
    )
    let importQty = translate(
      Namespace.RICE_STOCK,
      RiceStockKeys.IMPORTER.IMPORT_QTY
    )
    let importerId = translate(
      Namespace.RICE_STOCK,
      RiceStockKeys.IMPORTER.IMPORTER_ID
    )
    let miq = translate(Namespace.RICE_STOCK, RiceStockKeys.IMPORTER.MIQ)
    let name = translate(Namespace.RICE_STOCK, RiceStockKeys.IMPORTER.NAME)
    let packingPlantAvail = translate(
      Namespace.RICE_STOCK,
      RiceStockKeys.IMPORTER.PACKING_PLANT_AVAIL
    )
    let psq = translate(Namespace.RICE_STOCK, RiceStockKeys.IMPORTER.PSQ)
    let riceType = translate(
      Namespace.RICE_STOCK,
      RiceStockKeys.IMPORTER.RICE_TYPE
    )
    let type = translate(Namespace.RICE_STOCK, RiceStockKeys.IMPORTER.TYPE)

    return {
      tableTitle,
      criteriaQty,
      importQty,
      importerId,
      miq,
      name,
      packingPlantAvail,
      psq,
      riceType,
      type
    }
  }

  const columns = [
    {
      field: 'importerId',
      title: translatedTextsObject.importerId
    },
    {
      field: 'name',
      title: translatedTextsObject.name
    },
    {
      field: 'criteriaQty',
      title: translatedTextsObject.criteriaQty
    },
    {
      field: 'miq',
      title: translatedTextsObject.miq
    },
    {
      field: 'packingPlantAvail',
      title: translatedTextsObject.packingPlantAvail
    },
    {
      field: 'psq',
      title: translatedTextsObject.psq
    },
    {
      field: 'riceType',
      title: translatedTextsObject.riceType
    },
    {
      field: 'type',
      title: translatedTextsObject.type
    }
  ]

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
              addRoute={`${pathname}/add`}
              columns={columns}
              del={{ url: RiceStockApiUrls.IMPORTER_DELETE }}
              editRoute={`${pathname}/edit`}
              exportData={{ url: RiceStockApiUrls.IMPORTER_EXPORT }}
              fetch={{ url: RiceStockApiUrls.IMPORTER_GET }}
              idAccessor='id'
              notification={notification}
              //tableConfigurationCode='REPLACE_WITH_CORE_TABLE_CONFIG_CODE'
              viewRoute={`${pathname}/view`}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default TablePage
