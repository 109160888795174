import React from 'react'
import { Card, CardContent, Grid } from '@material-ui/core'

import { components } from 'cng-web-lib'
import FormProperties from './PingMyMilestone_FormProperties'
import PingMyMilestoneApiUrls from 'src/apiUrls/ping/PingMyMilestoneApiUrls'
import PingPathMap from 'src/paths/PathMap_Ping'
import DiscardButton from 'src/components/button/DiscardButton'
import GreenSubmitButton from 'src/components/button/GreenSubmitButton'

const { form: { CngAddForm } } = components

function AddPage({ history, showNotification }) {

    const postSuccessHandler = (response) => {

        // The response will be the myMilestoneId.
        let myMilestoneId = response
        let redirectUrl = `${PingPathMap.PING_MY_MILESTONES}/add-success/${myMilestoneId}`
        history.push(redirectUrl)
    }

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <Card>
            <CardContent>
                <CngAddForm
                    fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                    history={history}
                    showNotification={showNotification}
                    bodySection={
                        <FormProperties.Fields
                            showNotification={showNotification}
                        />
                    }
                    formikProps={FormProperties.formikProps}
                    toClientDataFormat={FormProperties.toClientDataFormat}
                    toServerDataFormat={FormProperties.toServerDataFormat}
                    create={{
                        url: PingMyMilestoneApiUrls.CREATE,
                        onPostSubmitSuccess: postSuccessHandler
                    }}

                    //* The customized buttons...
                    renderButtonSection={() => {
                        return (
                            <Grid container>
                                <Grid item xs={6}>
                                    <DiscardButton
                                        style={{
                                            paddingLeft: '2em',
                                            paddingRight: '2em'
                                        }}
                                        onClick={(event) => {
                                            history.push(PingPathMap.PING_MY_MILESTONES_LIST_VIEW)
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: 'right' }}>
                                    <GreenSubmitButton style={{
                                        paddingLeft: '2em',
                                        paddingRight: '2em',
                                    }} />
                                </Grid>
                            </Grid>
                        )
                    }}
                />
            </CardContent>
        </Card>

        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default AddPage