import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Grid, Container, Tooltip } from '@material-ui/core'
import { useServices } from 'cng-web-lib'

import PingSearchApiUrls from 'src/apiUrls/ping/PingSearchApiUrls'

// Font Awesome imports.
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { far as FontAwesomeRegular } from '@fortawesome/pro-regular-svg-icons'
import { fal as FontAwesomeLight } from '@fortawesome/pro-light-svg-icons'
import { library as FontAwesomeLibrary } from '@fortawesome/fontawesome-svg-core'
import Utils from 'src/views/common/utils/Utils'
// Moment libraries
import Moment from 'moment';
import 'moment-timezone';

// Ping components
import { PingSearchType } from '../PingSearch_Constants'
import LeftPanelLoading from './LeftPanelLoading'
import LeftPanelError from './LeftPanelError'
import LeftPanelNoBookingData from './LeftPanelNoBookingData'
import CopyToClipboardIcon from './CopyToClipboardIcon'

FontAwesomeLibrary.add(FontAwesomeRegular)
FontAwesomeLibrary.add(FontAwesomeLight)

function BookingSearchLeftPanel({ searchTime }) {

    const { bookingRef } = useParams()
    const { securedSendRequest } = useServices()

    const [responseState, setResponseState] = useState({
        isLoading: true,
        hasError: false,
        responseData: {}
    })


    const [isFavourite, setIsFavourite] = useState(false)

    useEffect(() => {
        let url = PingSearchApiUrls.BOOKING_INFO_SEARCH + '/' + bookingRef
        let data = {}

        setResponseState({ ...responseState, isLoading: true })
        setIsFavourite(false)

        securedSendRequest.execute('GET', url, data,
            (response) => {
                // success
                console.debug(response)

                setResponseState({
                    isLoading: false,
                    hasError: false,
                    responseData: response.data
                })

                loadIsFavouriteStarState()
            },
            (error) => {
                // error
                console.error(error)

                setResponseState({
                    isLoading: false,
                    hasError: true,
                    responseData: {}
                })
            },
            () => {
                // completed
            }
        )

    }, [bookingRef, searchTime])

    // call ajax to check is the current search an existing favourite
    function loadIsFavouriteStarState() {
        let searchType = PingSearchType.FREIGHT_BOOKING_REF
        let searchValue = bookingRef

        let url = PingSearchApiUrls.EXISTS_SEARCH_FAVOURITE
        let data = { 'searchType': searchType, 'searchValue': searchValue }

        securedSendRequest.execute('POST', url, data,
            (response) => {
                // success
                console.debug(response)

                setIsFavourite(response.data)
            },
            (error) => {
                console.error(error)
            },
            () => {
                // completed
            }
        )
    }

    const handleStarClick = (event) => {
        let searchType = PingSearchType.FREIGHT_BOOKING_REF
        let searchValue = bookingRef

        let url
        let data = { 'searchType': searchType, 'searchValue': searchValue }

        if (isFavourite == false) {
            // Currently is not favourite. Add the favourite
            url = PingSearchApiUrls.ADD_SEARCH_FAVOURITE
            setIsFavourite(true)
        }
        else {
            // Currently is favourite. Delete the favourite.
            url = PingSearchApiUrls.DELETE_SEARCH_FAVOURITE
            setIsFavourite(false)
        }

        securedSendRequest.execute('POST', url, data,
            (response) => {
                // success
                console.debug(response)
            },
            (error) => {
                console.error(error)
            },
            () => {
                // completed
            }
        )
    }

    function PING_STATIC_IMAGE(filename) {
        const PING_STATIC_IMAGE_DIR = `${process.env.PUBLIC_URL}/static/images/ping/`
        return (PING_STATIC_IMAGE_DIR + filename)
    }

    function formatDateTimeStr(timestamp) {
        if(Utils.isEmptyString(timestamp)){
            return ""
        }

        let moment = Moment(timestamp) // hardcode to use browser timezone

        let str = moment.format('D MMM YYYY - HH:mm')
        return (str)
    }

    //* ------------------------------------------------------------------------
    //* Return JSX - Loading...
    //* ------------------------------------------------------------------------
    if (responseState.isLoading == true) {
        return (<LeftPanelLoading />)
    }

    //* ------------------------------------------------------------------------
    //* Return JSX - Error...
    //* ------------------------------------------------------------------------
    if (responseState.hasError == true) {
        return (<LeftPanelError />)
    }

    //* ------------------------------------------------------------------------
    //* Return JSX - No data...
    //* ------------------------------------------------------------------------
    let responseData = responseState.responseData

    let hasData = (responseData && responseData.freightBooking && Object.entries(responseData.freightBooking).length > 0)
    if (!hasData) {
        return (<LeftPanelNoBookingData />)
    }

    //* ------------------------------------------------------------------------
    //* Return JSX - Result
    //* ------------------------------------------------------------------------
    let booking = responseData.freightBooking

    return (
        //* Start JSX ----------------------------------------------------------

        <Container>
            <Grid className="bookmark_bar" item xs={12}>
                <span>Booking ref no: {booking.carrierRef || "-"}</span>
                {
                    isFavourite == false ?
                        (
                            <Tooltip title="Bookmark this search">
                                <div className="star outline_star" onClick={handleStarClick}>
                                    <FontAwesomeIcon icon={["far", "star"]} />
                                </div>
                            </Tooltip>
                        )
                        :
                        (
                            <Tooltip title="Unbookmark this search">
                                <div className="star solid_star" onClick={handleStarClick}>
                                    <FontAwesomeIcon icon={["fas", "star"]} />
                                </div>
                            </Tooltip>
                        )
                }
            </Grid>

            <Grid className="info_header" item xs={12}>
                <img className="company_logo" src={booking.carrierCompanyLogoData} />
                <h2>{booking.carrierName}</h2>
            </Grid>

            <Grid className="port_info" item xs={12}>
                <Grid className="pol" container>
                    <Grid className="icon" item xs={2}>
                        <div className="icon_border">
                            <img src={PING_STATIC_IMAGE("icon-port.svg")} />
                        </div>
                        <div className="line"></div>
                    </Grid>
                    <Grid className="detail" item xs={10} container>
                        <Grid item xs={12}>
                            <label className="port">Port of loading</label>
                            <label className="on">on</label>
                            <span className="port_datetime">{formatDateTimeStr(booking.polEtdTimestamp)}</span>
                        </Grid>
                        <Grid item xs={12} container alignItems="center">
                            <img className="country_flag" src={`${process.env.PUBLIC_URL}/static/images/countries/${booking.polCountryCode}.svg`} />
                            <label className="port_name">{booking.polName}</label>
                            <span className="port_code">{booking.polCode}</span>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className="pod" container>
                    <Grid className="icon" item xs={2}>
                        <div className="icon_border">
                            <img src={PING_STATIC_IMAGE("icon-port.svg")} />
                        </div>
                    </Grid>
                    <Grid className="detail" item xs={10} container>
                        <Grid item xs={12}>
                            <label className="port">Port of discharge</label>
                            <label className="on">on</label>
                            <span className="port_datetime">{formatDateTimeStr(booking.podEtaTimestamp)}</span>
                        </Grid>
                        <Grid item xs={12} container alignItems="center">
                            <img className="country_flag" src={`${process.env.PUBLIC_URL}/static/images/countries/${booking.podCountryCode}.svg`} />
                            <label className="port_name">{booking.podName}</label>
                            <span className="port_code">{booking.podCode}</span>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid className="booking_info" item xs={12} container>
                <Grid className="info_row" item xs={12}>
                    <label>Freight booking Id</label>
                    <span>{booking.freightBookingId}</span>
                </Grid>
                <Grid className="info_row" item xs={12}>
                    <label>BL number</label>
                    {
                        booking.blNums.map((blNum, index) => {
                            let key = "blNumRow_" + index;
                            return (<span key={key}>{blNum}</span>)
                        })
                    }
                </Grid>
                <Grid className="info_row" item xs={12}>
                    <label>Cargo description</label>
                    <ul>
                        {
                            booking.cargoDescriptions.map((cargoDesc, index) => {
                                let key = "cargoDescRow_" + index
                                return (<li key={key}>{cargoDesc}</li>)
                            })
                        }
                    </ul>
                </Grid>
                <Grid className="info_group" item xs={12} container>
                    <Grid className="info_row equipment_details" item xs={12}>
                        <label>Equipment details</label>
                        <ul>
                            {
                                booking.equipmentDescriptions.map((eqDesc, index) => {
                                    let key = eqDesc.isoCode

                                    return (
                                        <li key={key}>
                                            <span className="code">{eqDesc.count}x {eqDesc.isoCode}</span>
                                            <span className="name">{eqDesc.isoType}</span>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </Grid>
                    <Grid className="info_row container_details" item xs={12}>
                        <label>Container details</label>
                        <ul>
                            {
                                booking.containerNums.map((containerNum, index) => {
                                    let key = containerNum

                                    return (
                                        <li key={key}>
                                            <FontAwesomeIcon className="container_icon" icon={["far", "container-storage"]} />
                                            <span>{containerNum}</span>
                                            <CopyToClipboardIcon str={containerNum} />
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </Grid>
                </Grid>
            </Grid>
        </Container>

        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default BookingSearchLeftPanel