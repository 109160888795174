import React, { useState, useEffect, } from 'react'
import 'src/assets/scss/_pingSearch.scss'
import { Button, Grid, RadioGroup, FormControlLabel, Radio, TextField, } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import Loading from 'src/views/vesselschedule/searchschedule/Loading'
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import InputComponent from 'src/components/input/input'
import { useServices, constants, useTranslation } from 'cng-web-lib'
import { useForm } from 'react-hook-form'
import CarrierEnrollApiUrls from 'src/apiUrls/CarrierEnrollApiUrls';
import ConfirmDialog from 'src/components/dialog/ConfirmDialog'
import PING_EVENT_SUBSCRIPTION from 'src/apiUrls/ping/PingEventSubscription'
import TabelComponent from '../Tabel'
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import FilterListIcon from '@material-ui/icons/FilterList';
import InputAdornment from '@material-ui/core/InputAdornment';
import Moment from 'moment';
import 'moment-timezone';
import Namespace from 'src/constants/locale/Namespace'
function renderRefNum(dataNum = {}) {
    const keyRender = { bkRf: 'BKRF', blNo: 'BLNO', ctNo: "CNTR" }

    return <div className="ref-num">{Object.keys(keyRender).map((item, index) => {
        return dataNum[item] ? <div className={`chip-no `} ><div className='chip'>{keyRender[item]}</div><div className='chip-text'>{dataNum[item]}</div></div> : null
    })}</div>

}

const PingSearch_EventSubscription = ({ showNotification }) => {
    const { t } = useTranslation(Namespace.PING_EVENT_SUBSCRIPTION)
    const { register, handleSubmit, formState: { errors }, setValue, clearErrors, reset, watch, setError } = useForm();
    const [typeSub, setStypeSub] = useState('OCEAN_INSIGHT')
    const [options, setOptions] = useState([])
    const [values, setValues] = useState({ keySearch: '', filterEvent: '', filterCode: '' });
    const [loadingTabel, setLoadingTable] = useState(true)
    const [loading, setLoading] = useState(true)
    const [showSearch, setShowSearch] = useState(false)
    const [confirmModal, setConfirmModal] = useState({ open: false, content: '', id: '', isSubscribed: false })
    const [page, setPage] = useState({ content: [], number: 0, size: 10, totalElements: 0 })
    const { NotificationType } = constants

    useEffect(() => {
        let listen = watch((defaultValue) => {
            const { bkRf, blNo, ctNo } = defaultValue;
            if (typeSub === 'OCEAN_INSIGHT') {
                clearErrors('ctNo')
                if (bkRf || blNo || ctNo) {
                    clearErrors('enterOne')
                }
            } else {
                if (!ctNo) {
                    setError('ctNo', { message: 'pls' })
                } else clearErrors('ctNo')


            }
            console.log(defaultValue)
        })
        return () => listen.unsubscribe();
    }, [watch])


    const { fetchPageableRecords, fetchRecords } = useServices();
        useEffect(() => {
        fetchPageableRecords.execute(
            PING_EVENT_SUBSCRIPTION.GET_CODE,
            {
                filterType: 'AND',
                filters: [{
                    field:'codeType',
                    value:'carrier'
                }],
                sorts: [],
                page: 0,
                pageSize: 50
            },
                        ({ content }) => {
                setOptions(content.map(elm => {
                                return {
                        code: elm?.code ?? '',
                        description: elm?.descriptionEn ?? ''
                                }
                }))
                            setLoading(false)
                },
                () => {
                    showNotification(NotificationType.ERROR, 'Carrier Code ERROR!')
                    setLoading(false)
                }
            )

        getDataTabel()
    }, [])
    const onChange = (_ev) => {
        const { value } = _ev?.target;
        setStypeSub(value)
        setValue('eventSource', value)
        // eventSource
    }
    const onSubmit = (value) => {
        console.log("onSubmit", value)
        const { bkRf, blNo, ctNo } = value;

        if (typeSub === 'OCEAN_INSIGHT') {
            if (!bkRf && !blNo && !ctNo) {
                setError('enterOne', { message: t('mgs_enter_one') })
            } else {
                sendForm(value)

            }
        } else {
            if (!ctNo) {
                setError('ctNo', { message: 'pls' })
            } else {
                sendForm(value)
            }
        }


    }

    const sendForm = (value) => {
        setLoading(true);
        const requestData = {
            actionDate: new Date().toISOString(),
            "actionType": "SUBSCRIBE",
            eventSource: typeSub,
            ...value,
            carrierCode: typeSub === 'OCEAN_INSIGHT'? value?.carrierCode?.code:'',
            carrierDescription: typeSub === 'OCEAN_INSIGHT'?value?.carrierCode?.description:'',

        }
        console.log(requestData)
        fetchRecords.execute(
            PING_EVENT_SUBSCRIPTION.CREATE,
            { customData: requestData },
            ({ errorMes }) => {
                console.log(errorMes);
                if (errorMes) {
                    showNotification(NotificationType.ERROR, errorMes)

                } else {

                    showNotification(NotificationType.SUCCESS, t('mgs_add_successfully'))

                }

            },
            (error) => { console.log(error) }, () => {
                setLoading(false)
                reset({ carrierCode: value?.carrierCode });
                getDataTabel(page.number, page.size, values?.keySearch)
            }
        )
    }
    const getDataTabel = (pageNum = 0, size = 10, valueSearch = '') => {
        setLoadingTable(true)
        fetchPageableRecords.execute(
            PING_EVENT_SUBSCRIPTION.GET,
            {
                filterType: valueSearch ? "OR" : '',
                filters: [{
                    field: 'carrierCode',
                    value: valueSearch
                },
                {
                    field: 'eventSource',
                    value: valueSearch
                },
                {
                    field: 'bkRf',
                    value: valueSearch
                },
                {
                    field: 'blNo',
                    value: valueSearch
                },
                {
                    field: 'ctNo',
                    value: valueSearch
                }]
                ,
                sorts: [],
                page: pageNum,
                pageSize: size,


            },
            (response) => {
                setPage({ ...response })
                setLoadingTable(false)

            },
        )
    }
    const handleChangePage = (event, newPage) => {
        getDataTabel(newPage, page.size, values.keySearch)
    };

    const handleChangeRowsPerPage = (event) => {
        getDataTabel(0, +event.target.value, values.keySearch)
    };
    const onSearch = (ev) => {
        setValues({ ...values, keySearch: ev.target?.value })
        getDataTabel(page.number, page.size, ev.target?.value.trim())
    }
    const onShowSearch = (show) => () => {
        setShowSearch(show);
    }
    const onDelete = (id, isSubscribed) => {
        setLoadingTable(true)
        fetchRecords.execute(
            isSubscribed ? PING_EVENT_SUBSCRIPTION.UNSUBSCRIBE : PING_EVENT_SUBSCRIPTION.SUBSCRIBE,
            { customData: { id } },
            (ev) => {
                showNotification(NotificationType.SUCCESS, isSubscribed ? t('mgs_unsubcribe') : t('mgs_subcribe'))

            },
            () => { setLoadingTable(false) }, () => {
                setConfirmModal({ ...confirmModal, open: false })

                getDataTabel(page.number, page.size, values.keySearch)
            }
        );
    }
    const getFilterCell = (pageNum = 0, size = 10, filter = {}) => {
        setValues({ ...values, ...filter })
        const { filterEvent, filterCode } = values
        setLoadingTable(true)
        fetchPageableRecords.execute(
            PING_EVENT_SUBSCRIPTION.GET,
            {
                filterType: 'AND',
                filters: [{
                    field: "eventSource",
                    value: filter['filterEvent'] ?? filterEvent,
                    operatorType: "LIKE"
                },
                {
                    field: "carrierDescription",
                    value: filter['filterCode'] ?? filterCode,
                    operatorType: "LIKE"
                }],
                sorts: [],
                page: pageNum,
                pageSize: size,


            },
            (response) => {
                setPage({ ...response })
            }, (error) => { console.log(error) }, () => {
                setLoadingTable(false)
            }
        )

    }
    const onFilterCell = (_ev) => {
        const { name, value } = _ev.target;
        getFilterCell(page.number, page.size, { [name]: value });
    }
    const confirmDialogF = () => {
        const { id, isSubscribed } = confirmModal;
        onDelete(id, isSubscribed)
        setConfirmModal({ ...confirmModal, open: false })
    }
    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <form onSubmit={handleSubmit(onSubmit)} onError={(ev) => { console.log(ev) }}>
            <ConfirmDialog
                isConfirmDialogOpen={confirmModal.open}
                closeDialog={() => { setConfirmModal({ ...confirmModal, open: false }) }}
                confirmDialog={confirmDialogF}
                content={confirmModal.content}
                title={t('label_event')}
                okMsg={"Yes"}
                cancelMsg={"Cancel"}
            />
            <Grid container className="ping-event" spacing={4}>
                <Grid item xs={12} className="event-subscription">
                    <Loading loading={loading} css={false} />
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <div className='title-label'>
                                {t('label_create')}
                            </div >
                        </Grid >
                        <Grid item xs={12} >
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <div className='btn-title'>{t('label_subcribe')}</div>
                                </Grid>
                                <Grid item xs={4}>
                                    <RadioGroup
                                        value={typeSub}
                                        name="eventSource"
                                        onChange={onChange}
                                    >
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <FormControlLabel value="OCEAN_INSIGHT" control={<Radio />} label={t('label_ocean')} />
                                            </Grid >
                                            <Grid item xs={6}>
                                                <FormControlLabel value="PSA" control={<Radio />} label={t('label_psa')} />
                                            </Grid>
                                        </Grid >
                                    </RadioGroup >
                                </Grid >
                            </Grid >
                        </Grid >
                        {typeSub === 'OCEAN_INSIGHT' && <Grid item xs={12}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={3} >
                                                            <div className='btn-title'>
                                                                <div>{t('label_carrier')}</div>
                                                                <div className='label'>{t('label_mada')}</div>
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={4} >
                                                            <Autocomplete
                                                                fullWidth
                                                                id="combo-box-demo"
                                                                loading={!!options}
                                                                options={options}
                                                                {...register('carrierCode', { required: "Please select carrier" })}
                                                                onChange={(ev, value) => {
                                                                    setValue('carrierCode', value)
                                                                    clearErrors('carrierCode')
                                                                }}
                                        getOptionLabel={(option) => option?.description}
                                                                renderOption={(option) => {
                                                                    return <div className='fl fl-sb full-w'>
                                                <div>{option?.description}</div>
                                                <div className='cfc-chip'>{option?.code}</div>

                                                                    </div>
                                                                }}
                                                                renderInput={(params) => {
                                                                    return <TextField
                                                                        className='box-view'
                                                                        error={!!errors['carrierCode']}
                                                                        {...params}
                                                                        helperText={!!errors['carrierCode'] && 'Please select carrier'}
                                                                        label="Carrier"
                                                                        variant="filled"
                                                                        InputProps={{
                                                                            ...params.InputProps,
                                                    endAdornment: <><div className='cfc-chip'>{options.find(item => item.description === params?.inputProps?.value)?.code}</div>{params.InputProps.endAdornment}</>
                                                                        }}
                                                                    />

                                                                }}
                                                            />
                                                        </Grid>

                                                    </Grid>
                        </Grid >}
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={3} >
                                    <div className='btn-title'>
                                        <div>{t('label_details')}</div>
                                        <div className='label'>{t('label_enter')}</div>
                                    </div>
                                </Grid>
                                {
                                    typeSub === 'OCEAN_INSIGHT' ? <Grid item xs={6}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={4} >
                                                <InputComponent error={!!errors['bkRf']} {...register('bkRf')}
                                                    type="text"
                                                    label="BKRF" />

                                            </Grid>
                                            <Grid item xs={4} >
                                                <InputComponent error={!!errors['blNo']} {...register('blNo')}
                                                    type="text"
                                                    label="BLNO" />

                                            </Grid>
                                            <Grid item xs={4} >
                                                <InputComponent error={!!errors['ctNo']} {...register('ctNo')}
                                                    type="text"
                                                    label="CNTR" />

                                            </Grid>
                                            <span className="error MuiFormHelperText-root MuiFormHelperText-contained ml-10">{errors?.enterOne?.message ?? ''}</span>

                                        </Grid>
                                    </Grid> : <Grid item xs={4} >
                                        <InputComponent error={!!errors['ctNo']}
                                            required {...register('ctNo')}
                                            type="text"
                                            label="CNTR" />
                                        <span className="error MuiFormHelperText-root MuiFormHelperText-contained">{!!errors?.ctNo && 'Please input value'}</span>
                                    </Grid>
                                }

                                <Grid item xs={typeSub === 'OCEAN_INSIGHT' ? 3 : 5} className="btn-add">
                                    <Button className="btn-ping btn-primary" type="submit" variant="contained">{t('label_add')}</Button>
                                </Grid>
                            </Grid>
                        </Grid >
                    </Grid >

                </Grid >
                {/*Table */}
                < Grid item xs={12} className="event-tabel" >
                    <Grid container spacing={2}>
                        {!showSearch && < Grid item xs={12} md={6} className='title-label'>
                            {t('label_sub_history')}
                        </Grid >}
                        < Grid item xs={12} md={showSearch ? 12 : 6} className="input-search">
                            {showSearch ? <InputComponent
                                afterIcon={<SearchOutlinedIcon />}
                                value={values?.keySearch}
                                onChange={onSearch}
                                label={t('label_search')}
                                type="text"
                                beforeIcon={<CloseSharpIcon onClick={onShowSearch(false)} />}
                            /> : <SearchOutlinedIcon onClick={onShowSearch(true)} />}
                        </Grid>

                        < Grid item xs={12}>
                            <TabelComponent dataTabel={page.content}
                                onPageChange={handleChangePage}
                                page={page.number}
                                totalCount={page?.totalElements}
                                rowsPerPage={page.size}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                loading={loadingTabel}

                                columns={[
                                    {
                                        title: 'EVENT SOURCE',
                                        field: 'eventSource',
                                        className: 'tabel-text',
                                        render: (text) => {
                                            return text != 'PSA' ? 'Ocean lnsight' : text;
                                        },
                                    },
                                    {
                                        title: 'CARRIER',
                                        field: 'carrierDescription',
                                        className: 'tabel-text',
                                        filter: <TextField
                                            id="input-with-icon-textfield"
                                            onChange={onFilterCell}
                                            name="filterCode"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <FilterListIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    },
                                    {
                                        title: 'REFERENCE NUMBERS',
                                        field: 'bkRf',
                                        render: (text, row) => {
                                            return renderRefNum(row);
                                        }

                                    },
                                    {
                                        title: 'SUBSCRIPTION DATE',
                                        field: 'actionDate',
                                        render: (text) => {
                                            return Moment(text).format('DD/MM/YYYY, HH:mm')
                                        }

                                    },
                                    {
                                        title: '',
                                        field: 'id',
                                        render: (id, row) => {
                                            return row?.isSubscribed ? <div className={`btn-tabel`} onClick={() => {
                                                setConfirmModal({ content: row?.isSubscribed ? t('mgs_dyw_unsub') : t('mgs_dyw_sub'), open: true, id: id, isSubscribed: row?.isSubscribed })
                                            }}>
                                                {t('btn_unsub')}
                                            </div> : <div className={`no-item`} ></div>
                                        },
                                        className: "row-btn"
                                    }
                                ]} />
                        </Grid>
                    </Grid >
                </Grid >

            </Grid >
        </form >

        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default PingSearch_EventSubscription