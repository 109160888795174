import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Card, CardContent, Grid } from '@material-ui/core'

import { components } from 'cng-web-lib'
import FormProperties from './PingMyMilestone_FormProperties'
import PingMyMilestoneApiUrls from 'src/apiUrls/ping/PingMyMilestoneApiUrls'
import PingPathMap from 'src/paths/PathMap_Ping'
import DiscardButton from 'src/components/button/DiscardButton'
import GreenUpdateButton from 'src/components/button/GreenUpdateButton'

const { form: { CngEditForm } } = components

function EditPage({ history, showNotification }) {

    const { id } = useParams()

    const [fetchData, setFetchData] = useState()
    const fetchPreSuccessHandler = (response) => {

        // Update the eventTimezone and 
        setFetchData(response)
    }

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <Card>
            <CardContent>
                <CngEditForm
                    fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                    history={history}
                    showNotification={showNotification}
                    bodySection={
                        <FormProperties.Fields
                            showNotification={showNotification}
                            id={id}
                            fetchData={fetchData}
                        />
                    }
                    formikProps={FormProperties.formikProps}
                    toClientDataFormat={FormProperties.toClientDataFormat}
                    toServerDataFormat={FormProperties.toServerDataFormat}

                    fetch={{
                        url: PingMyMilestoneApiUrls.DETAILS_GET,
                        onPreSuccess: fetchPreSuccessHandler
                    }}

                    update={{
                        url: PingMyMilestoneApiUrls.UPDATE,
                        successRedirect: `${PingPathMap.PING_MY_MILESTONES}/edit-success/${id}`
                    }}

                    id={id}

                    //* The customized buttons...
                    renderButtonSection={() => {
                        return (
                            <Grid container>
                                <Grid item xs={6}>
                                    <DiscardButton
                                        style={{
                                            paddingLeft: '2em',
                                            paddingRight: '2em'
                                        }}
                                        onClick={(event) => {
                                            history.push(PingPathMap.PING_MY_MILESTONES_LIST_VIEW)
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: 'right' }}>
                                    <GreenUpdateButton style={{
                                        paddingLeft: '2em',
                                        paddingRight: '2em',
                                    }} />
                                </Grid>
                            </Grid>
                        )
                    }}
                />
            </CardContent>
        </Card>

        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default EditPage