import React, {useEffect, useState} from 'react'
import {components, useServices} from 'cng-web-lib'
import FreightBookingApiUrls from 'src/apiUrls/FreightBookingApiUrls';

const {
    card: {
        CngSimpleCardHeader,
    },
    form: {
        adapter: {
            useFormAdapter:{ useField, useFormikContext }
        },
        field: {
            CngTextField,
            CngSelectField,
            CngDateField,
            CngDateTimeField,
            CngCodeMasterAutocompleteField
        },
    }
} = components


export default function UserBookingListField(props) {

    const {fetchRecords} = useServices();
    const [bookings, setBookings] = useState([]);

    const getBookingList = (partyList) => {

        fetchRecords.execute(
            FreightBookingApiUrls.BOOKING_LIST,
            {
                customData: {dateRange: 'all', filterText: '', sortCode: 1, partyIds: partyList, statusCodes: ['5102'], showArchived: true}
            },
            (data) => {
                let bookingList = []
                data.content.forEach(partyData => {bookingList.push({"text": partyData.dockey, "value": partyData.bookingId})})
                setBookings(bookingList)
                console.log("##### bookingList:["+JSON.stringify(bookingList)+"]")
            },
            (error) => {
                console.log("Error in getting partyId: " + error)
            }
        )
    }

    const getBookingListCriteria = () => {

        fetchRecords.execute(
            FreightBookingApiUrls.PARTY_LIST_BOOKING_COUNT,
            {
                customData: {dateRange: 'all', filterText: '', sortCode: 1, statusCodes: ['5102'], showArchived: true}
            },
            (data) => {
                let partyList = data.content.map(partyData => partyData.partyId)
                getBookingList(partyList)
            },
            (error) => {
                console.log("Error in getting partyId: " + error)
            }
        )
    }

    useEffect( () => {
        getBookingListCriteria();
    },[]);

    return (
        <CngSelectField
            name={props.response.parameterName}
            label={props.response.parameterDisplayNameEn}
            disabled={false}
            multiple
            defaultValue={[]}
            items = {bookings}
        />
    );
}