import {Card, CardContent, Grid} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {
    components,
    useServices,
    constants,
    useTranslation
} from 'cng-web-lib'
import Namespace from "../../../constants/locale/Namespace";
import ReportManagementKeys from "../../../constants/locale/key/ReportManagementKeys";
import CodeMaintainanceApiUrls from "../../../apiUrls/CodeMaintainanceApiUrls";
import paramValidationSchema from "./ParamValidationSchema";

const {
    card: {
        CngSimpleCardHeader,
    },
    form: {
        field: {
            CngTextField,
            CngSelectField
        },
    },
    CngGridItem,
} = components;

const {
    CodeMaintenanceType
} = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
    id: 0,
    parameterName: "",
    parameterDisplayNameEn: "",
    fieldType: "",
    regexValidation: "OPTIONAL"
});

const FORMIK_PROPS = {
    initialValues: { ...DEFAULT_INITIAL_VALUES },
    makeValidationSchema: paramValidationSchema
};

function Fields({
                    disabled
                }) {

    const {translate} = useTranslation(Namespace.REPORT_MANAGEMENT);
    const translatedTextsObject = makeTranslatedTextsObject();
    const { fetchRecords } = useServices();

    const [fieldTypeOptions, setFieldTypeOptions] = useState();

    useEffect(()=> {

        const onSuccess = (response) => {
            let options = response.content.map((record) => ({
                text:record['descriptionEn'],
                value: record["code"]
            }));
            setFieldTypeOptions(options);
        }

        const onError = (error) => { console.log("Error:[" + JSON.stringify(error) + "]") }

        const onComplete = () => {console.log("On complete")}

        fetchRecords.execute(
            CodeMaintainanceApiUrls.MAINTAINANCE_CODE,
            {
                customData: { codeMType: CodeMaintenanceType.CODE_MASTER },
                filters: [
                    {
                        field: 'codeType',
                        operator: 'EQUAL',
                        value: 'DYNAMIC_FIELD_TYPE'
                    }
                ]
            },
            onSuccess,
            onError,
            onComplete
        )

    }, []);

    function makeTranslatedTextsObject() {

        let title = translate(
            Namespace.REPORT_MANAGEMENT,
            ReportManagementKeys.ReportParameterList.TITLE
        )

        let id = translate(
            Namespace.REPORT_MANAGEMENT,
            ReportManagementKeys.ReportParameterList.PARAMETER_ID
        )

        let parameterName = translate(
            Namespace.REPORT_MANAGEMENT,
            ReportManagementKeys.ReportParameterList.PARAMETER_NAME
        )

        let parameterDisplayNameEn = translate(
            Namespace.REPORT_MANAGEMENT,
            ReportManagementKeys.ReportParameterList.PARAMETER_DISPLAY_NAME_EN
        )

        let parameterDisplayNameAr = translate(
            Namespace.REPORT_MANAGEMENT,
            ReportManagementKeys.ReportParameterList.PARAMETER_DISPLAY_NAME_AR
        )

        let parameterDisplayNameEs = translate(
            Namespace.REPORT_MANAGEMENT,
            ReportManagementKeys.ReportParameterList.PARAMETER_DISPLAY_NAME_ES
        )

        let parameterDisplayNameFr = translate(
            Namespace.REPORT_MANAGEMENT,
            ReportManagementKeys.ReportParameterList.PARAMETER_DISPLAY_NAME_FR
        )

        let parameterDisplayNameZh = translate(
            Namespace.REPORT_MANAGEMENT,
            ReportManagementKeys.ReportParameterList.PARAMETER_DISPLAY_NAME_ZH
        )

        let parameterDisplayNamePt = translate(
            Namespace.REPORT_MANAGEMENT,
            ReportManagementKeys.ReportParameterList.PARAMETER_DISPLAY_NAME_PT
        )

        let parameterDisplayNameRu = translate(
            Namespace.REPORT_MANAGEMENT,
            ReportManagementKeys.ReportParameterList.PARAMETER_DISPLAY_NAME_RU
        )

        let parameterDisplayNameHi = translate(
            Namespace.REPORT_MANAGEMENT,
            ReportManagementKeys.ReportParameterList.PARAMETER_DISPLAY_NAME_HI
        )

        let parameterDisplayNameNl = translate(
            Namespace.REPORT_MANAGEMENT,
            ReportManagementKeys.ReportParameterList.PARAMETER_DISPLAY_NAME_NL
        )

        let parameterValue = translate(
            Namespace.REPORT_MANAGEMENT,
            ReportManagementKeys.ReportParameterList.PARAMETER_VALUE
        )

        let fieldType = translate(
            Namespace.REPORT_MANAGEMENT,
            ReportManagementKeys.ReportParameterList.FIELD_TYPE
        )

        let regexValidation = translate(
            Namespace.REPORT_MANAGEMENT,
            ReportManagementKeys.ReportParameterList.REGEX_VALIDATION
        )

        return {
            id,
            title,
            parameterName,
            parameterDisplayNameEn,
            parameterDisplayNameAr,
            parameterDisplayNameEs,
            parameterDisplayNameFr,
            parameterDisplayNameZh,
            parameterDisplayNamePt,
            parameterDisplayNameRu,
            parameterDisplayNameHi,
            parameterDisplayNameNl,
            parameterValue,
            fieldType,
            regexValidation
        }
    }

    return (
        <Card>
            <CngSimpleCardHeader title={translatedTextsObject.title}/>
            <CardContent>
                <Grid container spacing={3}>
                    <CngGridItem xs={12} sm={6} shouldHide={true}>
                        <CngTextField
                            name="id"
                            label={translatedTextsObject.id}
                            disabled={disabled}
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={false}>
                        <CngTextField
                            name="parameterName"
                            label={translatedTextsObject.parameterName}
                            disabled={disabled}
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={false}>
                        <CngTextField
                            name="parameterDisplayNameEn"
                            label={translatedTextsObject.parameterDisplayNameEn}
                            disabled={disabled}
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={false}>
                        <CngTextField
                            name="parameterDisplayNameAr"
                            label={translatedTextsObject.parameterDisplayNameAr}
                            disabled={disabled}
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={false}>
                        <CngTextField
                            name="parameterDisplayNameEs"
                            label={translatedTextsObject.parameterDisplayNameEs}
                            disabled={disabled}
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={false}>
                        <CngTextField
                            name="parameterDisplayNameFr"
                            label={translatedTextsObject.parameterDisplayNameFr}
                            disabled={disabled}
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={false}>
                        <CngTextField
                            name="parameterDisplayNameZh"
                            label={translatedTextsObject.parameterDisplayNameZh}
                            disabled={disabled}
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={false}>
                        <CngTextField
                            name="parameterDisplayNamePt"
                            label={translatedTextsObject.parameterDisplayNamePt}
                            disabled={disabled}
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={false}>
                        <CngTextField
                            name="parameterDisplayNameRu"
                            label={translatedTextsObject.parameterDisplayNameRu}
                            disabled={disabled}
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={false}>
                        <CngTextField
                            name="parameterDisplayNameHi"
                            label={translatedTextsObject.parameterDisplayNameHi}
                            disabled={disabled}
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={false}>
                        <CngTextField
                            name="parameterDisplayNameNl"
                            label={translatedTextsObject.parameterDisplayNameNl}
                            disabled={disabled}
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={false}>
                        <CngSelectField
                            name="fieldType"
                            label={translatedTextsObject.fieldType}
                            disabled={disabled}
                            items={fieldTypeOptions}
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={false}>
                        <CngSelectField
                            name="regexValidation"
                            label={translatedTextsObject.regexValidation}
                            disabled={disabled}
                            items={[
                                { text: 'Mandatory', value: 'MANDATORY' },
                                { text: 'Optional', value: 'OPTIONAL' }
                            ]}
                        />
                    </CngGridItem>
                </Grid>
            </CardContent>
        </Card>
    )
}

const ReportParameterFormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    Fields: Fields
})

export default ReportParameterFormProperties