import React from 'react'
import { useHistory } from 'react-router-dom'
import { Card, CardContent, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';

import { useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'

import PingKeys from 'src/constants/locale/key/Ping'
import PingPathMap from 'src/paths/PathMap_Ping'
import KebabMenuButton from 'src/components/button/KebabMenuButton'

// FontAwesome imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { far as FontAwesomeRegular } from '@fortawesome/pro-regular-svg-icons'
import { library as FontAwesomeLibrary } from '@fortawesome/fontawesome-svg-core'

// Moment library
import Moment from 'moment';
import 'moment-timezone';

FontAwesomeLibrary.add(FontAwesomeRegular)

//* Styles ---------------------------------------------------------------------
const useStyles = makeStyles({
    // for the delete animations
    root: {
        transition: 'all 1s',
        maxHeight: '10em',
        opacity: 1,
    },
    root_deleted: {
        transition: 'all 1s',
        maxHeight: 0,
        opacity: 0,
        padding: 0
    },

    // for the hover animations
    root_card: {
        borderRadius: '1em',
        cursor: 'pointer',
        transition: 'background-color 0.5s',
        '&:hover': { backgroundColor: '#F9FAFF' }
    }
});

//* ----------------------------------------------------------------------------
function MyMilestoneRow({
    milestone,
    onDeleteClick = (id) => { /* Do nothing */ },
    isDeleted = false
}) {
    const history = useHistory()
    const classes = useStyles();

    // Translations ------------------------------------------------------------
    const { translate } = useTranslation(Namespace.PING)

    const PingFieldsKey = PingKeys.FIELDS

    const Labels = {
        REFERENCE_NUM: translate(Namespace.PING, PingFieldsKey.REFERENCE_NUM)
    }

    //* ------------------------------------------------------------------------
    // format the event date and time
    let moment = Moment(milestone.eventTimestamp)

    if (milestone.eventTimezone) {
        moment.tz(milestone.eventTimezone)
    }

    const eventDateStr = moment.format('D MMM yyyy - HH:mm ([GMT] Z)')

    const cardClickHandler = (event) => {
        let rootCardElement = event.target.closest('[data-tag="root-card"]')
        let rowActionElement = event.target.closest('[data-tag="row-action"]')

        let rowClicked = Boolean(rootCardElement)
        let rowActionClicked = Boolean(rowActionElement)

        // redirect only when clicked on row, except when clicking on the action menu
        let shouldRedirect = (rowClicked == true) && (rowActionClicked == false)

        if (shouldRedirect == true) {
            let viewUrl = `${PingPathMap.PING_MY_MILESTONES}/view/${milestone.myMilestoneId}`
            history.push(viewUrl)
        }
    }

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <Grid item xs={12} className={(isDeleted) ? (classes.root_deleted) : (classes.root)}>
            <Card variant='outlined' className={classes.root_card}
                onClick={cardClickHandler} data-tag='root-card'
            >
                <CardContent>
                    <Grid container spacing={1} alignItems='center'>
                        <Grid item xs={11} container spacing={1} alignItems='center'>
                            <Grid item xs={2} xl={1}>
                                <Card variant='outlined' style={{
                                    display: 'inline-block',
                                    borderRadius: '1em',
                                    padding: '0.2em 0.5em'
                                }}>
                                    <Typography color='primary'>{milestone.eventCode}</Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography color='textPrimary' style={{ fontWeight: 'bold' }}>
                                    {milestone.eventName}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography color='textSecondary'
                                    style={{
                                        fontWeight: 'bold',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}
                                >
                                    {Labels.REFERENCE_NUM}: {milestone.referenceValue}
                                </Typography>
                            </Grid>
                            <Grid item xs={3} xl={4}>
                                <Typography color='textSecondary' display='inline'>
                                    <FontAwesomeIcon icon={['far', 'calendar-day']} style={{ marginRight: '0.5em' }} />
                                    {eventDateStr}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={1} container spacing={1} alignItems='center' justify='flex-end'>
                            <KebabMenuButton
                                hasEdit={true}
                                onEditClick={(event) => {
                                    let url = `${PingPathMap.PING_MY_MILESTONES}/edit/${milestone.myMilestoneId}`
                                    history.push(url)
                                }}

                                hasDelete={true}
                                onDeleteClick={(event) => {
                                    if (onDeleteClick) {
                                        onDeleteClick(milestone.myMilestoneId)
                                    }
                                }}

                                // tag it so that clicking on this will not redirect to view page.
                                data-tag='row-action'
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default MyMilestoneRow
