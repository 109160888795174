import React, { useState, useEffect } from 'react'
import { Checkbox, Typography, CircularProgress, CardContent, Grid, FormControlLabel } from '@material-ui/core'

import { useTranslation, useServices } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import PingKeys from 'src/constants/locale/key/Ping'

import LoadingSpinnerLabel from 'src/components/label/LoadingSpinnerLabel'
import ErrorRetrievingDataLabel from 'src/components/label/ErrorRetrievingDataLabel'
import PingMyMilestoneApiUrls from 'src/apiUrls/ping/PingMyMilestoneApiUrls'

function EventCodeFilter({
    clearAllFilterClickedTime,
    onChange
}) {
    const { securedSendRequest } = useServices()

    // Translations ------------------------------------------------------------
    const { translate } = useTranslation(Namespace.PING)
    const FilterKeys = PingKeys.PING_MY_MILESTONES.FILTERS;

    const Labels = {
        EVENT_CODE: translate(Namespace.PING, PingKeys.FIELDS.EVENT_CODE),
        ALL: translate(Namespace.PING, FilterKeys.ALL)
    }

    //* ------------------------------------------------------------------------
    const DEFAULT_CHECKED_VALUES = { ALL: true }
    const [checkedValues, setCheckedValues] = useState({ ...DEFAULT_CHECKED_VALUES })

    const [initialLoadingState, setInitialLoadingState] = useState({
        isInitialLoading: true,
        isInitialLoadingError: false
    })

    // This state stores the event code options after the Ajax call is done.
    const [eventCodeOptions, setEventCodeOptions] = useState(false)
    const [recordTotalCount, setRecordTotalCount] = useState(false)

    // Monitor the Clear All clicked time. When changed, reset the value.
    useEffect(() => {
        setCheckedValues({ ...DEFAULT_CHECKED_VALUES })
    }, [clearAllFilterClickedTime])

    // The 'ALL' checkbox need special handling.
    const allCheckboxChangeHandler = (event) => {
        let currentChecked = checkedValues.ALL

        // Uncheck everything else, and change the current.
        let newCheckedValues = { ...DEFAULT_CHECKED_VALUES }
        newCheckedValues.ALL = !(currentChecked)
        setCheckedValues(newCheckedValues)
    }

    const checkboxChangeHandler = (event) => {
        let checkboxValue = event.target.value

        let currentChecked = (checkedValues[checkboxValue] == true)

        let newCheckedValues = { ...checkedValues }
        newCheckedValues[checkboxValue] = !(currentChecked)
        setCheckedValues(newCheckedValues)
    }

    // Initial load effect.
    useEffect(() => {

        // On page load, call Ajax to get the list of event code options.
        let url = PingMyMilestoneApiUrls.EVENT_CODE_FILTER_OPTION_GET
        let data = {}

        securedSendRequest.execute('POST', url, data,
            (response) => {
                // success
                setInitialLoadingState({
                    isInitialLoading: false,
                    isInitialLoadingError: false
                })

                setEventCodeOptions(response.data)

                // count the record total count
                let totalCount = 0
                response.data.forEach(item => {
                    totalCount += item.recordCount
                })

                setRecordTotalCount(String(totalCount))
            },
            (error) => {
                // error
                console.error(error)
                setInitialLoadingState({
                    isInitialLoading: false,
                    isInitialLoadingError: true
                })
            },
            () => {
                // complete
            }
        )
    }, [])

    useEffect(() => {

        // Trigger the onChange when checkedValues are changed.
        if (onChange) {
            let selectedValues = []

            for (let [key, value] of Object.entries(checkedValues)) {
                if (value == true) {
                    selectedValues.push(key)
                }
            }

            onChange(selectedValues)
        }
    }, [checkedValues])

    // Monitor the Clear All clicked time. When changed, reset the value.
    useEffect(() => {
        setCheckedValues({ ...DEFAULT_CHECKED_VALUES })
    }, [clearAllFilterClickedTime])

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <Grid container>
            <Grid item xs={12}>
                <CardContent style={{
                    background: '#8996AF10',
                    padding: '1em',
                    marginTop: '1em',
                    textTransform: 'uppercase',
                    fontWeight: 'bold'
                }}>
                    {Labels.EVENT_CODE}
                </CardContent>
            </Grid>
            <Grid item xs={12}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <FormControlLabel
                                label={
                                    <>
                                        <Typography color='textPrimary' display='inline'>{Labels.ALL}</Typography>
                                        <Typography component='span' color='textSecondary' display='inline'>&nbsp; (
                                            {recordTotalCount || <CircularProgress size='1em' />}
                                            )
                                        </Typography>
                                    </>
                                }
                                control={
                                    <Checkbox
                                        checked={checkedValues.ALL}
                                        onChange={allCheckboxChangeHandler}
                                    />
                                }
                            />
                        </Grid>

                        {
                            (initialLoadingState.isInitialLoading == true) &&
                            (
                                /* Show a loading spinner during initial loading. */
                                <Grid item xs={12}>
                                    <LoadingSpinnerLabel />
                                </Grid>
                            )
                        }

                        {
                            (initialLoadingState.isInitialLoadingError == true) &&
                            (
                                /* Show error if failed to initial load. */
                                <Grid item xs={12}>
                                    <ErrorRetrievingDataLabel />
                                </Grid>
                            )
                        }

                        {
                            (eventCodeOptions != false) &&
                            (
                                eventCodeOptions.map((option, index) => {
                                    let key = 'EventCodeOption_' + index;

                                    return (
                                        <Grid item xs={12} key={key}>
                                            <FormControlLabel
                                                disabled={(checkedValues.ALL == true)}
                                                label={
                                                    <>
                                                        <Typography color='textPrimary' display='inline'>{option.eventName}</Typography>
                                                        <Typography color='textSecondary' display='inline'>&nbsp; ({option.recordCount})</Typography>
                                                    </>
                                                }
                                                control={
                                                    <Checkbox value={option.eventCode}
                                                        checked={(checkedValues.ALL) || (checkedValues[option.eventCode])}
                                                        onChange={checkboxChangeHandler}
                                                    />
                                                }
                                            />
                                        </Grid>
                                    )
                                })
                            )
                        }
                    </Grid>
                </CardContent>
            </Grid>
        </Grid>
        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default EventCodeFilter
