import React, {useEffect} from 'react'
import Namespace from 'src/constants/locale/Namespace'
import ReportManagementKeys from "src/constants/locale/key/ReportManagementKeys";
import {Card, CardContent, Grid } from '@material-ui/core'
import {useTranslation, components, DataFlattener, DateTimeFormatter, Yup, useServices} from 'cng-web-lib'
import ReportManagementApiUrls from "../../../apiUrls/ReportManagementApiUrls";
import PortSelectAutoComplete from "../../vesselschedule/searchschedule/PortSelectAutoComplete";
import TtfbTprPartyApiUrls from 'src/apiUrls/TtfbTprPartyApiUrls.js'
import ReportDownloadApiUrls from "../../../apiUrls/ReportDownloadApiUrls";
import CarrierListField from "./CarrierListField";
import ShipperListField from "./ShipperListField";
import UserBookingListField from "./UserBookingListField";
import CurrentUserPartyId from "./CurrentUserPartyId";
import CurrentUserPartyName from "./CurrentUserPartyName";

const {
    card: {
        CngSimpleCardHeader,
    },
    form: {
        adapter: {
            useFormAdapter:{ useField, useFormikContext }
        },
        field: {
            CngTextField,
            CngSelectField,
            CngDateField,
            CngDateTimeField,
            CngCodeMasterAutocompleteField
        },
    }
} = components


const DEFAULT_INITIAL_VALUES = Object.freeze({
    id: 0
})

function initValidationSchema() {
    const dynamicValidation = {
        parameterName: Yup.string(),
        parameterDisplayName: Yup.string()
    }
    return Yup.object( dynamicValidation );
}

const FORMIK_PROPS = {
    initialValues: {...DEFAULT_INITIAL_VALUES},
    makeValidationSchema: initValidationSchema
}

const onChangePortsAutoComplete = (value, option) => {
    if (value != null) {
        console.log("on change port:["+JSON.stringify(value)+"] option:["+option+"]");
    }
}

export function generateField(response, id, dynamicFieldData) {
    if(response.fieldType === "DATE_FIELD") {
        return <Grid key={id} item xs={6}>
            <CngDateField
                name={response.parameterName}
                label={response.parameterDisplayNameEn}
                disabled={false}
                defaultValue={DateTimeFormatter.toClientDate(new Date())}
            />
        </Grid>
    } else if (response.fieldType === "DATE_TIME_FIELD") {
        return <Grid key={id} item xs={6}>
            <CngDateTimeField
                name={response.parameterName}
                label={response.parameterDisplayNameEn}
                disabled={false}
                format='YYYY-MM-DDTHH:mm:ss'
                toServerDataFormat='YYYY-MM-DDTHH:mm:ss'
            />
        </Grid>
    } else if (response.fieldType === "PARTY_FIELD") {
        return <Grid key={id} item xs={6}>
            <CngSelectField
                name={response.parameterName}
                label={response.parameterDisplayNameEn}
                disabled={false}
                fetch={
                    {
                        url: TtfbTprPartyApiUrls.GET_BY_PARTY_SERVICE,
                        textAccessor: "name"
                    }
                }
            />
        </Grid>
    }
    else if (response.fieldType === "CARRIER_LIST_FIELD") {
        return <Grid key={id} item xs={6}>
            <CarrierListField response={response}/>
        </Grid>
    }
    else if (response.fieldType === "SHIPPER_LIST_FIELD") {
        return <Grid key={id} item xs={6}>
            <ShipperListField response={response}/>
        </Grid>
    }
    else if (response.fieldType === "CURRENT_USER_FIELD") {
        return <Grid key={id} item xs={6}>
            <CngSelectField
                name={response.parameterName}
                label={response.parameterDisplayNameEn}
                disabled={false}
                items = {dynamicFieldData.currentUser}
            />
        </Grid>
    }
    else if (response.fieldType === "USER_BK_LIST_FIELD") {
        return <Grid key={id} item xs={6}>
            <UserBookingListField response={response}/>
        </Grid>
    } else if (response.fieldType === "CURRENT_USER_PARTY_ID") {
        return <Grid key={id} item xs={6}>
            <CurrentUserPartyId response={response}/>
        </Grid>
    } else if (response.fieldType === "CURRENT_USER_PARTY_NAME") {
        return <Grid key={id} item xs={6}>
            <CurrentUserPartyName response={response}/>
        </Grid>
    } else if (response.fieldType === "PORT_FIELD") {
        return <Grid key={id} item xs={6}>
            <PortSelectAutoComplete
                name={response.parameterName}
                label={response.parameterDisplayNameEn}
                keyRef={response.parameterValue}
                onChangeAutoComplete={onChangePortsAutoComplete}
                disabled={false}
            />
        </Grid>
    } else if (response.fieldType === "PING_SEARCH_TYPE_FIELD") {
        return <Grid key={id} item xs={6}>
            <CngCodeMasterAutocompleteField
                name={response.parameterName}
                label={response.parameterDisplayNameEn}
                codeType='CAL_PING_SEARCH_TYPE'
                key={response.parameterValue}
            />
        </Grid>
    } else if (response.fieldType === "PING_SEARCH_RESULT_FIELD") {
        return <Grid key={id} item xs={6}>
            <CngCodeMasterAutocompleteField
                name={response.parameterName}
                label={response.parameterDisplayNameEn}
                codeType='CAL_PING_SEARCH_RESULT'
                key={response.parameterValue}
            />
        </Grid>
    } else {
        return <Grid key={id} item xs={6}>
            <CngTextField
                fullWidth
                label={response.parameterDisplayNameEn}
                name={response.parameterName}
                required={false}
                type="text"
            />
        </Grid>;
    }

}

function Fields({response, reportId }) {
    const {translate} = useTranslation(Namespace.REPORT_MANAGEMENT);
    const translatedTextsObject = makeTranslatedTextsObject();
    const { fetchRecords } = useServices();

    const dynamicFieldDataReducer = (state, action) => {
        switch (action.type) {
            case 'RESET_DATA':
                return {
                    ...state,
                    //carrierList: [],
                    //shipperList: [],
                    currentUser: []
                }
            // case 'UPDATE_SHIPPER_LIST':
            //     return {
            //         ...state,
            //         shipperList: action.payload.shipperList
            //     }
            // case 'UPDATE_CARRIER_LIST':
            //     return {
            //         ...state,
            //         carrierList: action.payload.carrierList
            //     }
            case 'UPDATE_CURRENT_USER':
                return {
                    ...state,
                    currentUser: action.payload.currentUser
                }
        }
    };

    useEffect(() => {


        //fetch shipper list
        /*fetchRecords.execute(
            ReportDownloadApiUrls.GET_PARTY_LIST_BY_USER_GROUP_CODE,
            { filters: [{ field: 'userGroupCode', operator: 'EQUAL', value: 'SHP' }] },
            (data) => {
                const fetchedData = { shipperList: data }
                dispatchDynamicFieldData({
                    type: 'UPDATE_SHIPPER_LIST',
                    payload: fetchedData
                });
            },
            (error) => { console.log("Error in getting shipper list: " + error) }
        );*/


        // fetch carrier list
        /*fetchRecords.execute(
            ReportDownloadApiUrls.GET_PARTY_LIST_BY_USER_GROUP_CODE,
            { filters: [ { field: 'userGroupCode', operator: 'EQUAL', value: 'PCS' } ] },
            (data) => {
                const fetchedData = { carrierList: data }
                dispatchDynamicFieldData({
                    type: 'UPDATE_CARRIER_LIST',
                    payload: fetchedData
                });
            },
            (error) => { console.log("Error in getting carrier list: " + error) }
        );*/


        // fetch current user
        fetchRecords.execute(
            ReportDownloadApiUrls.GET_CURRENT_USER,
            undefined,
            (data) => {
                const fetchedData = { currentUser: data }
                dispatchDynamicFieldData({
                    type: 'UPDATE_CURRENT_USER',
                    payload: fetchedData
                });
            },
            (error) => { console.log("Error in getting current user: " + error) }
        );


    }, []);

    const[dynamicFieldData, dispatchDynamicFieldData] = React.useReducer(
        dynamicFieldDataReducer,
        { shipperList: [], currentUser: [] }
    );

    function makeTranslatedTextsObject() {

        let title = translate(
            Namespace.REPORT_MANAGEMENT,
            ReportManagementKeys.ReportParameterList.DOWNLOAD_TITLE
        )

        let id = translate(
            Namespace.REPORT_MANAGEMENT,
            ReportManagementKeys.ReportParameterList.PARAMETER_ID
        )

        let parameterName = translate(
            Namespace.REPORT_MANAGEMENT,
            ReportManagementKeys.ReportParameterList.PARAMETER_NAME
        )

        let parameterDisplayName = translate(
            Namespace.REPORT_MANAGEMENT,
            ReportManagementKeys.ReportParameterList.PARAMETER_DISPLAY_NAME_EN
        )

        let parameterValue = translate(
            Namespace.REPORT_MANAGEMENT,
            ReportManagementKeys.ReportParameterList.PARAMETER_VALUE
        )

        return {
            title,
            id,
            parameterName,
            parameterDisplayName,
            parameterValue
        }

    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Card>
                    <CngSimpleCardHeader title={translatedTextsObject.title}/>
                    <CardContent>
                        <Grid container spacing={3}>

                            <Grid key='reportId' item xs={6}>
                                <CngSelectField
                                    name="reportId"
                                    type="number"
                                    label='Report Name'
                                    disabled={false}
                                    value={reportId}
                                    fetch={
                                        {
                                            url: ReportManagementApiUrls.GET_BY_ID,
                                            textAccessor: "reportName",
                                            valueAccessor: "id",
                                            body: {
                                                id: reportId
                                            }
                                        }
                                    }
                                />
                            </Grid>
                            <Grid item xs={6} />

                            {response.map((data, index) => {
                                return generateField(data, index, dynamicFieldData)
                            })}

                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

function toClientDataFormat(serverData) {
    return  DataFlattener.parse(serverData);
}

function toServerDataFormat(localData) {

    for (const [ key, value ] of Object.entries(localData)) {
        if(Array.isArray(value)){
            localData[key] = localData[key].join()
        }
    }
    console.log("dta to server: " + JSON.stringify(localData))
    return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    Fields: Fields,
    toClientDataFormat: toClientDataFormat,
    toServerDataFormat: toServerDataFormat
})

export default FormProperties
