import React from 'react'
import { Grid, Paper } from '@material-ui/core'

import PingSearchTopPanel from '../TopPanel/PingSearchTopPanel'

import 'src/views/ping/search/styles/ping-base.css'
import 'src/views/ping/search/styles/ping-responsive.css'
import 'src/views/ping/search/styles/ping-search.css'
import 'src/views/ping/search/styles/ping-bookinglist.css'
import 'src/views/ping/search/styles/ping-favouritelist.css'
import 'src/views/ping/search/styles/ping-booking.css'
import 'src/views/ping/search/styles/ping-container.css'
import 'src/views/ping/search/styles/ping-milestone.css'

function PingSearch_Base({ pageName, leftPanelComponent, rightPanelComponent, onSearchButtonClick = null }) {

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <Grid container spacing={3} id='ping_search_page' className={pageName}>
            <Grid id='top_panel' item xs={12}>
                <PingSearchTopPanel onSearchButtonClick={onSearchButtonClick} />
            </Grid>

            <Grid id='bottom_panel' item xs={12}>
                <Paper>
                    <Grid container>
                        <Grid id='left_panel' item xs={12} md={3} xl={3} container alignItems="stretch">
                            <Paper>
                                {leftPanelComponent}
                            </Paper>
                        </Grid>

                        <Grid id='right_panel' item xs={12} md={9} xl={9} container>
                            {rightPanelComponent}
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
        //* End JSX ------------------------------------------------------------

    );

    //* End of function --------------------------------------------------------
}

export default PingSearch_Base