import ReportDownloadApiUrls from 'src/apiUrls/ReportDownloadApiUrls'
import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React, {useEffect, useState} from 'react'
import { components,useServices, DateTimeFormatter, Yup } from 'cng-web-lib'
import { useParams } from 'react-router-dom'
import CngBackdrop from 'src/views/vesselschedule/searchschedule/cngcomponent/CngBackDrop'
import moment from 'moment'

const {
    table: {useDefaultNotification},
    form: {CngAddForm},
    CngGridItem
} = components

function EditPage({ showNotification }) {

    const { id } = useParams();
    const [formInitialValues, setFormInitialValues] = useState({});
    const [responseData, setResponseData] = useState([]);
    const [userData, setUserData] = useState({"partyName": '', "partyId": '', "bookingList": [], "carrierList": []});
    const [fetchReportData, setFetchReportData] = useState(false);
    const [loading, setLoading] = useState(true)
    const {fetchRecords} = useServices();
    const [validations, setValidations] = useState( { parameterName: Yup.string(), parameterDisplayName: Yup.string() } );
    const [addFormKey, setAddFormKey] = useState(false);

    const {
        success: showSuccessNotification,
        error: showErrorNotification
    } = useDefaultNotification(showNotification);



    useEffect(() => {

        const onSuccess = (response) => {
            setResponseData(response.content);
            generateDynamicValidations(response.content);
            setFetchReportData(true)
            setLoading(false)
        }

        const onError = (error) => {
            console.log("Error:[" + JSON.stringify(error) + "]")
        }

        const onComplete = () => {
            console.log("Finish retrieving report parameters")
        }

        fetchRecords.execute(
            ReportDownloadApiUrls.GET_BY_ID,
            {customData: {id: id}},
            onSuccess,
            onError,
            onComplete
        );

    }, []);

    useEffect(() => {
      // Setting Initial Values
      if( fetchReportData){
        let initVal = {};
        responseData.forEach( t => {
          if (t.fieldType === "DATE_FIELD"){
            initVal[t.parameterName] = DateTimeFormatter.toClientDate(new Date());
          } else if (t.fieldType === "DATE_TIME_FIELD"){
              let nowInLocal = moment().local();
              //default value will set to time now in local
              initVal[t.parameterName] = nowInLocal.format('YYYY-MM-DDTHH:mm:ss');
              if(t.parameterName?.toLowerCase().includes("param_from")) {
                  initVal[t.parameterName] = nowInLocal.startOf('day').format('YYYY-MM-DDTHH:mm:ss');
              }
          } else {
            initVal[t.parameterName] = '';
          }
        })
        initVal['reportId'] = id;
        setFormInitialValues(initVal);
      }
    }, [fetchReportData]);


    function isIterable (value) {
        return Symbol.iterator in Object(value);
    }

    function generateDynamicValidations(responseDataContent) {
        const dynamicValidation = {
            parameterName: Yup.string(),
            parameterDisplayName: Yup.string()
        }

        if (responseDataContent && responseDataContent.length > 0 && isIterable(responseDataContent) ) {
            for (const response of responseDataContent) {
                if( "MANDATORY" === response.regexValidation ) {
                    dynamicValidation[response.parameterName] = Yup.string().required("Required filed")
                }
                if ( "NUMBER" === response.regexValidation ) {
                    dynamicValidation[response.parameterName] = Yup.number()
                }
                if( "OPTIONAL" === response.regexValidation ) {
                    dynamicValidation[response.parameterName] = Yup.string().nullable()
                }

                //validation for from_date
                if( "param_from_date" === response.parameterName && "MANDATORY" === response.regexValidation ) {
                    dynamicValidation['param_from_date'] = Yup.date("Invalid date").required("Required filed").nullable()
                }
                if( "param_from_date" === response.parameterName && "OPTIONAL" === response.regexValidation ) {
                    dynamicValidation['param_from_date'] = Yup.date("Invalid date").nullable()
                }

                //validation for to_date
                if( "param_to_date" === response.parameterName && "MANDATORY" === response.regexValidation ) {
                    dynamicValidation['param_to_date'] = Yup.date("Invalid date")
                        .required("Required filed")
                        .nullable()
                        .test({
                            name: 'max',
                            exclusive: false,
                            params: {},
                            message: 'Invalid range. Range must be ['+response.dateRangeMaxLimit+'] days',
                            test: function (value) {
                                try {
                                    let fromDate = new Date(this.parent.param_from_date);
                                    let maxDate = new Date(this.parent.param_from_date);
                                    maxDate.setDate(maxDate.getDate() + response.dateRangeMaxLimit);
                                    if( this.parent.param_from_date == null) {
                                        return false;
                                    }
                                    if( fromDate.getTime() > value.getTime()) {
                                        return false;
                                    }
                                    return maxDate.getTime() > value.getTime();
                                }
                                catch(err) {
                                    return false
                                }
                            },
                        })
                }
                if( "param_to_date" === response.parameterName && "OPTIONAL" === response.regexValidation ) {
                    dynamicValidation['param_to_date'] = Yup.date("Invalid date")
                        .nullable()
                        .test({
                            name: 'max',
                            exclusive: false,
                            params: {},
                            message: 'Invalid range. Range must be ['+response.dateRangeMaxLimit+'] days',
                            test: function (value) {
                                try {
                                    let fromDate = new Date(this.parent.param_from_date);
                                    let maxDate = new Date(this.parent.param_from_date);
                                    maxDate.setDate(maxDate.getDate() + response.dateRangeMaxLimit);
                                    if( this.parent.param_from_date == null) {
                                        return true;
                                    }
                                    if( fromDate.getTime() > value.getTime()) {
                                        return false;
                                    }
                                    return maxDate.getTime() > value.getTime();
                                }
                                catch(err) {
                                    return false
                                }
                            },
                        })
                }

            }
        }
        setValidations( dynamicValidation );
    }

    function base64toBlob(base64Data) {
        let sliceSize = 1024;
        let byteCharacters = atob(base64Data);
        let bytesLength = byteCharacters.length;
        let slicesCount = Math.ceil(bytesLength / sliceSize);
        let byteArrays = new Array(slicesCount);

        for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            let begin = sliceIndex * sliceSize;
            let end = Math.min(begin + sliceSize, bytesLength);

            let bytes = new Array(end - begin);
            for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        return byteArrays;
    }

    function downloadExcel(byteArrays, filename, contentTypeHeader) {
        let blob = new Blob(byteArrays,{type: contentTypeHeader});
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();
    }

    function makeValidationSchemaUpdated() {
        return Yup.object( validations );
    }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngAddForm
          key={addFormKey}
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          showNotification={showNotification}
          resetButtonDisabled
          bodySection={
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
              response={responseData}
              validations={validations}
              reportId={id}
              userData={userData}
            />
          }
          formikProps={{
              ...FormProperties.formikProps,
              validationSchema: makeValidationSchemaUpdated,
              initialValues: formInitialValues
          }}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          create={{
              url: ReportDownloadApiUrls.DOWNLOAD,
              onSuccess: (data, setShouldShowPrimaryButtonCircularProgress, shouldShowPrimaryButtonCircularProgress) => {
                  showSuccessNotification("Downloaded Successfully");
                  setShouldShowPrimaryButtonCircularProgress(false);
                  let contentType = 'application/vnd.ms-excel';
                  let byteArrays = base64toBlob(data.base64Data);
                  downloadExcel(byteArrays, data.fileName, contentType);
              }
          }}
          id={id}
        />
      </Grid>
      <CngGridItem xs={12} sm={9} shouldHide={!loading}>
        <CngBackdrop loading={loading} />
      </CngGridItem>
    </Grid>
  )
}

export default EditPage
