import React, { useState, useEffect } from 'react'
import { TextField, CircularProgress } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { useServices } from 'cng-web-lib'
import PingSearchApiUrls from 'src/apiUrls/ping/PingSearchApiUrls'

/* Reference: https://mui.com/components/autocomplete/#load-on-open */
function VesselNameAutoComplete({ value, ...props }) {

    const { securedSendRequest } = useServices()

    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        // On first open, call Ajax to get the list of event code options.
        if ((open == true) && (options.length == 0) && (loading == false)) {

            setLoading(true)

            let url = PingSearchApiUrls.VESSEL_NAME_LIST
            let data = {}

            securedSendRequest.execute('GET', url, data,
                (response) => {
                    // success
                    console.debug(response)
                    setOptions(response.data)
                },
                (error) => {
                    // error
                    console.error(error)
                },
                () => {
                    // complete
                    setLoading(false)
                }
            )
        }
    }, [open]);

    //* return JSX -------------------------------------------------------------
    return (
        //* Start JSX ----------------------------------------------------------

        <Autocomplete
            {...props}
            className="search_value"

            value={value}
            filterOptions={(_options) => {

                let filteredOptions = _options.filter((_optionValue) => {
                    return (_optionValue.toUpperCase().includes(value.toUpperCase()))
                })

                console.log({
                    "_options": _options,
                    "filteredOptions": filteredOptions,
                    "value": value
                })

                return (filteredOptions)
            }}

            // Open/Close state.
            open={open}
            onOpen={() => { setOpen(true) }}
            onClose={() => { setOpen(false) }}

            loading={loading}
            options={options}

            renderInput={(params) => (
                <TextField
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        )
                    }}
                />
            )}

            // Make the dropdown look like textbox.
            disableClearable={true}
        />

        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default VesselNameAutoComplete