import React, { useEffect, useState } from 'react'

import { PowerBIEmbed } from 'powerbi-client-react'
import RiceStockApiUrls from 'src/apiUrls/RiceStockApiUrls'
import { makeStyles } from '@material-ui/core'
import { models } from 'powerbi-client'
import { constants, useServices } from 'cng-web-lib'

const useStyles = makeStyles((theme) => ({
  powerBiEmbed: {
    height: 'calc((100vw - 60px) * 0.50)'
  }
}))

function ImportStatus(props) {
  const classes = useStyles()
  const { showNotification } = props
  const { NotificationType } = constants

  const [reportConfig, setReportConfig] = useState({
    type: 'report',
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    accessToken: undefined
  })

  const { fetchRecord } = useServices()
  useEffect(() => {
    fetchRecord.execute(
      RiceStockApiUrls.GET_POWERBI_EMBEDING_INFO + '?reportName=ImportStatus',
      undefined,
      (data) => {
        if (
          data &&
          data.embedReports !== undefined &&
          (data.embedReports[0].pageName !== undefined ||
            data.embedReports[0].pageName !== '')
        )
          setReportConfig((prevState) => {
            let newConfig = {
              ...prevState,
              id: data.embedReports[0].reportId,
              embedUrl: data.embedReports[0].embedUrl,
              accessToken: data.embedToken,
              pageName: data.embedReports[0].pageName,
              settings: {
                filterPaneEnabled: false,
                navContentPaneEnabled: false
              }
            }

            if (data.filters != undefined) {
              let filterConfig = {
                $schema: 'http://powerbi.com/product/schema#basic',
                filterType: models.FilterType.Basic,
                requireSingleSelection: true,
                target: {
                  table: data.filters.table,
                  column: data.filters.column
                },
                operator: data.filters.operator,
                values: data.filters.values
              }

              newConfig = { ...newConfig, filters: [filterConfig] }
            }

            return newConfig
          })
      },
      (e) => {
        showNotification(NotificationType.ERROR, e.message)
        console.error(e)
      }
    )
  }, [])

  return (
    <PowerBIEmbed
      embedConfig={reportConfig}
      cssClassName={classes.powerBiEmbed}
    />
  )
}

export default ImportStatus
