import { Yup } from 'cng-web-lib'

function makeValidationSchema(translate) {
    const tempNumberRegx = /^-{0,1}[0-9]{1,3}(?:\.[0-9]{1})?$/
    
    const tempUnitErrorMessage = "Please enter only numeric values with 3 digits and maxinum 1 decimal point"
    const tempLargerThanMaxMessage = "Cannot be larger than maximum temperature"
    const tempSmallerThanMinMessage = "Cannot be smaller than minimum temperature"

    function validateTempUnitNumber(value, name) {
        if (!!value) {
          return tempNumberRegx.test(value)
        } else {
    
          return true;
        }
      }

    return Yup.object({
        referenceValue: Yup.string().nullable().required().max(50),
        vesselName: Yup.string().max(1000),
        voyageNum: Yup.string().max(1000),
        beeId: Yup.string().max(1000),
        temp: Yup.string().max(1000),
        humidity: Yup.string().max(1000),
        ambience: Yup.string().max(1000),
        maxTemp: Yup.number()
        .test("maxTemp", tempUnitErrorMessage, value => validateTempUnitNumber(value)
        )
        .min(Yup.ref('minTemp'), tempSmallerThanMinMessage),
       
        minTemp: Yup.number().test("minTemp", 
        tempUnitErrorMessage, 
        value => validateTempUnitNumber(value))
        .when("maxTemp", {
          is: undefined,
          then: Yup.number(),
          otherwise: Yup.number()
            .max(Yup.ref('maxTemp'), tempLargerThanMaxMessage),
        }),
    })
}

export default makeValidationSchema
