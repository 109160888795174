import {Card, CardContent, Grid, Tooltip} from '@material-ui/core'
import {
  components,
  constants,
  useTranslation,
  useServices
} from 'cng-web-lib'

import axios from 'axios'

import React, { useEffect, useState } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import GeneratedReportKeys from "../../../constants/locale/key/GeneratedReportKeys";
import GeneratedReportApiUrls from "../../../apiUrls/GeneratedReportApiUrls";
import { makeStyles } from '@material-ui/core/styles';
import UserCountTooltip from "../generatedreport/UserCountTooltip";
import Button from "@material-ui/core/Button";
import Utils from "../../common/utils/Utils";
import CodeMasterApi from "../../common/api/CodeMasterApi";
import moment from 'moment/moment'

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    useDefaultNotification,
    useFetchCustomLookup,
    useFetchCodeMaintenanceLookup
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  },
  filter: { EQUAL }
} = constants

const useStyles = makeStyles((theme) => ({

  aHover: {
    textDecoration: 'underline',
    cursor: "pointer",
    '&:hover': {
      color: 'blue',

    },
  },
  userCount: {
    color: 'blue'
  }

}));


function TablePage(props) {
  const {
    location: { pathname },
    showNotification
  } = props

  const notification = useDefaultNotification(showNotification)
  const fetchCustomLookup = useFetchCustomLookup()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.GENERATED_REPORT
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {

    let tableTitle = translate(
      Namespace.GENERATED_REPORT,
      GeneratedReportKeys.TITLE
    )

    let party = translate(
      Namespace.GENERATED_REPORT,
      GeneratedReportKeys.PARTY
    )

    let reportName = translate(
      Namespace.GENERATED_REPORT,
      GeneratedReportKeys.REPORT_NAME
    )

    let scheduleName = translate(
      Namespace.GENERATED_REPORT,
      GeneratedReportKeys.SCHEDULE_NAME
    )

    let users = translate(
      Namespace.GENERATED_REPORT,
      GeneratedReportKeys.USERS
    )

    let report = translate(
      Namespace.GENERATED_REPORT,
      GeneratedReportKeys.REPORT
    )

    let createdDate = translate(
      Namespace.GENERATED_REPORT,
      GeneratedReportKeys.CREATED_DATE
    )

    return {
      party,
      reportName,
      scheduleName,
      users,
      report,
      createdDate
    }
  }

  function onClickDownloadReport(data) {

    function isJSON(text) {
      if (typeof text !== "string") {
        return false;
      }
      try {
        let json = JSON.parse(text);
        return (typeof json === 'object');
      } catch (error) {
        return false;
      }
    }

    function onTokenLoadSuccess(codeMasterValue) {

      if (codeMasterValue.length > 0) {
        let tokenKey = codeMasterValue[0].descriptionEn
        console.log("Token key:[" + tokenKey + "]");
        let storageKey = localStorage.getItem(tokenKey);
        console.log('Retrieve token from localStorage', storageKey)
        let accessToken;
        if(isJSON(storageKey)) {
          let token = JSON.parse(storageKey)
          accessToken = (Utils.isEmpty(token.access_token)) ? token : token.access_token
        } else {
          accessToken = storageKey
        }

        axios(
            `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/report/generated-report/hdr/get-file-path/${data.id}`,
            {
              method: 'get',
              headers: {Authorization: `Bearer ${accessToken}`}
            })
            .then(res => {
              if (res.data) {
                window.open(res.data, "_blank")
              }
            })
      }

    }

    CodeMasterApi.fetchCodeMaster(
        fetchRecords,
        'REPORT_TOKEN',
        false,
        onTokenLoadSuccess
    )

  }

  const classes = useStyles();
  const { fetchRecords } = useServices();
  const onSuccess = (response) => {
    console.log("response", response)
  }

  function getTimezone(date) {

    if(date.length < 5)
      return "+0000";

    let time = date.slice(-5)

    if(time.includes('+') || time.includes('-'))
      return time;
    else
      return "+0800";
  }

  const columns = [

    {
      field: "reportId",
      title: translatedTextsObject.reportName,
      customLookup: () => {
        return fetchCustomLookup(
          `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/report/report-management/hdr/get`,
          {},
          'content',
          'reportName',
          'id',
          (error) => console.error(error)
        )
      },
    },
    {
      field: "partyReportId",
      title: translatedTextsObject.scheduleName,
      customLookup: () => {
        return fetchCustomLookup(
          `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/report/report-scheduler/hdr/get`,
          {},
          'content',
          'scheduleName',
          'id',
          (error) => console.error(error)
        )
      },
      render: (rowData) => rowData.partyReportDTO.scheduleName
    },
    {
      field: "users",
      title: translatedTextsObject.users,
      render: (rowData) => {
        let userList = rowData.userGeneratedReportList;
        return <>
          <Tooltip title={<UserCountTooltip userProfileList={userList}/>} placement="right">
            <Button className={classes.userCount}>{userList.length}</Button>
          </Tooltip>
        </>
      }
    },
    {
      field: "generatedReportName",
      title: translatedTextsObject.report,
      render: (rowData) => {
        let renderData = "";
        if (rowData) {
          renderData = rowData
        }
        return <div className={classes.aHover} onClick={() => onClickDownloadReport(renderData)}>{renderData.generatedReportName}</div>
      }
    },
    {
      field: "createdDate",
      title: translatedTextsObject.createdDate,
      filtering: false,
      sorting: true,
      defaultSort: "desc",
      render: (rowData) => {
        if (rowData && rowData.partyReportDTO != null) {
          console.log(rowData)
          let date = moment(rowData.createdDate).utcOffset(parseInt(rowData.partyReportDTO.timezone)).format();
          return date.replace("T", " ");
        }
      }
    }
  ]
  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              columns={columns}
              fetch={{ url: GeneratedReportApiUrls.GET_CUSTOMER }}
              idAccessor="id"
              notification={notification}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default TablePage
