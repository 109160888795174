export const PingSearchType = {
    FREIGHT_BOOKING_REF: 'FREIGHT_BOOKING_REF',
    BL_NUM: 'BL_NUM',
    CONTAINER_NUM: 'CONTAINER_NUM',
    VESSEL_NAME: 'VESSEL_NAME'
}

export const PingSearchTypeCode = {
    FREIGHT_BOOKING_REF: 'BKRF',
    BL_NUM: 'BLNO',
    CONTAINER_NUM: 'CNTR',
    VESSEL_NAME: 'VSLN'
}