import React, { useState, useEffect } from 'react'
import { CardContent, Grid, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'

import { useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import PingKeys from 'src/constants/locale/key/Ping'

function DateFilter({
    clearAllFilterClickedTime,
    onChange,
    title
}) {

    // Translations ------------------------------------------------------------
    const { translate } = useTranslation(Namespace.PING)
    const FilterKeys = PingKeys.PING_MY_MILESTONES.FILTERS;

    const Labels = {
        EVENT_DATE: translate(Namespace.PING, PingKeys.FIELDS.EVENT_DATE),
        ALL: translate(Namespace.PING, FilterKeys.ALL),
        PAST_15_DAYS: translate(Namespace.PING, FilterKeys.PAST_15_DAYS),
        PAST_30_DAYS: translate(Namespace.PING, FilterKeys.PAST_30_DAYS),
        PAST_60_DAYS: translate(Namespace.PING, FilterKeys.PAST_60_DAYS)
    }

    //* ------------------------------------------------------------------------
    const DEFAULT_VALUE = 'ALL'
    const [value, setValue] = useState(DEFAULT_VALUE)

    const handleChange = (event) => {
        setValue(event.target.value)

        if (onChange) {
            onChange(event.target.value)
        }
    }

    // Monitor the Clear All clicked time. When changed, reset the value.
    useEffect(() => {
        setValue(DEFAULT_VALUE)

        if (onChange) {
            onChange(DEFAULT_VALUE)
        }

    }, [clearAllFilterClickedTime])

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <Grid container>
            <Grid item xs={12}>
                <CardContent style={{
                    background: '#8996AF10',
                    padding: '1em',
                    marginTop: '1em',
                    textTransform: 'uppercase',
                    fontWeight: 'bold'
                }}>
                    {title}
                </CardContent>
            </Grid>
            <Grid item xs={12}>
                <CardContent>
                    <RadioGroup value={value} onChange={handleChange}>
                        <FormControlLabel value='ALL' control={<Radio />} label={Labels.ALL} />
                        <FormControlLabel value='DAYS_15' control={<Radio />} label={Labels.PAST_15_DAYS} />
                        <FormControlLabel value='DAYS_30' control={<Radio />} label={Labels.PAST_30_DAYS} />
                        <FormControlLabel value='DAYS_60' control={<Radio />} label={Labels.PAST_60_DAYS} />
                    </RadioGroup>
                </CardContent>
            </Grid>
        </Grid>
        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default DateFilter
