import React from 'react'
import { Typography, Button, Container, Card, CardContent, Grid } from '@material-ui/core'
import fileDownload from 'js-file-download'

import { components, useTranslation, useServices } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import CalistaUiComponentKeys from 'src/constants/locale/key/CalistaUiComponent'

import KebabMenuButton from 'src/components/button/KebabMenuButton'
import UploadButtonPrimary from 'src/components/button/UploadButtonPrimary'

import PingMyMilestoneApiUrls from 'src/apiUrls/ping/PingMyMilestoneApiUrls'
import { UploadStatus } from '../components/common/PingMyMilestone_Constants'
import FileIcon from '../components/common/FileIcon'
import UploadStatusLabel from '../components/common/UploadStatusLabel'

const {
    table: { useDefaultNotification },
} = components

//* ----------------------------------------------------------------------------
//* Dialog Content
//* ----------------------------------------------------------------------------
function DialogContent({
    uploadedFiles = [],
    onUploadMoreButtonClick = (event) => { /* Do nothing */ },
    showNotification
}) {

    const notification = useDefaultNotification(showNotification)
    const { securedSendRequest } = useServices()

    // Translations ------------------------------------------------------------
    const { translate } = useTranslation(Namespace.CALISTA_UI_COMPONENT)

    const Labels = {
        FAILED_TO_DOWNLOAD_FILE: translate(Namespace.CALISTA_UI_COMPONENT, CalistaUiComponentKeys.FAILED_TO_DOWNLOAD_FILE),
        UPLOAD_MORE_FILES: translate(Namespace.CALISTA_UI_COMPONENT, CalistaUiComponentKeys.UPLOAD_MORE_FILES),
        UPLOAD_TEMPLATE: translate(Namespace.CALISTA_UI_COMPONENT, CalistaUiComponentKeys.UPLOAD_TEMPLATE)
    }

    //* ------------------------------------------------------------------------
    function bytesToSize(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    }

    // Download function
    function _downloadFile(filename, uploadId) {
        let url = PingMyMilestoneApiUrls.UPLOAD_DOWNLOAD_DATA_GET
        let data = { id: uploadId }
        let config = { responseType: 'blob' }

        securedSendRequest.execute('POST', url, data,
            (response) => {
                // Success
                console.debug(response)
                fileDownload(response.data, filename)
            },
            (error) => {
                notification.error(Labels.FAILED_TO_DOWNLOAD_FILE)
                console.error(error)
            },
            () => { /* Complete. Do nothing. */ },
            config
        )
    }

    //* return JSX -------------------------------------------------------------
    return (
        //* Start JSX ----------------------------------------------------------
        <>
            <Card style={{ background: '#5E81F405', marginTop: '1rem' }}>
                <CardContent style={{ padding: '0.5rem 1.5rem' }}>
                    {
                        uploadedFiles.map((file, index) => {

                            // Only allow download if the status is SUCCESS/ERROR
                            let uploadId = (file.uploadId)
                            let filename = (file.name)
                            let canDownload = (file.uploadStatus == UploadStatus.SUCCESS || file.uploadStatus == UploadStatus.ERROR)
                            let isDownloadDisabled = (canDownload == false)

                            return (
                                <Grid container style={{
                                    paddingTop: '1rem', paddingBottom: '1rem',
                                    borderTop: ((index > 0) ? '0.05rem solid #8996AF33' : 'inherit')
                                }}>
                                    <Grid item xs={1}><FileIcon /></Grid>

                                    <Grid item xs={8} container>
                                        <Grid item xs={12} style={{ paddingRight: '2em' }}>
                                            <Typography color='textPrimary' style={{
                                                fontWeight: 'bold',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }}>
                                                {file.name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography color='textSecondary'>
                                                {bytesToSize(file.size)}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={2} container alignItems='center'>
                                        <Typography>
                                            <UploadStatusLabel
                                                uploadStatus={file.uploadStatus}
                                                statusMessage={file.statusMessage}
                                            />
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={1} container spacing={1} alignItems='center' justify='flex-end'>
                                        <KebabMenuButton
                                            hasDownload={true}
                                            isDownloadDisabled={isDownloadDisabled}
                                            onDownloadClick={(event) => { _downloadFile(filename, uploadId) }}
                                        />
                                    </Grid>
                                </Grid>
                            )
                        })
                    }
                </CardContent>
            </Card>

            { /* The upload more files */}
            <Container disableGutters={true} style={{ marginTop: '2rem', textAlign: 'center' }}>
                <Typography color='textPrimary' display='inline'>
                    {Labels.UPLOAD_MORE_FILES}
                </Typography>
                <UploadButtonPrimary label={Labels.UPLOAD_TEMPLATE}
                    style={{ marginLeft: '1em' }}
                    onClick={onUploadMoreButtonClick}
                />
            </Container>
        </>
        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

//* ----------------------------------------------------------------------------
//* Dialog Action
//* ----------------------------------------------------------------------------
function DialogAction({
    onCloseClick = (event) => { /* Do nothing */ }
}) {

    // Translations ------------------------------------------------------------
    const { translate } = useTranslation(Namespace.CALISTA_UI_COMPONENT)

    const Labels = {
        CLOSE: translate(Namespace.CALISTA_UI_COMPONENT, CalistaUiComponentKeys.CLOSE)
    }

    //* return JSX -------------------------------------------------------------
    return (
        //* Start JSX ----------------------------------------------------------
        <Button color='primary' style={{ marginRight: '1em' }}
            onClick={onCloseClick}
        >
            {Labels.CLOSE}
        </Button>
        //* End JSX ------------------------------------------------------------
    )
    //* End of function --------------------------------------------------------
}

const UploadResultProperties = Object.freeze({
    DialogContent: DialogContent,
    DialogAction: DialogAction
})

export default UploadResultProperties
