import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Grid, Container, Tooltip } from '@material-ui/core'
import { useServices } from 'cng-web-lib'

import PingSearchApiUrls from 'src/apiUrls/ping/PingSearchApiUrls'

// Font Awesome imports.
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { far as FontAwesomeRegular } from '@fortawesome/pro-regular-svg-icons'
import { library as FontAwesomeLibrary } from '@fortawesome/fontawesome-svg-core'

// Ping components
import { PingSearchType } from '../PingSearch_Constants'
import LeftPanelLoading from './LeftPanelLoading'
import LeftPanelError from './LeftPanelError'

FontAwesomeLibrary.add(FontAwesomeRegular)

function VesselSearchLeftPanel({ searchTime }) {

    const { vesselName } = useParams()
    const { securedSendRequest } = useServices()

    const [responseState, setResponseState] = useState({
        isLoading: true,
        hasError: false,
        responseData: {}
    })

    const [isFavourite, setIsFavourite] = useState(false)

    useEffect(() => {
        let url = PingSearchApiUrls.VESSEL_INFO_SEARCH + '/' + vesselName
        let data = {}

        setResponseState({ ...responseState, isLoading: true })
        setIsFavourite(false)

        securedSendRequest.execute('GET', url, data,
            (_response) => {
                // success
                console.debug(_response)

                setResponseState({
                    isLoading: false,
                    hasError: false,
                    responseData: _response.data
                })

                loadIsFavouriteStarState()
            },
            (error) => {
                // error
                console.error(error)

                setResponseState({
                    isLoading: false,
                    hasError: true,
                    responseData: {}
                })
            },
            () => { /* Completed. Do nothing. */ }
        )

    }, [vesselName, searchTime])

    // call ajax to check is the current search an existing favourite
    function loadIsFavouriteStarState() {
        let searchType = PingSearchType.VESSEL_NAME
        let searchValue = vesselName

        let url = PingSearchApiUrls.EXISTS_SEARCH_FAVOURITE
        let data = { 'searchType': searchType, 'searchValue': searchValue }

        securedSendRequest.execute('POST', url, data,
            (_response) => {
                // success
                console.debug(_response)

                setIsFavourite(_response.data)
            },
            (error) => {
                console.error(error)
            },
            () => { /* Completed. Do nothing. */ }
        )
    }

    const handleStarClick = (event) => {
        let searchType = PingSearchType.VESSEL_NAME
        let searchValue = vesselName

        let url
        let data = { 'searchType': searchType, 'searchValue': searchValue }

        if (isFavourite == false) {
            // Currently is not favourite. Add the favourite
            url = PingSearchApiUrls.ADD_SEARCH_FAVOURITE
            setIsFavourite(true)
        }
        else {
            // Currently is favourite. Delete the favourite.
            url = PingSearchApiUrls.DELETE_SEARCH_FAVOURITE
            setIsFavourite(false)
        }

        securedSendRequest.execute('POST', url, data,
            (_response) => {
                // success
                console.debug(_response)
            },
            (error) => {
                console.error(error)
            },
            () => { /* Completed. Do nothing. */ }
        )
    }

    //* ------------------------------------------------------------------------
    //* Return JSX - Loading...
    //* ------------------------------------------------------------------------
    if (responseState.isLoading == true) {
        return (<LeftPanelLoading />)
    }

    //* ------------------------------------------------------------------------
    //* Return JSX - Error...
    //* ------------------------------------------------------------------------
    if (responseState.hasError == true) {
        return (<LeftPanelError />)
    }

    //* ------------------------------------------------------------------------
    //* Return JSX - Result
    //* ------------------------------------------------------------------------
    let response = responseState.responseData

    return (
        //* Start JSX ----------------------------------------------------------

        <Container>
            <Grid className="bookmark_bar" item xs={12}>
                <span>Vessel name: {response.vesselName}</span>
                {
                    isFavourite == false ?
                        (
                            <Tooltip title="Bookmark this search">
                                <div className="star outline_star" onClick={handleStarClick}>
                                    <FontAwesomeIcon icon={["far", "star"]} />
                                </div>
                            </Tooltip>
                        )
                        :
                        (
                            <Tooltip title="Unbookmark this search">
                                <div className="star solid_star" onClick={handleStarClick}>
                                    <FontAwesomeIcon icon={["fas", "star"]} />
                                </div>
                            </Tooltip>
                        )
                }
            </Grid>

            <Grid className="info_header" item xs={12}>
                <div className="header_icon">
                    <FontAwesomeIcon icon={["far", "ship"]} />
                </div>
                <h2>{response.vesselName}</h2>
            </Grid>
        </Container>

        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default VesselSearchLeftPanel