import React from 'react'
import { Grid } from '@material-ui/core'

import RelatedBookingRow from './RelatedBookingRow'
import LeftPanelNoBookingData from './LeftPanelNoBookingData'

function RelatedBookingList({ bookingList }) {

    //* ------------------------------------------------------------------------
    //* Return JSX - No data...
    //* ------------------------------------------------------------------------
    if (!bookingList) {
        return (<LeftPanelNoBookingData />)
    }

    //* ------------------------------------------------------------------------
    //* Return JSX - Result
    //* ------------------------------------------------------------------------
    return (
        //* Start JSX ----------------------------------------------------------

        <Grid container>
            {
                bookingList.map((booking, index) => {
                    let key = "RelatedBookingRow_" + index;
                    return (
                        <Grid key={key} className="left_panel_row related_booking_row" item xs={12}>
                            <RelatedBookingRow booking={booking} />
                        </Grid>
                    )
                })
            }
        </Grid>

        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default RelatedBookingList