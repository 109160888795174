import React from 'react'
import { Button } from '@material-ui/core'

import { components, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import CalistaUiComponentKeys from 'src/constants/locale/key/CalistaUiComponent'
import PingKeys from 'src/constants/locale/key/Ping'

const {
    dropzone: { CngFileUpload, HelperTextArea },
    table: { useDefaultNotification }
} = components

//* ----------------------------------------------------------------------------
//* Dialog Content
//* ----------------------------------------------------------------------------
function DialogContent({
    onFileSelect = (selectedFiles) => { /* Do nothing */ },
    showNotification
}) {
    const notification = useDefaultNotification(showNotification)

    const FILE_COUNT_LIMIT = 5
    const FILE_SIZE_LIMIT = (10 * 1024 * 1024) // 10MB

    // Translations ------------------------------------------------------------
    const { translate } = useTranslation(Namespace.PING)

    const PingMyMilestoneKeys = PingKeys.PING_MY_MILESTONES

    const Labels = {
        helperText: translate(Namespace.PING, PingMyMilestoneKeys.UPLOAD_MILESTONE_HELPER_TEXT)
    }

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <CngFileUpload
            showFormFields={false}
            accept={['.xls', '.xlsx']}
            maxFiles={FILE_COUNT_LIMIT}
            maxSize={FILE_SIZE_LIMIT}

            onFileSelect={onFileSelect}

            onDropRejected={(errorMessages, rejectedFiles, event) => {
                notification.error(errorMessages)
            }}

            moreActions={[
                { icon: ['fal', 'trash'], name: 'Delete', action: 'remove' }
            ]}

            helpText={Labels.helperText}

            renderHelperText={() => {
                return (
                    <HelperTextArea
                        accept={['.xls', '.xlsx']}
                        maxFiles={FILE_COUNT_LIMIT}
                        maxSize={FILE_SIZE_LIMIT}
                    />
                )
            }}
        />
        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

//* ----------------------------------------------------------------------------
//* Dialog Action
//* ----------------------------------------------------------------------------
function DialogAction({
    onCloseClick = (event) => { /* Do nothing */ },
    uploadDisabled = false,
    onUploadClick = (event) => { /* Do nothing */ }
}) {

    // Translations ------------------------------------------------------------
    const { translate } = useTranslation(Namespace.CALISTA_UI_COMPONENT)

    const Labels = {
        CLOSE: translate(Namespace.CALISTA_UI_COMPONENT, CalistaUiComponentKeys.CLOSE),
        UPLOAD_AND_VALIDATE: translate(Namespace.CALISTA_UI_COMPONENT, CalistaUiComponentKeys.UPLOAD_AND_VALIDATE)
    }

    //* return JSX -------------------------------------------------------------
    return (
        //* Start JSX ----------------------------------------------------------
        <>
            <Button color='primary' style={{ marginRight: '1em' }}
                onClick={onCloseClick}
            >
                {Labels.CLOSE}
            </Button>

            <Button variant='contained' color='primary'
                onClick={onUploadClick} disabled={uploadDisabled}
            >
                {Labels.UPLOAD_AND_VALIDATE}
            </Button>
        </>
        //* End JSX ------------------------------------------------------------
    )
    //* End of function --------------------------------------------------------
}

const UploadFormProperties = Object.freeze({
    DialogContent: DialogContent,
    DialogAction: DialogAction
})

export default UploadFormProperties
