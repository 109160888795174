import React, {useEffect, useState} from 'react'
import {components, useServices} from 'cng-web-lib'
import ReportDownloadApiUrls from "../../../apiUrls/ReportDownloadApiUrls";

const {
    card: {
        CngSimpleCardHeader,
    },
    form: {
        adapter: {
            useFormAdapter:{ useField, useFormikContext }
        },
        field: {
            CngTextField,
            CngSelectField,
            CngDateField,
            CngDateTimeField,
            CngCodeMasterAutocompleteField
        },
    }
} = components

export default function CarrierListField(props) {

    const {fetchRecords} = useServices();
    const [carriers, setCarriers] = useState([]);

    useEffect( () => {

        const onSuccess = (data) => {
            setCarriers(data)
        }

        const onError = (error) => {
            console.log("Error:[" + JSON.stringify(error) + "]")
        }

        const onComplete = () => {
            console.log("Finish retrieving report parameters")
        }

        fetchRecords.execute(
            ReportDownloadApiUrls.GET_PARTY_LIST_BY_USER_GROUP_CODE,
            { filters: [{ field: 'userGroupCode', operator: 'EQUAL', value: 'CAR' }] },
            onSuccess,
            onError,
            onComplete
        );

    },[]);

    return (
        <CngSelectField
            name={props.response.parameterName}
            label={props.response.parameterDisplayNameEn}
            disabled={false}
            multiple
            defaultValue={[]}
            items = {carriers}
        />
    );
}