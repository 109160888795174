import React, { useState, useEffect, useRef } from 'react'
import { Grid, Paper, CircularProgress, MenuItem, Select } from '@material-ui/core'
import { useServices } from 'cng-web-lib'

import BookingRow from './BookingRow'
import PingSearchApiUrls from 'src/apiUrls/ping/PingSearchApiUrls'
import RightPanelLoading from './RightPanelLoading'
import RightPanelError from './RightPanelError'
import LoadMoreObserver from 'src/components/misc/LoadMoreObserver'

function BookingList({ pageLoadTimestamp, ...props }) {

    const { securedSendRequest } = useServices()

    //* ------------------------------------------------------------------------
    //* Search related states and effects.
    //* ------------------------------------------------------------------------
    const [initialLoadingState, setInitialLoadingState] = useState({
        isInitialLoading: true,
        isInitialLoadingError: false
    })

    const [loadingMoreState, setLoadingMoreState] = useState({
        isLoadingMore: false,
        isLoadingMoreError: false,
        isNoMoreData: false
    })

    const [sortType, setSortType] = useState('MOST_RECENT');

    const MAX_TIMESTAMP = new Date().getTime()
    const searchTimestamp = useRef(MAX_TIMESTAMP)
    const bookingData = useRef([])

    const [totalResultCount, setTotalResultCount] = useState(false)

    function processResponse(response) {

        let newBookingData = [...bookingData.current, ...response]
        bookingData.current = newBookingData

        // loop and update the maxTimestamp
        let nextSearchMaxTimestamp = searchTimestamp.current

        switch (sortType) {
            case "MOST_RECENT": {
                newBookingData.forEach((item, index) => {
                    nextSearchMaxTimestamp = Math.min(item.createdDate)
                })
                break
            }
            case "UPCOMING": {
                newBookingData.forEach((item, index) => {
                    nextSearchMaxTimestamp = Math.min(item.polEtdTimestamp)
                })
                break
            }
        }

        searchTimestamp.current = nextSearchMaxTimestamp
    }

    function callBookingAjax({ onBeforeStart, onSuccess, onError, onComplete }) {

        if (onBeforeStart) { onBeforeStart() }

        let url = PingSearchApiUrls.GET_BOOKING_LIST
        let data = {
            sortType: sortType,
            maxTimestamp: searchTimestamp.current
        }

        securedSendRequest.execute("POST", url, data,
            (response) => {
                console.debug(response)
                if (onSuccess) { onSuccess(response) }
            },
            (error) => {
                console.error(error)
                if (onError) { onError(error) }
            },
            () => { if (onComplete) { onComplete() } }
        )
    }

    function refreshBookingCount() {
        let url = PingSearchApiUrls.GET_BOOKING_COUNT
        let data = {}

        securedSendRequest.execute("POST", url, data,
            (response) => {
                console.debug(response)
                setTotalResultCount(Number(response.data))
            },
            (error) => {
                console.error(error)
            },
            () => { /* Complete */ }
        )
    }

    // Initial load effect. Will also be triggered by the sort option onChange.
    useEffect(() => {
        callBookingAjax({
            onBeforeStart: () => {
                // reset the ref data
                searchTimestamp.current = MAX_TIMESTAMP
                bookingData.current = []

                // reset the load more data
                setLoadingMoreState({
                    isLoadingMore: false,
                    isLoadingMoreError: false,
                    isNoMoreData: false
                })

                setInitialLoadingState({ ...initialLoadingState, isInitialLoading: true })
            },
            onSuccess: (response) => {
                console.debug(response)

                processResponse(response.data)
                setInitialLoadingState({ ...initialLoadingState, isInitialLoading: false })
            },
            onError: (error) => {
                console.error(error)

                setInitialLoadingState({
                    ...initialLoadingState,
                    isInitialLoading: false,
                    isInitialLoadingError: true
                })
            },
            onComplete: () => { /* Do nothing */ }
        })
    }, [pageLoadTimestamp, sortType])

    // Initial page load to query the result count.
    useEffect(() => {
        refreshBookingCount()
    }, [])

    // the infinite scroll
    const handleInfiniteScroll = (entries, observer) => {

        if (initialLoadingState.isInitialLoading) {
            // do nothing. it is still initial loading.
            return;
        }

        if (loadingMoreState.isLoadingMore) {
            // Is still loading more.
            return;
        }

        if (loadingMoreState.isNoMoreData) {
            // do nothing also. There is no more data to load.
            return;
        }

        callBookingAjax({
            onBeforeStart: () => {
                setLoadingMoreState({ ...loadingMoreState, isLoadingMore: true })
            },
            onSuccess: (response) => {
                console.debug(response)

                if ((response) && (response.data) && (response.data.length)) {
                    // has data.
                    processResponse(response.data)
                    setLoadingMoreState({ ...loadingMoreState, isLoadingMore: false })
                }
                else {
                    // no more data.
                    setLoadingMoreState({
                        ...loadingMoreState,
                        isLoadingMore: false,
                        isNoMoreData: true
                    })
                }

            },
            onError: (error) => {
                console.error(error)

                setLoadingMoreState({
                    ...loadingMoreState,
                    isLoadingMore: false,
                    isLoadingMoreError: true
                })
            },
            onComplete: () => { /* Do nothing */ }
        })
    }

    // the change handler for the sort type dropdown
    const handleSortTypeChange = (event) => {

        // reset the max search timestamp again
        searchTimestamp.current = MAX_TIMESTAMP

        // change the sort type state to trigger a refresh of UI
        setSortType(event.target.value)
    }

    //* ------------------------------------------------------------------------
    //* Return JSX - Loading...
    //* ------------------------------------------------------------------------
    if (initialLoadingState.isInitialLoading == true) {
        return (<RightPanelLoading />)
    }

    //* ------------------------------------------------------------------------
    //* Return JSX - Error...
    //* ------------------------------------------------------------------------
    if (initialLoadingState.isInitialLoadingError == true) {
        return (<RightPanelError />)
    }

    //* ------------------------------------------------------------------------
    //* Return JSX - Result
    //* ------------------------------------------------------------------------
    return (
        //* Start JSX ----------------------------------------------------------

        <Paper>
            <Grid id="right_panel_top" container>
                <Grid id="right_panel_title" item xs={12}>
                    <h3>Bookings list</h3>
                </Grid>
                <Grid id="result_count" item xs={6}>
                    <span>Showing {bookingData.current.length} out of {totalResultCount || <CircularProgress size='1em' />} results</span>
                </Grid>
                <Grid id="sort_form" item xs={6}>
                    <span>SORT BY:</span>
                    <Select id="sort_field" disableUnderline={true}
                        value={sortType}
                        onChange={handleSortTypeChange}
                    >
                        <MenuItem value="MOST_RECENT">Most recent</MenuItem>
                        <MenuItem value="UPCOMING">Upcoming</MenuItem>
                    </Select>
                </Grid>
            </Grid>
            <Grid id="right_panel_result" container direction="column">
                {
                    bookingData.current.map((booking, index) => {
                        let key = "BookingRow_" + index;

                        return (
                            <BookingRow
                                key={key}
                                booking={booking}
                            />
                        )
                    })
                }
                <Grid className="load_more" item xs={12}>
                    {(
                        () => { // use annon function to return if-else condition.
                            if (loadingMoreState.isNoMoreData) {
                                return (<span>No more data to load.</span>)
                            }
                            else if (loadingMoreState.isLoadingMoreError) {
                                return (<span>Error loading more data.</span>)
                            }
                            else {
                                return (
                                    <LoadMoreObserver onInView={handleInfiniteScroll}>
                                        {
                                            loadingMoreState.isLoadingMore && // show spinner only when isLoadingMore
                                            <CircularProgress />
                                        }
                                    </LoadMoreObserver>
                                )
                            }
                        }
                    )()}
                </Grid>
            </Grid>
        </Paper>

        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default BookingList