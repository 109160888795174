import React, {useEffect, useState} from 'react'
import Namespace from 'src/constants/locale/Namespace'
import {Button, Card, CardContent, Grid} from '@material-ui/core'
import ReportSchedulerKeys from 'src/constants/locale/key/ReportSchedulerKeys';
import {components, constants, DataFlattener, useServices, useTranslation} from 'cng-web-lib'
import SelectComponent from './SelectComponent'
import CommonUtils from 'src/views/common/utils/Utils';
import moment from 'moment';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {useFormContext} from 'react-hook-form';
import DynamicFieldsHolder from "./DynamicFieldsHolder";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    btnCustom: {
        bottom: "-3.3rem",
        left: "6rem",
        zIndex: "10",
        width: "99px",
        height: "40px",
        position: "absolute",
        textTransform: 'capitalize'
    }
}));

const {
    card: {
        CngSimpleCardHeader,
    },
    form: {
        field: {
            CngTextField,
            CngSelectField,
            CngDateField,
            CngServerSideAutocompleteField,
            CngSwitchField
        },
    },
    table: {
        useDefaultNotification
    },
    CngGridItem,
} = components

const AsteriskRedCngServerSideAutocompleteField = withStyles({
    root: {
        '& .MuiFormLabel-asterisk.MuiInputLabel-asterisk': {
            color: 'red'
        }
    }
})(CngServerSideAutocompleteField)

const {
    CodeMaintenanceType,
    EQUAL
} = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
    id: 0,
    title: "",
    partyId: 0,
    reportId: "",
    scheduleName: "",
    frequency: "",
    scheduledStartDate: "",
    scheduledEndDate: "",
    dynamicParameterList: [],
    userPartyReportList: [],
    reportStartDate: "",
    reportEndDate: "",
    scheduledStartTime: "",
    timezone: "",
    blankReport: false
})

export const frequencyOptions = [
    { text: "HOURLY", value: "HOUR" },
    { text: "DAILY", value: "DAY" },
    { text: "WEEKLY", value: "WEK" },
    { text: "MONTHLY", value: "MTH" },
    { text: "QUARTERLY", value: "QTR" },
]

export const frequencyDataOptions = [
    { text: "Today (current time)", value: "NOW" },
    { text: "Start of Today (00:00hrs)", value: "SOD" },
    { text: "Today -1 day", value: "D1" },
    { text: "Today -7 days", value: "D7" },
    { text: "Today -14 days", value: "D14" },
    { text: "Today -1 month", value: "M1" },
    { text: "Today -3 months", value: "M3" },
    { text: "Today -1 year", value: "Y1" },
    { text: "Monday of Current Week", value: "SOW" },
    { text: "Day 1 of Current Month", value: "SOM" },
    { text: "Day 1 of Current Quarter", value: "SOQ" },
    { text: "Day 1 of Current Year", value: "SOY" }
]

export const hourlyOptions = [
    { text: "00:55", value: "0"},
    { text: "01:55", value: "1"},
    { text: "02:55", value: "2"},
    { text: "03:55", value: "3"},
    { text: "04:55", value: "4"},
    { text: "05:55", value: "5"},
    { text: "06:55", value: "6"},
    { text: "07:55", value: "7"},
    { text: "08:55", value: "8"},
    { text: "09:55", value: "9"},
    { text: "10:55", value: "10"},
    { text: "11:55", value: "11"},
    { text: "12:55", value: "12"},
    { text: "13:55", value: "13"},
    { text: "14:55", value: "14"},
    { text: "15:55", value: "15"},
    { text: "16:55", value: "16"},
    { text: "17:55", value: "17"},
    { text: "18:55", value: "18"},
    { text: "19:55", value: "19"},
    { text: "20:55", value: "20"},
    { text: "21:55", value: "21"},
    { text: "22:55", value: "22"},
    { text: "23:55", value: "23"}
]

export const timezoneOptions = [
    { text: "GMT-12", value: "-12" },
    { text: "GMT-11", value: "-11" },
    { text: "GMT-10", value: "-10" },
    { text: "GMT-9", value: "-9" },
    { text: "GMT-8", value: "-8" },
    { text: "GMT-7", value: "-7" },
    { text: "GMT-6", value: "-6" },
    { text: "GMT-5", value: "-5" },
    { text: "GMT-4", value: "-4" },
    { text: "GMT-3", value: "-3" },
    { text: "GMT-2", value: "-2" },
    { text: "GMT-1", value: "-1" },
    { text: "GMT+0", value: "0" },
    { text: "GMT+1", value: "1" },
    { text: "GMT+2", value: "2" },
    { text: "GMT+3", value: "3" },
    { text: "GMT+4", value: "4" },
    { text: "GMT+5", value: "5" },
    { text: "GMT+6", value: "6" },
    { text: "GMT+7", value: "7" },
    { text: "GMT+8", value: "8" },
    { text: "GMT+9", value: "9" },
    { text: "GMT+10", value: "10" },
    { text: "GMT+11", value: "11" },
    { text: "GMT+12", value: "12" },
]

// Date Time
const SG_DATE_FORMAT = CommonUtils.FORMAT_DATE_SERVER_DATE

function Fields({ disabled, showNotification, shouldHideMap, fetchData }) {
    const { getValues } = useFormContext()
    const classes = useStyles();
    const { translate } = useTranslation(Namespace.REPORT_MANAGEMENT);
    const translatedTextsObject = makeTranslatedTextsObject();
    const { fetchRecords } = useServices();
    const [usersOptions, setUsersOptions] = useState([]);
    const [isDisabledTB, setIsDisabledTB] = useState(false);
    const [partyId, setPartyId] = useState(fetchData?.partyId)
    const [dynamicFieldsKey, setDynamicFieldsKey] = useState(1)

    const {
        success: showSuccessNotification,
        error: showErrorNotification
    } = useDefaultNotification(showNotification);

    function makeTranslatedTextsObject() {

        let title = translate(
            Namespace.REPORT_SCHEDULER,
            ReportSchedulerKeys.TITLE
        )

        let partyId = translate(
            Namespace.REPORT_SCHEDULER,
            ReportSchedulerKeys.PARTY
        )
        let reportId = translate(
            Namespace.REPORT_SCHEDULER,
            ReportSchedulerKeys.REPORT_NAME
        )
        let scheduleName = translate(
            Namespace.REPORT_SCHEDULER,
            ReportSchedulerKeys.SCHEDULE_NAME
        )
        let frequency = translate(
            Namespace.REPORT_SCHEDULER,
            ReportSchedulerKeys.FREQUENCY
        )
        let userPartyReportList = translate(
            Namespace.REPORT_SCHEDULER,
            ReportSchedulerKeys.USERS
        )
        let scheduledStartDate = translate(
            Namespace.REPORT_SCHEDULER,
            ReportSchedulerKeys.SCHEDULED_START_DATE
        )
        let scheduledEndDate = translate(
            Namespace.REPORT_SCHEDULER,
            ReportSchedulerKeys.SCHEDULED_END_DATE
        )
        let scheduledStartTime = translate(
            Namespace.REPORT_SCHEDULER,
            ReportSchedulerKeys.SCHEDULED_START_TIME
        )
        let timezone = translate(
          Namespace.REPORT_SCHEDULER,
          ReportSchedulerKeys.TIMEZONE
        )
        let blankReportGenerate = translate(
          Namespace.REPORT_SCHEDULER,
          ReportSchedulerKeys.BLANK_REPORT
        )

        return {
            title,
            partyId,
            reportId,
            scheduleName,
            frequency,
            userPartyReportList,
            scheduledStartDate,
            scheduledEndDate,
            scheduledStartTime,
            timezone,
            blankReportGenerate
        }
    }

    const onSelectReportName = (ev) => {
        setDynamicFieldsKey(dynamicFieldsKey + 1)
    }

    useEffect(() => {
        if(fetchData?.partyId) {
            getUsersInTheParty(fetchData.partyId)
        }
    }, [fetchData])

    useEffect(() => {
        console.log("Party id in state:", partyId)
        if(fetchData) {
            getUsersInTheParty(fetchData?.partyId)
        }
    }, [])

    const getUsersInTheParty = (partyId) => {
        const onSuccess = (response) => {
            let options = []
            options = response.content.map((record) => ({
                text: record.userProfile.loginId,
                value: record.id
            }));
            setUsersOptions(options);
        }

        const onError = (error) => { console.log("Error:[" + JSON.stringify(error) + "]") }

        const onComplete = () => { console.log("On Complete") }

        fetchRecords.execute(
            `${process.env.REACT_APP_USER_ORIGIN_URL}/tpr-auth/user-detail/get`,
            {
                "customData": {
                    "baseFilterDTO": {
                        "filterType": "AND",
                        "filterProperties": [
                            {
                                "fieldName": "partyId",
                                "operatorType": "EQUAL",
                                "value1": partyId
                            }
                        ],
                        "sortProperties": []
                    }
                }
            },
            onSuccess,
            onError,
            onComplete
        )
    }

    function base64toBlob(base64Data) {
        let sliceSize = 1024;
        let byteCharacters = atob(base64Data);
        let bytesLength = byteCharacters.length;
        let slicesCount = Math.ceil(bytesLength / sliceSize);
        let byteArrays = new Array(slicesCount);

        for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            let begin = sliceIndex * sliceSize;
            let end = Math.min(begin + sliceSize, bytesLength);

            let bytes = new Array(end - begin);
            for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        return byteArrays;
    }

    function downloadExcel(byteArrays, filename, contentTypeHeader) {
        let blob = new Blob(byteArrays, { type: contentTypeHeader });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();
    }

    const triggerSampleReportDownload = () => {
        setIsDisabledTB(true);
        const onSuccess = (response) => {
            setIsDisabledTB(false);
            showSuccessNotification("Downloaded Successfully");
            let contentType = 'application/vnd.ms-excel';
            let byteArrays = base64toBlob(response.base64Data);
            downloadExcel(byteArrays, response.fileName, contentType);
        }

        const onError = (error) => { console.log("Error:[" + JSON.stringify(error) + "]") }

        const onComplete = () => { console.log("On Complete") }

        fetchRecords.execute(
            `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/report/generated-report/hdr/trigger-sample-report`,
            {
                "customData": toServerDataFormat(getValues())
            },
            onSuccess,
            onError,
            onComplete
        )

    }

    return (
        <Grid container spacing={3} style={{ position: "relative" }}>
            <Grid item xs={12}>
                <Card>
                    <CngSimpleCardHeader title={translatedTextsObject.title} />
                    <CardContent>
                        <Grid container spacing={3}>
                            <CngGridItem xs={12} sm={6} shouldHide={false}>

                                <AsteriskRedCngServerSideAutocompleteField
                                    key={`partyIdKey`}
                                    name={`partyId`}
                                    label={translatedTextsObject.partyId + ' *'}
                                    lookupProps={{
                                        initFetch: true
                                    }}
                                    minFilterChars={1}
                                    searchableFields={['name']}
                                    lookupPageableProps={{
                                        label: (record) => {
                                            return `${record.name}`
                                        },
                                        value: 'id',
                                        url: `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get`,
                                        pageSize: 10
                                    }}
                                    onChange={(optionValue, option) => {
                                        if (option) {
                                            const lookupObject = option.data
                                            setPartyId(lookupObject.id)
                                            getUsersInTheParty(lookupObject.id)
                                        }
                                    }}
                                    renderOption={(option) => {
                                        return (
                                            <>{option.data.name}</>
                                        )
                                    }}
                                />

                            </CngGridItem>

                            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.blankReportGenerate}>
                                <CngSwitchField
                                  name="blankReportGenerate"
                                  label={translatedTextsObject.blankReportGenerate}
                                  disabled={disabled}
                                />
                            </CngGridItem>

                            <CngGridItem xs={12} sm={6} shouldHide={false}>
                                <CngSelectField
                                    name="reportId"
                                    label={translatedTextsObject.reportId + ' *'}
                                    disabled={false}
                                    fetch={
                                        {
                                            url: `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/report/report-management/hdr/get`,
                                            textAccessor: "reportName",
                                            valueAccessor: "id"
                                        }
                                    }
                                    onClick={onSelectReportName}
                                />
                            </CngGridItem>

                            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.userPartyReportList}>
                                <SelectComponent
                                    name="userPartyReportList"
                                    label={translatedTextsObject.userPartyReportList}
                                    options={usersOptions}
                                    value={fetchData?.userPartyReportList}
                                />
                            </CngGridItem>

                            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.scheduleName}>
                                <CngTextField
                                    name="scheduleName"
                                    label={translatedTextsObject.scheduleName + ' *'}
                                />
                            </CngGridItem>

                            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.frequency}>
                                <CngSelectField
                                    name="frequency"
                                    label={translatedTextsObject.frequency + ' *'}
                                    items={frequencyOptions}
                                    disabled={disabled}
                                />
                            </CngGridItem>

                            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.scheduledStartTime}>
                                <CngSelectField
                                  name="scheduledStartTime"
                                  label={translatedTextsObject.scheduledStartTime + ' *'}
                                  items={hourlyOptions}
                                  disabled={disabled}
                                />
                            </CngGridItem>

                            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.timezone}>
                                <CngSelectField
                                  name="timezone"
                                  label={translatedTextsObject.timezone + ' *'}
                                  items={timezoneOptions}
                                  disabled={disabled}
                                />
                            </CngGridItem>

                            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.scheduledStartDate}>
                                <CngDateField
                                    label={translatedTextsObject.scheduledStartDate + ' *'}
                                    name='scheduledStartDate'
                                    disabled={disabled}
                                    format={"YYYY-MM-DD"}
                                    shouldDisableDate={(date) => {
                                        return moment(moment().format(SG_DATE_FORMAT)).isAfter(
                                            moment(date)
                                        )
                                    }}
                                />
                            </CngGridItem>

                            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.scheduledEndDate}>
                                <CngDateField
                                    label={translatedTextsObject.scheduledEndDate}
                                    name='scheduledEndDate'
                                    disabled={disabled}
                                    format="YYYY-MM-DD"
                                    shouldDisableDate={(date) => {
                                        return moment(moment().format(SG_DATE_FORMAT)).isAfter(
                                            moment(date)
                                        )
                                    }}
                                />
                            </CngGridItem>

                            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.reportStartDate}>
                                <CngSelectField
                                    name="reportStartDate"
                                    label="Report Start Date"
                                    items={frequencyDataOptions}
                                    disabled={false}
                                />
                            </CngGridItem>

                            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.reportEndDate}>
                                <CngSelectField
                                    name="reportEndDate"
                                    label="Report End Date"
                                    items={frequencyDataOptions}
                                    disabled={false}
                                />
                            </CngGridItem>

                            {
                                <DynamicFieldsHolder key={dynamicFieldsKey} reportSchedulerData={fetchData} reportId={getValues("reportId")}/>
                            }

                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Button variant="contained" disabled={isDisabledTB} color="primary" onClick={triggerSampleReportDownload} className={classes.btnCustom}>Trigger</Button>
        </Grid>
    )
}

const avoidFields = ["param_from_date", "param_to_date"]
function toClientDataFormat(serverData) {
    const parseData = DataFlattener.parse(serverData);
    const dynamicParam = parseData.dynamicParameterList
    dynamicParam.forEach( t => {
        if(avoidFields.includes(t.paramKey)) {
            // Values are taken from report scheduler main from "from_date" and "to_date"
        } else {
            let dynamicParamValue = t.paramValue;
            if(dynamicParamValue.includes(",") || t.paramKey.includes("multi_select") ) {
                // For multi-selects, initial values need to de-structure
                parseData[t.paramKey] = dynamicParamValue.split(',')
            } else {
                parseData[t.paramKey] = dynamicParamValue
            }
        }
    })

    return parseData
}

const reportSchedulerBaseFields = ["id", "title", "partyId", "reportId", "scheduleName", "frequency", "scheduledStartDate", "scheduledEndDate", "dynamicParameterList", "userPartyReportList", "reportStartDate", "reportEndDate", "scheduledStartTime", "timezone", "blankReportGenerate"]
function toServerDataFormat(localData) {
    const formData = DataFlattener.unflatten(localData)
    console.log("Report scheduler formDate:", formData)
    let dynamicParameterList = []
    let updatedFormDate = {}
    Object.keys(formData).forEach(( key, index) => {
        if(reportSchedulerBaseFields.includes(key)) {
            updatedFormDate[key] = formData[key]
        } else {
            dynamicParameterList.push({"paramKey": key, "paramValue": getParamValue(formData[key]) })
        }
    })
    updatedFormDate["dynamicParameterList"] = dynamicParameterList
    return updatedFormDate;
}

function getParamValue(paramValue) {
    if(Array.isArray(paramValue)){
        paramValue = paramValue.toString()
    }
    return paramValue;
}

const FormScheduler = Object.freeze({
    Fields: Fields,
    toClientDataFormat: toClientDataFormat,
    toServerDataFormat: toServerDataFormat
})

export default FormScheduler
