import React from 'react'
import { BasePage as CngBasePage, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import PingKeys from 'src/constants/locale/key/Ping'
import BreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_Ping'
import ListPage from './list-page/PingMyMilestone_ListPage'
import AddPage from './form-page/PingMyMilestone_AddPage'
import AddSuccessPage from './form-page/PingMyMilestone_AddSuccessPage'
import ViewPage from './form-page/PingMyMilestone_ViewPage'
import EditPage from './form-page/PingMyMilestone_EditPage'
import EditSuccessPage from './form-page/PingMyMilestone_EditSuccessPage'
import UploadListPage from './list-page/PingMyMilestone_UploadListPage'

//* Module Title ---------------------------------------------------------------
function ModuleTitle() {
	const { translate } = useTranslation(Namespace.PING)
	let title = translate(Namespace.PING, PingKeys.MODULE_TITLE)

	return (title);
}

//* Wrapped List Page ---------------------------------------------------------
function WrappedListPage(props) {
	const breadcrumbNameMap = BreadcrumbNameMap()
	return (
		<CngBasePage renderPage={(showSnackbar) => (
			<ListPage showNotification={showSnackbar} {...props} />
		)}
			extraCngBreadcrumbNameMap={breadcrumbNameMap}
			moduleTitle={ModuleTitle()}
		/>
	)
}

//* Wrapped Add Page ---------------------------------------------------------
function WrappedAddPage(props) {
	const breadcrumbNameMap = BreadcrumbNameMap()
	return (
		<CngBasePage renderPage={(showSnackbar) => (
			<AddPage showNotification={showSnackbar} {...props} />
		)}
			extraCngBreadcrumbNameMap={breadcrumbNameMap}
			moduleTitle={ModuleTitle()}
		/>
	)
}

//* Wrapped Add Success Page ---------------------------------------------------
function WrappedAddSuccessPage(props) {
	const breadcrumbNameMap = BreadcrumbNameMap()
	return (
		<CngBasePage renderPage={(showSnackbar) => (
			<AddSuccessPage showNotification={showSnackbar} {...props} />
		)}
			extraCngBreadcrumbNameMap={breadcrumbNameMap}
			moduleTitle={ModuleTitle()}
		/>
	)
}

//* Wrapped Add Page ---------------------------------------------------------
function WrappedEditPage(props) {
	const breadcrumbNameMap = BreadcrumbNameMap()
	return (
		<CngBasePage renderPage={(showSnackbar) => (
			<EditPage showNotification={showSnackbar} {...props} />
		)}
			extraCngBreadcrumbNameMap={breadcrumbNameMap}
			moduleTitle={ModuleTitle()}
		/>
	)
}

//* Wrapped Add Success Page ---------------------------------------------------
function WrappedEditSuccessPage(props) {
	const breadcrumbNameMap = BreadcrumbNameMap()
	return (
		<CngBasePage renderPage={(showSnackbar) => (
			<EditSuccessPage showNotification={showSnackbar} {...props} />
		)}
			extraCngBreadcrumbNameMap={breadcrumbNameMap}
			moduleTitle={ModuleTitle()}
		/>
	)
}

//* Wrapped View Page ----------------------------------------------------------
function WrappedViewPage(props) {
	const breadcrumbNameMap = BreadcrumbNameMap()
	return (
		<CngBasePage renderPage={(showSnackbar) => (
			<ViewPage showNotification={showSnackbar} {...props} />
		)}
			extraCngBreadcrumbNameMap={breadcrumbNameMap}
			moduleTitle={ModuleTitle()}
		/>
	)
}

//* Wrapped Upload List Page ---------------------------------------------------
function WrappedUploadListPage(props) {
	const breadcrumbNameMap = BreadcrumbNameMap()
	return (
		<CngBasePage renderPage={(showSnackbar) => (
			<UploadListPage showNotification={showSnackbar} {...props} />
		)}
			extraCngBreadcrumbNameMap={breadcrumbNameMap}
			moduleTitle={ModuleTitle()}
		/>
	)
}

//* Export ---------------------------------------------------------------------
export {
	WrappedListPage as ListPage,
	WrappedAddPage as AddPage,
	WrappedAddSuccessPage as AddSuccessPage,
	WrappedEditPage as EditPage,
	WrappedEditSuccessPage as EditSuccessPage,
	WrappedViewPage as ViewPage,
	WrappedUploadListPage as UploadListPage
}