import React from 'react'
import { Paper } from '@material-ui/core'

function RightPanelNoData({ title = "" }) {

    function PING_STATIC_IMAGE(filename) {
        const PING_STATIC_IMAGE_DIR = `${process.env.PUBLIC_URL}/static/images/ping/`
        return (PING_STATIC_IMAGE_DIR + filename)
    }

    //* return JSX -------------------------------------------------------------
    return (
        //* Start JSX ----------------------------------------------------------

        <Paper className="no_data">
            <h3>{title}</h3>
            <img src={PING_STATIC_IMAGE("right_panel_no_data.svg")} />
            <label>Nothing here yet</label>
        </Paper>

        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default RightPanelNoData