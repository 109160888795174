import React from 'react'
import { CircularProgress, Paper } from '@material-ui/core'

function RightPanelLoading(props) {

    //* return JSX -------------------------------------------------------------
    return (
        //* Start JSX ----------------------------------------------------------

        <Paper className="loading">
            <CircularProgress />
            <label>Loading...</label>
        </Paper>

        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default RightPanelLoading