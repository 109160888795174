import { Container, Grid, Paper } from '@material-ui/core'

import ImporterFormProperties from './ImporterFormProperties'
import React from 'react'
import RiceStockApiUrls from 'src/apiUrls/RiceStockApiUrls'
import { components } from 'cng-web-lib'
import { useParams } from 'react-router-dom'

const {
  form: { CngViewForm }
} = components

function ViewPage({ showNotification }) {
  const { id } = useParams()

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <Container maxWidth='xl'>
            <CngViewForm
              fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
              showNotification={showNotification}
              bodySection={
                <ImporterFormProperties.Fields
                  disabled={true}
                  showNotification={showNotification}
                />
              }
              formikProps={ImporterFormProperties.formikProps}
              toClientDataFormat={ImporterFormProperties.toClientDataFormat}
              toServerDataFormat={ImporterFormProperties.toServerDataFormat}
              fetch={{
                id: id,
                url: RiceStockApiUrls.IMPORTER_GET
              }}
            />
          </Container>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default ViewPage
