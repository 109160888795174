import React, { useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { Card, CardContent, CircularProgress, Grid, Divider, Typography } from '@material-ui/core'

import { components, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import PingKeys from 'src/constants/locale/key/Ping'

import FormProperties from './PingMyMilestone_ViewFormProperties'
import PingMyMilestoneApiUrls from 'src/apiUrls/ping/PingMyMilestoneApiUrls'
import PingPathMap from 'src/paths/PathMap_Ping'
import BackButton from 'src/components/button/BackButton'
import EditButton2 from 'src/components/button/EditButton2'
import DeleteButton2 from 'src/components/button/DeleteButton2'
import PingMyMilestoneDeleteDialog from '../dialog-page/PingMyMilestoneDeleteDialog'

// FontAwesome imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { far as FontAwesomeRegular } from '@fortawesome/pro-regular-svg-icons'
import { library as FontAwesomeLibrary } from '@fortawesome/fontawesome-svg-core'

// Moment library
import Moment from 'moment';
import 'moment-timezone';

FontAwesomeLibrary.add(FontAwesomeRegular)

const {
    form: { CngViewForm },
    table: { useDefaultNotification }
} = components

function AddSuccessPage({ history, showNotification }) {

    const { id } = useParams()
    const notification = useDefaultNotification(showNotification)

    const [fetchData, setFetchData] = useState(false)

    const DATE_DISPLAY_FORMAT = 'dddd, D MMM YYYY [at] HH:mm [GMT] Z'
    function _formatDate(datetime) {

        let moment = Moment(datetime)
        let str = moment.format(DATE_DISPLAY_FORMAT)
        return (str)
    }

    // Translations ------------------------------------------------------------
    const { translate } = useTranslation(Namespace.PING)

    const PingMyMilestoneKeys = PingKeys.PING_MY_MILESTONES
    const Labels = {
        MILESTONE_DELETED: translate(Namespace.PING, PingMyMilestoneKeys.MILESTONE_DELETED),
        BACK_TO_MILESTONES: translate(Namespace.PING, PingMyMilestoneKeys.BACK_TO_MILESTONES)
    }

    //* ------------------------------------------------------------------------
    //* Delete Dialog related stuffs
    //* ------------------------------------------------------------------------
    const deleteDialogRef = useRef()

    const deleteClickHandler = (event) => {
        deleteDialogRef.current.openDialog(id)
    }

    const deleteDialogSuccessHandler = (myMilestoneId) => {
        notification.success(Labels.MILESTONE_DELETED)
        history.push(PingPathMap.PING_MY_MILESTONES_LIST_VIEW)
    }

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <>
            <Card>
                <CardContent>
                    <h3>
                        Milestone created successfully
                        <FontAwesomeIcon icon={['far', 'check-circle']} style={{ color: '#7CE7AC', marginLeft: '1em' }} />
                    </h3>

                    <Typography
                        component='sub' color='textSecondary'
                        style={{ fontSize: 'small', display: 'block', marginTop: '2em', marginBottom: '1.5em' }}
                    >
                        Created on &nbsp;
                        {
                            fetchData ?
                                _formatDate(fetchData.createdDate)
                                : <CircularProgress size='1em' />
                        }
                        &nbsp; by &nbsp;
                        {
                            fetchData ? fetchData.createdByName
                                : <CircularProgress size='1em' />
                        }
                    </Typography>

                    <CngViewForm
                        fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                        history={history}
                        showNotification={showNotification}
                        bodySection={
                            <FormProperties.Fields
                                disabled={true}
                                showNotification={showNotification}
                                id={id}
                                fetchData={fetchData}
                            />
                        }
                        formikProps={FormProperties.formikProps}
                        toClientDataFormat={FormProperties.toClientDataFormat}
                        toServerDataFormat={FormProperties.toServerDataFormat}
                        fetch={{
                            url: PingMyMilestoneApiUrls.DETAILS_GET,
                            id: id,
                            onPostSuccess: (response) => {
                                setFetchData(response)
                            }
                        }}
                    />

                    { /* The buttons */}
                    <Divider style={{ marginTop: '1em', marginBottom: '1em' }} />
                    <Grid container>
                        <Grid item xs={6}>
                            <BackButton label={Labels.BACK_TO_MILESTONES}
                                onClick={() => {
                                    history.push(PingPathMap.PING_MY_MILESTONES_LIST_VIEW)
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: 'right' }} >
                            <DeleteButton2 style={{ width: '10em', marginRight: '1em' }}
                                onClick={deleteClickHandler}
                            />

                            <EditButton2 style={{ width: '10em' }}
                                onClick={(event) => {
                                    let editUrl = `${PingPathMap.PING_MY_MILESTONES}/edit/${id}`
                                    history.push(editUrl)
                                }}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            { /* The Delete dialog box */}
            <PingMyMilestoneDeleteDialog ref={deleteDialogRef}
                onDeleteSuccess={deleteDialogSuccessHandler}
            />
        </>

        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default AddSuccessPage