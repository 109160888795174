import React from 'react'
import { Card, CardContent, Grid, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import CalistaUiComponentKeys from 'src/constants/locale/key/CalistaUiComponent'

function FilterPanel({
    onClearAllClicked = (event) => { /* Do nothing */ },
    onApplyClicked = (event) => { /* Do nothing */ },
    ...props
}) {

    //* Styles -----------------------------------------------------------------
    const useStyles = makeStyles({
        filterButton: {
            width: '100%',
            paddingLeft: 0,
            paddingRight: 0
        },
        filterCancelButton: {
            "background-color": "#8996AF0F !important"
        }
    });

    const classes = useStyles();

    // Translations ------------------------------------------------------------
    const { translate } = useTranslation(Namespace.CALISTA_UI_COMPONENT)

    const Labels = {
        FILTERS: translate(Namespace.CALISTA_UI_COMPONENT, CalistaUiComponentKeys.FILTERS),
        CLEAR_ALL: translate(Namespace.CALISTA_UI_COMPONENT, CalistaUiComponentKeys.CLEAR_ALL),
        APPLY: translate(Namespace.CALISTA_UI_COMPONENT, CalistaUiComponentKeys.APPLY)
    }

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <Card>
            <CardContent>
                <Grid container alignItems='center'>
                    <Grid item xs={12} xl={6}>
                        <h3 style={{ marginBottom: 0, fontWeight: 'bold' }}>
                            {Labels.FILTERS}
                        </h3>
                    </Grid>
                    <Grid item xs={12} xl={6} container spacing={1} alignItems='center'>
                        <Grid item xs={6}>
                            <Button variant='contained'
                                classes={{ root: 'ng-button-filled-secondary' }}
                                className={`${classes.filterButton} ${classes.filterCancelButton}`}
                                onClick={onClearAllClicked}
                            >
                                {Labels.CLEAR_ALL}
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant='contained'
                                classes={{ root: 'ng-button-filled-secondary' }}
                                className={classes.filterButton}
                                onClick={onApplyClicked}
                            >
                                {Labels.APPLY}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
            {
                props.children
            }
        </Card>
        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default FilterPanel
