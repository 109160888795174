import React, { useState } from 'react'
import { Card, CardContent, Grid } from '@material-ui/core'

import { components, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import PingKeys from 'src/constants/locale/key/Ping'

import MyMilestoneListing from './MyMilestoneListing'
import SearchPanel from '../components/filter-panel/SearchPanel'
import FilterPanel from '../components/filter-panel/FilterPanel'
import DateFilter from '../components/filter-panel/DateFilter'
import EventCodeFilter from '../components/filter-panel/EventCodeFilter'
import ReferenceTypeFilter from '../components/filter-panel/ReferenceTypeFilter'

const { table: { useDefaultNotification } } = components

function ListPage({ showNotification, ...props }) {

    const notification = useDefaultNotification(showNotification)

    // Translations ------------------------------------------------------------
    const { translate } = useTranslation(Namespace.PING)

    const PingMyMilestoneKeys = PingKeys.PING_MY_MILESTONES

    const Labels = {
        EVENT_DATE: translate(Namespace.PING, PingKeys.FIELDS.EVENT_DATE),
        MILESTONE_LIST_SEARCH_PLACEHOLDER: translate(Namespace.PING, PingMyMilestoneKeys.MILESTONE_LIST_SEARCH_PLACEHOLDER),
        PLEASE_SELECT_REFERENCE_TYPE: translate(Namespace.PING, PingMyMilestoneKeys.FILTERS.PLEASE_SELECT_REFERENCE_TYPE),
        PLEASE_SELECT_EVENT_CODE: translate(Namespace.PING, PingMyMilestoneKeys.FILTERS.PLEASE_SELECT_EVENT_CODE)
    }

    //* ------------------------------------------------------------------------

    const [searchText, setSearchText] = useState('')

    const [clearAllFilterClickedTime, setClearAllFilterClickedTime] = useState(new Date())
    const [dateFilter, setDateFilter] = useState('ALL')
    const [referenceTypeFilter, setReferenceTypeFilter] = useState(['ALL'])
    const [eventCodeFilter, setEventCodeFilter] = useState(['ALL'])

    const [filterData, setFilterData] = useState({
        dateFilter: 'ALL',
        referenceTypeFilter: ['ALL'],
        eventCodeFilter: ['ALL']
    })

    const applyFilterClickHandler = (event) => {

        // Validations ---------------------------------------------------------
        let hasError = false

        // dateFilter is Radio button. Will always have value. No need to validate.

        // Reference Type
        if (Boolean(referenceTypeFilter.length) == false) {
            notification.error(Labels.PLEASE_SELECT_REFERENCE_TYPE)
            hasError = true
        }

        // Event Code
        if (Boolean(eventCodeFilter.length) == false) {
            notification.error(Labels.PLEASE_SELECT_EVENT_CODE)
            hasError = true
        }

        // Apply
        if (hasError == false) {
            let newFilterData = {
                dateFilter: dateFilter,
                referenceTypeFilter: referenceTypeFilter,
                eventCodeFilter: eventCodeFilter
            }

            setFilterData(newFilterData)
        }

        return (false)
    }

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <Grid container spacing={3}>
            <Grid item xs={9}>
                <Card>
                    <CardContent>
                        <MyMilestoneListing
                            searchText={searchText}
                            filterData={filterData}
                            showNotification={showNotification}
                        />
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={3} container spacing={3} alignContent='flex-start'>
                <Grid item xs={12}>
                    <SearchPanel
                        placeholderText={Labels.MILESTONE_LIST_SEARCH_PLACEHOLDER}
                        onSearch={(searchValue) => {
                            setSearchText(searchValue)
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <FilterPanel
                        onClearAllClicked={(event) => {
                            setClearAllFilterClickedTime(new Date())
                            return (false)
                        }}
                        onApplyClicked={applyFilterClickHandler}
                    >
                        <DateFilter
                            title={Labels.EVENT_DATE}
                            clearAllFilterClickedTime={clearAllFilterClickedTime}
                            onChange={(value) => {
                                setDateFilter(value)
                            }}
                        />
                        <ReferenceTypeFilter
                            clearAllFilterClickedTime={clearAllFilterClickedTime}
                            onChange={(value) => {
                                setReferenceTypeFilter(value)
                            }}
                        />
                        <EventCodeFilter
                            clearAllFilterClickedTime={clearAllFilterClickedTime}
                            onChange={(value) => {
                                setEventCodeFilter(value)
                            }}
                        />
                    </FilterPanel>
                </Grid>
            </Grid>
        </Grid>
        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default ListPage
