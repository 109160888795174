import React from 'react'

import PingSearchBase from './PingSearch_Base'
import LeftPanelPlaceholder from '../LeftPanel/LeftPanelPlaceholder'
import HistoryList from '../RightPanel/HistoryList'

function PingSearch_HistoryList(props) {

	// Somehow some QA machine doesn't trigger useEffect(..., []) every page load.
	// Add in this one to see will it forcefully trigger the useEffect and reload...
	let pageLoadTimestamp = new Date()

	//* return JSX -------------------------------------------------------------
	return (

		//* Start JSX ----------------------------------------------------------
		<PingSearchBase
			pageName='ping_history_list'
			leftPanelComponent={<LeftPanelPlaceholder />}
			rightPanelComponent={<HistoryList pageLoadTimestamp={pageLoadTimestamp} />}
		/>

		//* End JSX ------------------------------------------------------------
	);

	//* End of function --------------------------------------------------------
}

export default PingSearch_HistoryList