import React from 'react'
import { Link } from 'react-router-dom'

// FontAwesome imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { far as FontAwesomeRegular } from '@fortawesome/pro-regular-svg-icons'
import { library as FontAwesomeLibrary } from '@fortawesome/fontawesome-svg-core'

FontAwesomeLibrary.add(FontAwesomeRegular)

function TopPanelLink({ href, icon, text }) {

    //* return JSX -------------------------------------------------------------
    return (
        //* Start JSX ----------------------------------------------------------
        <Link to={href} className="top_panel_link">
            <FontAwesomeIcon icon={["far", icon]} />
            <span>{text}</span>
        </Link>

        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default TopPanelLink