import React, { useState } from 'react'

import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation,
  useServices
} from 'cng-web-lib'
import axios from 'axios';

import Namespace from 'src/constants/locale/Namespace'
import ReportSchedulerKeys from "../../../constants/locale/key/ReportSchedulerKeys";
import ReportSchedulerApiUrls from "../../../apiUrls/ReportSchedulerApiUrls";
import { makeStyles } from '@material-ui/core/styles';
import Utils from "../../common/utils/Utils";
import CodeMasterApi from "../../common/api/CodeMasterApi";

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    useDefaultNotification,
    useFetchCustomLookup,
    useFetchCodeMaintenanceLookup
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  },
  filter: { EQUAL }
} = constants

const useStyles = makeStyles((theme) => ({

  colorRed: {
    color: "red"
  },
  aHover: {
    textDecoration: 'underline',
    cursor: "pointer",
    '&:hover': {
      color: 'blue',

    },
  }

}));

function ReportSchedulerList(props) {
  const {
    location: { pathname },
    showNotification
  } = props

  const notification = useDefaultNotification(showNotification)
  const fetchCustomLookup = useFetchCustomLookup()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const { fetchRecords } = useServices();
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.REPORT_SCHEDULER
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {

    let party = translate(
      Namespace.REPORT_SCHEDULER,
      ReportSchedulerKeys.PARTY
    )

    let reportName = translate(
      Namespace.REPORT_SCHEDULER,
      ReportSchedulerKeys.REPORT_NAME
    )

    let scheduleName = translate(
      Namespace.REPORT_SCHEDULER,
      ReportSchedulerKeys.SCHEDULE_NAME
    )

    let frequency = translate(
      Namespace.REPORT_SCHEDULER,
      ReportSchedulerKeys.FREQUENCY
    )

    let lastReport = translate(
      Namespace.REPORT_SCHEDULER,
      ReportSchedulerKeys.LAST_REPORT
    )

    let lastRunDate = translate(
      Namespace.REPORT_SCHEDULER,
      ReportSchedulerKeys.LAST_RUN_DATE
    )

    return {
      party,
      reportName,
      scheduleName,
      frequency,
      lastReport,
      lastRunDate
    }
  }

  function onClickDownloadReport(data) {

    function isJSON(text) {
      if (typeof text !== "string") {
        return false;
      }
      try {
        let json = JSON.parse(text);
        return (typeof json === 'object');
      } catch (error) {
        return false;
      }
    }

    function onTokenLoadSuccess(codeMasterValue) {

      if (codeMasterValue.length > 0) {
        let tokenKey = codeMasterValue[0].descriptionEn
        console.log("Token key:[" + tokenKey + "]");
        let storageKey = localStorage.getItem(tokenKey);
        console.log('Retrieve token from localStorage', storageKey)
        let accessToken;
        if(isJSON(storageKey)) {
          let token = JSON.parse(storageKey)
          accessToken = (Utils.isEmpty(token.access_token)) ? token : token.access_token
        } else {
          accessToken = storageKey
        }

        axios(
            `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/report/generated-report/hdr/get-file-path/${data.id}`,
            {
              method: 'get',
              headers: {Authorization: `Bearer ${accessToken}`}
            })
            .then(res => {
              if (res.data) {
                window.open(res.data, "_blank")
              }
            })
      }

    }

    CodeMasterApi.fetchCodeMaster(
        fetchRecords,
        'REPORT_TOKEN',
        false,
        onTokenLoadSuccess
    )

  }


  const classes = useStyles();
  const columns = [
    {
      field: "id",
      hidden: true,
    },
    {
      field: "partyId",
      title: translatedTextsObject.party,
      customLookup: () => {
        return fetchCustomLookup(
          `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get`,
          {},
          'content',
          'name',
          'id',
          (error) => console.error(error)
        )
      },
    },
    {
      field: "reportId",
      title: translatedTextsObject.reportName,
      customLookup: () => {
        return fetchCustomLookup(
          `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/report/report-management/hdr/get`,
          {},
          'content',
          'reportName',
          'id',
          (error) => console.error(error)
        )
      },
    },
    {
      field: "scheduleName",
      title: translatedTextsObject.scheduleName,
    },
    {
      field: "frequency",
      title: translatedTextsObject.frequency,
      lookup: {
        HOUR: 'HOURLY',
        DAY: 'DAILY',
        WEK: 'WEEKLY',
        MTH: 'MONTHLY',
        QTR: 'QUARTERLY',
      },
    },
    {
      field: "lastReport",
      title: translatedTextsObject.lastReport,
      filtering: true,
      sorting: true,
      render: (rowData) => {
        let renderData = "";
        if (rowData && Array.isArray(rowData.generatedReportList) && rowData.generatedReportList.length) {
          renderData = rowData.generatedReportList[0];
        }
        return <div className={classes.aHover} onClick={() => onClickDownloadReport(renderData)}>
          {renderData.generatedReportName}
        </div>
      }
    },
    {
      field: "lastRunDate",
      title: translatedTextsObject.lastRunDate,
      filtering: false,
      sorting: true,
      render: (rowData) => {
        let renderData = "";
        if (rowData && Array.isArray(rowData.generatedReportList) && rowData.generatedReportList.length) {
          const date = new Date(rowData.generatedReportList[0].createdDate).toISOString();
          renderData = date.replace("T", " ").substring(0, 19);
          return renderData;
        }
      }
    }
  ]
  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              addRoute={`${pathname}/add`}
              columns={columns}
              del={{ url: ReportSchedulerApiUrls.DELETE }}
              editRoute={`${pathname}/edit`}
              //exportData={{ url: ReportSchedulerApiUrls.EXPORT }}
              fetch={{ url: ReportSchedulerApiUrls.GET }}
              idAccessor="id"
              notification={notification}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}


export default ReportSchedulerList;