import Namespace from 'src/constants/locale/Namespace.js'
import { Yup } from 'cng-web-lib'
import { constants } from 'cng-web-lib'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )

  return Yup.object({
    criteriaQty: Yup.string().required(requiredMessage),
    importQty: Yup.number().required(requiredMessage),
    importerId: Yup.string().required(requiredMessage),
    miq: Yup.number().required(requiredMessage),
    name: Yup.string().required(requiredMessage),
    packingPlantAvail: Yup.string().required(requiredMessage),
    psq: Yup.number().required(requiredMessage),
    riceType: Yup.string(),
    type: Yup.string().required(requiredMessage)
  })
}

export default makeValidationSchema
