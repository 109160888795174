import React from 'react'
import { useHistory } from 'react-router-dom'
import { Grid } from '@material-ui/core'

// Font Awesome imports.
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { far as FontAwesomeRegular } from '@fortawesome/pro-regular-svg-icons'
import { library as FontAwesomeLibrary } from '@fortawesome/fontawesome-svg-core'

// Moment libraries
import Moment from 'moment';

// Ping components
import PathMap from 'src/paths/PathMap_Ping'
import Utils from 'src/views/common/utils/Utils'

FontAwesomeLibrary.add(FontAwesomeRegular)

function RelatedBookingRow({ booking }) {
    const history = useHistory()

    function formatDateTimeStr(timestamp, timezone) {
        if(Utils.isEmptyString(timestamp)){
            return ""
        }
        let moment = Moment(timestamp)

        // Hardcode to use server timezone
        moment.tz('Asia/Singapore')

        if (timezone) {
            // TODO: do we need to change to use port timezone?
        }

        let str = moment.format('D MMM YYYY - HH:mm')
        return (str)
    }

    const handleRowClick = (event) => {
        history.push(`${PathMap.PING_SEARCH}/booking-ref/` + booking.freightBookingId)
    }

    //* return JSX -------------------------------------------------------------
    return (
        //* Start JSX ----------------------------------------------------------

        <Grid container
            onClick={handleRowClick}
        >
            <Grid className="booking_ref" item xs={12}>
                <span>BKRF: {booking.carrierRef}</span>
            </Grid>

            <Grid className="pol" item xs={6}>
                <div className="field">
                    <label>POL</label>
                    <span className="country">
                        <img className="flag" src={`${process.env.PUBLIC_URL}/static/images/countries/${booking.polCountryCode}.svg`} />
                        <span className="portcode">{booking.polCode}</span>
                    </span>
                </div>
                <div className="field pol_pod_datetime">
                    <label>&nbsp;</label>
                    <span>
                        <span className="on">on &nbsp;</span>
                        <span>{formatDateTimeStr(booking.polEtd, booking.polTimezone)}</span>
                    </span>
                </div>
            </Grid>

            <Grid className="pod" item xs={6}>
                <FontAwesomeIcon className="arrow" icon={["far", "arrow-right"]} />
                <div className="field">
                    <label>POD</label>
                    <span className="country">
                        <img className="flag" src={`${process.env.PUBLIC_URL}/static/images/countries/${booking.podCountryCode}.svg`} />
                        <span className="portcode">{booking.podCode}</span>
                    </span>
                </div>
                <div className="field pol_pod_datetime">
                    <label>&nbsp;</label>
                    <span>
                        <span className="on">on &nbsp;</span>
                        <span>{formatDateTimeStr(booking.podEta, booking.podTimezone)}</span>
                    </span>
                </div>
            </Grid>

            <Grid className="booking_created_date" item xs={12}>
                <FontAwesomeIcon icon={["far", "clock"]} />
                <span>BK created on {formatDateTimeStr(booking.createdTimestamp)}</span>
            </Grid>

        </Grid>

        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default RelatedBookingRow